/* eslint-disable promise/no-nesting,max-lines,max-lines-per-function */
import { AnyResourceType } from '@deltasierra/components';
import {
    ViewFileDto,
    BuilderEmailDocument,
    deDuplicateEditableFields,
    ButtonSection,
    isButtonSection,
    ImageSection,
    isImageSection,
    isSupportedFormat,
    Section,
    isTextSection,
    TextSection,
    BuilderTemplate,
    BuilderTemplateId,
    BuilderType,
    linkToGallery,
    BuilderTemplateCategoryId,
    BuilderTemplateFormat,
    Client,
    ClientId,
    assertNever,
    ErrorCode,
    ExportPlatforms,
    AssignedLocation,
    GalleryPlannerDetails,
    Tag,
    Upload,
} from '@deltasierra/shared';

import { clone, noop } from '@deltasierra/utilities/object';
import JSZip from 'jszip';
import type { IWindowService, ILocationService, IFilterService, IScope, IPromise, IAngularEvent } from 'angular';
import { isNullOrUndefined } from '@deltasierra/type-utilities';
import { SentryService } from '../../common/sentryService';
import { MvAuth } from '../../account/mvAuth';
import { MvIdentity } from '../../account/mvIdentity';
import { MvClientResource, mvClientResourceSID } from '../../clients/mvClientResource';
import {
    $filterSID,
    $locationSID,
    $qSID,
    $routeSID,
    $sanitizeSID,
    $scopeSID,
    $windowSID,
    IRoute,
    ISanitize,
} from '../../common/angularData';
import { $modalSID } from '../../common/angularUIBootstrapData';
import { ConfirmModal, confirmModalSID } from '../../common/confirmModal';
import { DataUtils } from '../../common/dataUtils';
import { ImageCropperService as imageCropperService } from '../../common/imageCropper/service';
import { InteractionUtils } from '../../common/interactionUtils';
import { MvNotifier } from '../../common/mvNotifier';
import { UploadMap, UploadService } from '../../common/uploadService';
import { I18nService } from '../../i18n';
import { MvPlanner } from '../../planner/mvPlanner';
import { PlannerUIService as plannerUIService } from '../../planner/plannerUIService';
import { ModalInstance, ModalService } from '../../typings/angularUIBootstrap/modalService';
import { GraphqlService } from '../../graphql/GraphqlService';
import { BuilderConstants, builderConstantsSID } from '../builderConstants';
import {
    FieldValidationResult,
    FieldValidationResultSeverity,
    getFieldValidationClassName,
} from '../builderDocumentValidation';
import { BuilderTemplateApiClient as IBuilderTemplateApiClient } from '../builderTemplateApiClient';
import {
    BuilderCommonService,
    CurrentTextSubstitutionField,
    TextSubstitutionScopeMixins,
    UploadResourcesScope,
} from '../common/builderCommonService';
import { fileReaderServiceSID, IFileReaderService } from '../common/fileReaderService';
import { TextSubstitutionService } from '../common/textSubstitutionService';
import { ZipService } from '../common/zipService';
import { FileCache, ImageLoaderService } from '../imageLoaderService';
import {
    MvBuilderTemplateFormatResource,
    mvBuilderTemplateFormatResourceSID,
} from '../mvBuilderTemplateFormatResource';
import { GetForUploadType } from '../mvContentBuilderCtrl';
import { PublishResult } from '../publish/publishResult';
import { FileUtils } from '../../common/fileUtils';
import {
    CampaignMonitorTemplateParserSID,
    ICampaignMonitorTemplateParser,
    ICampaignMonitorTemplateParserResult,
} from './campaignMonitorTemplateParser';
import { EmailBuilder as EmailBuilderClass, EmailBuilderFactory, MailMergeFieldType } from './emailBuilder';
import { IZipFileValidationContext, ZipFileProcessorFactory, ZipFileValidator } from './emailBuilderZip';
import { EmailPublishData } from './emailPublishData';
import { GET_EMAIL_BUILDER_CONFIG } from './GetEmailBuilderConfig.query';
import { EmailPublishService } from './publish/emailPublishService';
import { GetEmailBuilderConfig } from './__graphqlTypes/GetEmailBuilderConfig';

interface BuilderPlannerDetails extends GalleryPlannerDetails {
    visible?: boolean;
}

enum EmailBuilderView {
    BUILDER = 'BUILDER',
    EDIT_SOURCE = 'EDIT_SOURCE',
    PUBLISHED = 'PUBLISHED',
    PUBLISH = 'PUBLISH',
}

class NotFoundError extends Error {
    public data = { code: ErrorCode.NotFoundError };
}

enum EmailBuilderSubView {
    MAIN = 'MAIN',
    ERROR_NOT_FOUND = 'ERROR_NOT_FOUND',
}

export interface EmailBuilderCtrlScope extends IScope, TextSubstitutionScopeMixins {
    VIEWS: typeof EmailBuilderView;
    PUBLISH_AGAIN: {
        NO: 'NO';
        YES: 'YES';
        LATER: 'LATER';
        DIFFERENT_TEMPLATE: 'DIFFERENT_TEMPLATE';
    };
    plannerDetailsExpanded: boolean;
    identity: MvIdentity;
    clients: Client[] | null;
    plannerId: number | null;
    plannerImages: Upload[] | null;
    plannerDetails: BuilderPlannerDetails | null;
    boundData: {
        selectedClient: Client | null;
        highlightingEnabled: boolean;
        showAllSections: boolean;
        isPublishing: boolean;
        publishData?: EmailPublishData;
        view: EmailBuilderView;
        subView: EmailBuilderSubView;
        publishResult: PublishResult | null;
        publishAgain: string | null;
        isDraft: boolean;
        desktopViewport: boolean;
    };
    loading: {
        setPlannerStatusToPlanned: boolean;
    };
    builderTemplateFormats: BuilderTemplateFormat[];
    selectedBuilderTemplateFormats: BuilderTemplateFormat[];
    selectedCategoryIds: BuilderTemplateCategoryId[];
    currentTextSubstitutionField: CurrentTextSubstitutionField | null;
    fileCache: FileCache;
    emailBuilder: EmailBuilderClass;
    location: AssignedLocation | null | undefined;
    shouldShowNewDocumentPrompt: boolean;
    shouldShowDocumentProperties: boolean;
    isLoading: boolean;
    isSaving: boolean;
    templateId: BuilderTemplateId | null;
    templateGraphqlId: string | null;
    tagContext: {
        value: string | null;
    };
    tags: Tag[];
    existingThumbnailUpload: Upload | null;
    isDirty: boolean;
    dirtyChecks: number; // Horrible, horrible hack
    dirtyModal: ModalInstance | null;
    editSourceData?: {
        htmlDocument: HTMLDocument;
    };
    zipValidationMessages: string[];
    linkValidationMessages: string[];
    isViaMemberEngager: boolean;

    getFieldValidationClassName(messages: FieldValidationResult[]): string;
    updateEditorFromDocument(): void;
    updateDocumentFromEditor(): void;
    onPlannerDetailsLoaded(plannerDetails: BuilderPlannerDetails): IPromise<any>;
    updateLocation(location: AssignedLocation): void;
    initNewDocument(): void;
    selectZipFile(files: any[]): IPromise<void> | void;
    selectDocumentProperties(): void;
    saveAsExistingTemplate(deleteLocationDrafts: boolean): void;
    saveAsNewTemplate(): void;
    deleteTemplate(): IPromise<void> | void;
    showImageChooser(section: ImageSection): void;
    setFormat(format: BuilderTemplateFormat): void;
    isFormatSelected(format: BuilderTemplateFormat): boolean;
    onClickPublish(): void;
    isTemplateValid(): boolean;
    canPublish(serviceName: string): boolean;
    togglePlannerDetails(): void;
    getPublishedDescription(): string;
    cancelPublishing(): void;
    finishPublishing(publishResult: PublishResult): void;
    goToGallery(): void;
    choosePublishAgain(): void;
    constructPlannerUrl(): string | undefined;
    shouldShowPlannerDetails(): boolean;
    advancedMode(): boolean;
    getAuthorizeOnAppsPageText(): string;
    onSelectedCategoryUpdate(categories: BuilderTemplateCategoryId[]): void;
    onClickEditSource(): void;
    onUpdateHtml(result: ICampaignMonitorTemplateParserResult): void;
    onCancelEditSource(): void;
    checkInitialViewport(): boolean;

    isButtonSection(section: Section): section is ButtonSection;
    isImageSection(section: Section): section is ImageSection;
    isTextSection(section: Section): section is TextSection;

    resourcePickerMediaTypeFilter: 'image' | 'video';
    isAssetLibraryModalShown: boolean;
    isResourcePickerModalShown: boolean;
    canChooseLocationLogo: boolean;
    isImageSelectConfirmationModalShown: boolean;
    selectedFile: File | null;
    selectedFilePath: string | null;
    handleReplaceImageDirectly: (file: File, section: ImageSection) => void;
    onFileSelectForImage: (file: File, section: ImageSection) => Promise<void>;
    handleCloseResourcePicker: () => void;
    handleCloseAssetLibrary: () => void;

    resourcePickerImageSection: ImageSection;

    openAssetLibraryModal: () => void;

    handleAssetChosen: (asset: ViewFileDto) => void;
    handleResourcePicked: (resource: AnyResourceType) => void;

    plannerResources: Upload[];
    assetResources: ViewFileDto[];
    assetLibraryMediaFilter: Array<'document' | 'image' | 'video'>;

    originalTemplate: BuilderTemplate | null;

    getForUpload: GetForUploadType;

    builderType: BuilderType;

    loadLocationDraft: (locationDraftGraphqlId: string) => Promise<void>;
    loadOriginalTemplate: () => Promise<void>;

    isLocationDraftEnabled: boolean;
}

// #TODO: We're pulling in a lot of services into this controller. May be a sign we should split this file up a little?
// #TODO: Convert to a class

angular.module('app').controller('mvEmailBuilderCtrl', [
    $scopeSID,
    $qSID,
    $locationSID,
    $routeSID,
    $sanitizeSID,
    $filterSID,
    $modalSID,
    $windowSID,
    ImageLoaderService.SID,
    EmailBuilderClass.SID,
    BuilderCommonService.SID,
    MvNotifier.SID,
    mvClientResourceSID,
    MvIdentity.SID,
    mvBuilderTemplateFormatResourceSID,
    IBuilderTemplateApiClient.SID,
    CampaignMonitorTemplateParserSID,
    fileReaderServiceSID,
    ZipService.SID,
    TextSubstitutionService.SID,
    UploadService.SID,
    confirmModalSID,
    builderConstantsSID,
    MvPlanner.SID,
    plannerUIService.SID,
    InteractionUtils.SID,
    DataUtils.SID,
    ZipFileValidator.SID,
    ZipFileProcessorFactory.SID,
    I18nService.SID,
    MvAuth.SID,
    imageCropperService.SID,
    EmailPublishService.SID,
    GraphqlService.SID,
    FileUtils.SID,
    SentryService.SID,
    // eslint-disable-next-line max-params,max-statements
    function mvEmailBuilderCtrl(
        this: never,
        $scope: EmailBuilderCtrlScope,
        $q: angular.IQService,
        $location: ILocationService,
        $route: IRoute,
        $sanitize: ISanitize,
        $filter: IFilterService,
        $modal: ModalService,
        $window: IWindowService,
        imageLoaderService: ImageLoaderService,
        EmailBuilder: EmailBuilderFactory,
        builderCommonService: BuilderCommonService,
        mvNotifier: MvNotifier,
        mvClientResource: MvClientResource,
        mvIdentity: MvIdentity,
        mvBuilderTemplateFormatResource: MvBuilderTemplateFormatResource,
        BuilderTemplateApiClient: IBuilderTemplateApiClient,
        CampaignMonitorTemplateParser: ICampaignMonitorTemplateParser,
        fileReaderService: IFileReaderService,
        zipService: ZipService,
        textSubstitutionService: TextSubstitutionService,
        uploadService: UploadService,
        confirmModal: ConfirmModal,
        builderConstants: BuilderConstants,
        mvPlanner: MvPlanner,
        PlannerUIService: plannerUIService,
        interactionUtils: InteractionUtils,
        dataUtils: DataUtils,
        zipFileValidator: ZipFileValidator,
        zipFileProcessorFactory: ZipFileProcessorFactory,
        i18nService: I18nService,
        mvAuth: MvAuth,
        ImageCropperService: imageCropperService,
        emailPublishService: EmailPublishService,
        graphqlService: GraphqlService,
        fileUtils: FileUtils,
        sentryService: SentryService,
    ) {
        $scope.VIEWS = EmailBuilderView;
        $scope.PUBLISH_AGAIN = {
            DIFFERENT_TEMPLATE: 'DIFFERENT_TEMPLATE',
            LATER: 'LATER',
            NO: 'NO',
            YES: 'YES',
        };
        $scope.identity = mvIdentity;
        $scope.clients = null;
        $scope.plannerId = null;
        $scope.plannerImages = null;
        $scope.plannerDetails = null;
        $scope.originalTemplate = null;
        $scope.boundData = {
            desktopViewport: shouldDesktopViewportBeUsed(),
            highlightingEnabled: true,
            isDraft: false,
            isPublishing: false,
            publishAgain: null,
            publishResult: null,
            selectedClient: null,
            showAllSections: false,
            subView: EmailBuilderSubView.MAIN,
            view: $scope.VIEWS.BUILDER,
        };
        $scope.loading = {
            setPlannerStatusToPlanned: false,
        };
        $scope.selectedBuilderTemplateFormats = [];
        $scope.currentTextSubstitutionField = null;
        $scope.fileCache = new FileCache();
        $scope.emailBuilder = /* New*/ EmailBuilder($scope.fileCache);
        $scope.location = null;
        $scope.plannerDetailsExpanded = false;
        $scope.shouldShowNewDocumentPrompt = true;
        $scope.shouldShowDocumentProperties = true;
        $scope.isImageSelectConfirmationModalShown = false;
        $scope.selectedFilePath = null;
        $scope.selectedFile = null;
        $scope.isLoading = true;
        $scope.isSaving = false;
        $scope.templateId = null;
        $scope.templateGraphqlId = null;
        $scope.tagContext = {
            value: null,
        };
        $scope.tags = [];
        $scope.existingThumbnailUpload = null;
        $scope.isDirty = false;
        $scope.dirtyChecks = 0; // Horrible, horrible hack
        $scope.dirtyModal = null;
        $scope.editSourceData = undefined;
        $scope.isViaMemberEngager = $location.path().indexOf('/memberEngager') > -1;

        $scope.emailBuilder.linkedAssetLibraryAsset = [];

        $scope.builderType = 'emailBuilder';

        $scope.isLocationDraftEnabled = false;

        $scope.$on('$destroy', () => {
            $scope.fileCache.clear();
        });

        $scope.$on('$locationChangeStart', (event, next) => {
            if ($scope.isDirty) {
                event.preventDefault();
                if (!$scope.dirtyModal) {
                    const url = $location.url();
                    $scope.dirtyModal = confirmModal.open(
                        i18nService.text.common.leavePagePrompt.title(),
                        i18nService.text.common.leavePagePrompt.confirm(),
                        () => {
                            $scope.isDirty = false;
                            $scope.dirtyModal = null;
                            $location.url(url);
                        },
                        () => {
                            $scope.dirtyModal = null;
                        },
                    );
                }
            }
        });
        $scope.$on(builderConstants.EVENTS.PUBLISH_CANCEL, onBuilderPublishCancelEvent);
        $scope.$on(builderConstants.EVENTS.PUBLISH_FINISH, onBuilderPublishFinishEvent);
        $scope.zipValidationMessages = [];
        $scope.linkValidationMessages = [];

        $scope.getFieldValidationClassName = getFieldValidationClassName;

        builderCommonService.applyTextSubstitutionMixins($scope, 'emailBuilder');

        const zipFileProcessor = zipFileProcessorFactory.create($scope);

        async function initLocation() {
            return builderCommonService.getLocation($scope).then(updateLocation, err => {
                mvNotifier.unexpectedErrorWithData(i18nService.text.build.error.failedToGetLocation(), err);
            });
        }

        function initClients() {
            return builderCommonService.setClients($scope).then(
                (selectedClient: Client | null) => {
                    $scope.boundData.selectedClient = selectedClient; // Might be null
                },
                err => {
                    mvNotifier.unexpectedErrorWithData(i18nService.text.build.error.failedToGetClients(), err);
                },
            );
        }

        function initBuilderTemplateFormats() {
            $scope.builderTemplateFormats = mvBuilderTemplateFormatResource.query(
                {
                    type: 'email',
                },
                () => {
                    if ($scope.builderTemplateFormats.length > 0) {
                        $scope.selectedBuilderTemplateFormats.push($scope.builderTemplateFormats[0]);
                    }
                },
                (data: any) => {
                    mvNotifier.unexpectedErrorWithData(
                        i18nService.text.build.error.failedToRetrieveBuilderTemplateFormats(),
                        data,
                    );
                },
            );
            return $scope.builderTemplateFormats.$promise;
        }

        function initTemplate(templateId: BuilderTemplateId) {
            return BuilderTemplateApiClient.getBuilderTemplate(templateId).then(template => {
                if (isNullOrUndefined(template.id)) {
                    return $q.reject(new NotFoundError());
                }
                return template;
            });
        }

        function initWatchers() {
            $scope.$watch(() => $scope.emailBuilder.document, onDocumentChange, true);
            // $scope.$watch(function () {
            //     Return $scope.contentBuilder.textSubstitutionValues;
            // }, onTextSubstitutionChange, true);
            $scope.$watch(() => $scope.emailBuilder.advancedMode, onAdvancedModeChange);
            $scope.$watch(() => $scope.emailBuilder.selectedSection, onSelectedSectionChange);
        }

        function initDirtyWatch() {
            $scope.$watch(() => $scope.emailBuilder.document, onDirtyChange, true);
        }

        function onDirtyChange() {
            $scope.dirtyChecks++;
            if ($scope.dirtyChecks > 2) {
                $scope.isDirty = true;
                // TODO: unwatch
            }
        }

        function onDocumentChange() {
            $scope.updateEditorFromDocument();
        }

        function onAdvancedModeChange() {
            if (
                !$scope.emailBuilder.advancedMode &&
                !!$scope.emailBuilder.selectedSection &&
                !$scope.emailBuilder.hasAnyEditableFieldForSection($scope.emailBuilder.selectedSection.id)
            ) {
                // When switching from the advanced editor, deselect the currently selected section if it has no editable
                //  Fields. Otherwise, we'll try to show simple editor fields for a section without any.
                $scope.emailBuilder.selectSection(null);
            }
        }

        function shouldDesktopViewportBeUsed() {
            return $window.window.innerWidth >= 768;
        }

        function onSelectedSectionChange() {
            $scope.shouldShowDocumentProperties = !$scope.emailBuilder.selectedSection;
        }

        $scope.$on(builderConstants.EVENTS.UPDATE_DOCUMENT_FROM_EDITOR, () => {
            $scope.updateDocumentFromEditor();
        });

        $scope.updateDocumentFromEditor = () => {
            $scope.emailBuilder.updateDocumentFromEditor();
        };

        $scope.updateEditorFromDocument = () => {
            $scope.emailBuilder.updateEditorFromDocument();
        };

        function initPage() {
            const params = $location.search();
            const parsedPlannerId = Number(params.planner);
            const isValidPlannerId = !isNaN(parsedPlannerId) && parsedPlannerId > 0;
            $scope.plannerId = isValidPlannerId ? parsedPlannerId : null;
            if (!$scope.plannerId) {
                return loadABunchOfData();
            }
            return Promise.resolve();
        }

        function loadABunchOfData() {
            const params = $location.search();
            const promises = [
                initLocation().then(initClients),
                initBuilderTemplateFormats(),
                loadLinkedAssetLibraryAssets(params.template),
                getBuilderEnvironmentConfig(),
            ];
            let finalPromise: IPromise<any>;
            if (params.template) {
                promises.push();
                finalPromise = $q
                    .all(promises)
                    .then(() => initTemplate(params.template))
                    .then(async template => {
                        $scope.originalTemplate = clone(template);
                        await loadFromTemplate(template);
                    })
                    .catch(err => {
                        // Catch err here. Check if it is not undefined, and has a code === ErrorCode.NotFound.
                        // Do a sneaky number check to see if we are calling an invalid template id
                        // This needs to be solved before fetching when refactored, checking like this is an anti-pattern
                        const isNotFoundError = err && err.data && err.data.code === ErrorCode.NotFoundError;
                        const isInvalidTemplateId = isNaN(params.template) || Number(params.template) < 0;
                        if (isNotFoundError || isInvalidTemplateId) {
                            $scope.boundData.subView = EmailBuilderSubView.ERROR_NOT_FOUND;
                        } else {
                            mvNotifier.unexpectedErrorWithData(
                                i18nService.text.build.error.failedToRetrieveTemplate(),
                                err,
                            );
                            $scope.isLoading = false;
                        }
                    });
            } else {
                finalPromise = $q.all(promises);
                $scope.shouldShowNewDocumentPrompt = true;
                $scope.isLoading = false;
            }
            initWatchers();
            return finalPromise.then(initDirtyWatch);
        }

        async function loadLinkedAssetLibraryAssets(builderTemplateId: BuilderTemplateId): Promise<void> {
            $scope.emailBuilder.linkedAssetLibraryAsset =
                await BuilderTemplateApiClient.getAssetLibraryAssetsUsedInTemplate(builderTemplateId);
        }

        async function getBuilderEnvironmentConfig(): Promise<void> {
            const gqlClient = graphqlService.getClient();
            const configResult = await gqlClient.query<GetEmailBuilderConfig>({
                fetchPolicy: 'cache-first',
                notifyOnNetworkStatusChange: true,
                query: GET_EMAIL_BUILDER_CONFIG,
            });
            if (configResult.errors) {
                throw new Error('Failed to fetch builder config');
            }

            if (configResult.data) {
                $scope.isLocationDraftEnabled = configResult.data.config.features.builder.builderTemplateDrafts;
            }
        }

        $scope.getAuthorizeOnAppsPageText = () => i18nService.text.build.email.authorizeOnAppsPage();

        $scope.onPlannerDetailsLoaded = (plannerDetails: BuilderPlannerDetails) => {
            $scope.plannerDetails = plannerDetails;
            getImagesFromPlanner();
            return loadABunchOfData();
        };

        function getImagesFromPlanner() {
            $scope.plannerImages = dataUtils.filterBy(
                'isImage',
                $scope.plannerDetails ? $scope.plannerDetails.uploads || [] : [],
                true,
            );
        }

        function selectClient(clientId: ClientId) {
            $scope.boundData.selectedClient = builderCommonService.findClientById(clientId, $scope.clients || []); // Might be null
        }

        function updateLocation(location: AssignedLocation) {
            $scope.location = location;
            $scope.emailBuilder.textSubstitutionValues.location = dataUtils.useFallbackWhenUndefinedOrNull(
                location.displayName,
                location.title,
            );
        }

        $scope.updateLocation = updateLocation;

        function loadFromTemplate(data: BuilderTemplate) {
            $scope.shouldShowNewDocumentPrompt = false;
            $scope.emailBuilder.advancedMode = false;
            $scope.templateId = data.id;
            $scope.templateGraphqlId = data.graphqlId;
            $scope.tags = data.tags || [];
            $scope.boundData.isDraft = data.isDraft;
            $scope.existingThumbnailUpload = data.compositeImage!;

            const templateWithDedupedFields: BuilderTemplate = {
                ...data,
                document: {
                    ...data.document,
                    editableFields: deDuplicateEditableFields(data.document.editableFields),
                },
            };

            return $scope.emailBuilder
                .loadDocument(templateWithDedupedFields.document as BuilderEmailDocument)
                .then(() => {
                    selectClient(data.clientId);
                    $scope.selectedBuilderTemplateFormats = [];
                    for (const format of $scope.builderTemplateFormats) {
                        if (builderCommonService.indexOfId(data.formats || [], format.id) > -1) {
                            $scope.selectedBuilderTemplateFormats.push(format);
                        }
                    }
                    $scope.selectedCategoryIds = (data.categories || []).map(category => category.id);
                    setMailMergeFieldType();
                    $scope.isLoading = false;
                });
        }

        $scope.initNewDocument = () => {
            setMailMergeFieldType();
            $scope.shouldShowNewDocumentPrompt = false;
        };

        function setMailMergeFieldType() {
            for (const format of $scope.selectedBuilderTemplateFormats) {
                if (format.canPublishTo === 'fitware') {
                    $scope.emailBuilder.mailMergeFieldType = MailMergeFieldType.Fitware;
                    return;
                } else if (format.canPublishTo === 'clubReady') {
                    $scope.emailBuilder.mailMergeFieldType = MailMergeFieldType.ClubReady;
                    return;
                }
            }
            $scope.emailBuilder.mailMergeFieldType = MailMergeFieldType.None;
        }

        $scope.selectZipFile = function selectZipFile(files: any[]) {
            if (files.length !== 1) {
                mvNotifier.expectedError(i18nService.text.build.email.uploadOnlyOneZipFile());
                return;
            }

            const zipFile = files[0];

            // eslint-disable-next-line consistent-return
            return uploadService
                .checkFileSize(zipFile)
                .then(
                    async () =>
                        zipService
                            .readZip(zipFile)
                            .catch(err => {
                                mvNotifier.unexpectedError(err.message);
                                mvNotifier.unexpectedErrorWithData(
                                    i18nService.text.build.email.failedToReadZipFile(),
                                    err,
                                );
                                return null;
                            })
                            // eslint-disable-next-line consistent-return
                            .then((zip: JSZip | null): IPromise<IZipFileValidationContext | void> | void => {
                                if (zip) {
                                    return zipFileValidator.validate(zip).catch(err => {
                                        mvNotifier.unexpectedErrorWithData(
                                            i18nService.text.build.email.failedToValidateZipFile(),
                                            err,
                                        );
                                        return undefined;
                                    });
                                }
                            })
                            // eslint-disable-next-line consistent-return
                            .then(context => {
                                if (context) {
                                    $scope.zipValidationMessages = context.validationMessages;

                                    if ($scope.zipValidationMessages.length === 0) {
                                        return zipFileProcessor
                                            .process(context)
                                            .catch(err =>
                                                mvNotifier.unexpectedErrorWithData(
                                                    i18nService.text.build.email.failedToProcessZipFile(),
                                                    err,
                                                ),
                                            );
                                    }
                                }
                            }),
                    err => {
                        $scope.zipValidationMessages = [err.message || err];
                    },
                )
                .then(noop);
        };

        $scope.selectDocumentProperties = function selectDocumentProperties() {
            $scope.emailBuilder.selectSection(null);
        };

        function uploadThumbnailImage(modalScope: UploadResourcesScope): IPromise<Upload> {
            // NOTE: rectifying the images prevents the need to upload any image resources later! This will give a confusing
            //  UX because we're uploading multiple files... ah well, we can sort it out later.
            return getCompiledTemplateHtml().then(htmlDocument =>
                emailPublishService
                    .rectifyAllImagesAndConvertToString(
                        $scope.emailBuilder.document,
                        htmlDocument,
                        $scope.fileCache,
                        modalScope,
                    )
                    .then(html => BuilderTemplateApiClient.takeHtmlScreenshot(html)),
            );
        }

        $scope.getForUpload = async () => {
            const filesToUpload: { [key: string]: Blob | File } = {};
            $scope.emailBuilder.document.sections
                .filter(
                    (section?: Section): section is ImageSection =>
                        isImageSection(section) && section.locationType === 'local' && !!section.location,
                )
                .forEach((section: ImageSection) => {
                    if (section.location) {
                        const file = $scope.fileCache.get(section.location);
                        if (file) {
                            filesToUpload[section.location] = file;
                        }
                    }
                });

            const result = builderCommonService.showModalAndUploadResources(
                $scope,
                'emailTemplateImage',
                filesToUpload,
                2,
            );

            const [uploadMap, thumbnailImageUpload] = await $q.all([
                result.promise,
                tryUploadThumbnailImage(result.scope),
            ]);

            // Apparently we need to wrap this in setTimeout or the modal glitches out
            setTimeout(() => {
                result.cleanup();
            }, 500);

            return {
                compositeImageUpload: thumbnailImageUpload,
                contentBuilder: $scope.emailBuilder,
                uploadMap,
            };
        };

        async function tryUploadThumbnailImage(result: UploadResourcesScope): Promise<Upload> {
            for (let i = 0; i < 3; i++) {
                try {
                    // eslint-disable-next-line no-await-in-loop
                    return await uploadThumbnailImage(result);
                } catch (err) {
                    sentryService.captureException(err, result);
                }
            }
            throw new Error(i18nService.text.build.error.failedToUploadThumbnail());
        }

        function saveAsEmailTemplate(templateId?: number, deleteLocationDrafts?: boolean) {
            // TODO: images are being uploaded twice; once to generate the thumbnail image, and again for the template.
            // It would be nice to avoid this.
            const filesToUpload: { [key: string]: Blob | File } = {};
            $scope.emailBuilder.document.sections
                .filter(
                    (section?: Section): section is ImageSection =>
                        isImageSection(section) && section.locationType === 'local' && !!section.location,
                )
                .forEach((section: ImageSection) => {
                    if (section.location) {
                        const file = $scope.fileCache.get(section.location);
                        if (file) {
                            filesToUpload[section.location] = file;
                        }
                    }
                });

            const result = builderCommonService.showModalAndUploadResources(
                $scope,
                'emailTemplateImage',
                filesToUpload,
                2,
            );
            const cleanup = result.cleanup;
            return $q.all([result.promise, tryUploadThumbnailImage(result.scope)]).then(
                ([uploadMap, thumbnailImageUpload]: [UploadMap, Upload]) => {
                    const emailTemplate = {
                        categories: $scope.selectedCategoryIds,
                        clientId: $scope.boundData.selectedClient!.id,
                        compositeImageId: null as number | null,
                        compositeImageUpload: thumbnailImageUpload,
                        document: {
                            ...$scope.emailBuilder.document,
                            editableFields: deDuplicateEditableFields($scope.emailBuilder.document.editableFields),
                        },
                        formats: $scope.selectedBuilderTemplateFormats,
                        imageMap: uploadMap,
                        isDraft: $scope.boundData.isDraft,
                        isMultiImage: false,
                        linkedAssetLibraryAssetIds: $scope.emailBuilder.linkedAssetLibraryAsset.map(value => ({
                            assetId: value.asset.id,
                            layerId: value.layerId,
                        })),
                        mobile: false, // No mobile for you!
                        tags: $scope.tags,
                    };
                    return (
                        BuilderTemplateApiClient.createOrUpdateBuilderTemplate(
                            emailTemplate,
                            templateId,
                            deleteLocationDrafts,
                        )
                            /*
                             * FIXME: Remove this cast
                             * TS versions prior to v3.9 didn't pickup this typing issue. Our TS upgrade exposed this issue. It's too
                             * complicated and time consuming to fix at the moment...
                             */
                            .then(template => template as BuilderTemplate)
                            .then(
                                (data: BuilderTemplate) => {
                                    cleanup();
                                    mvNotifier.notify(i18nService.text.build.templateSaved());
                                    $scope.isDirty = false;
                                    if (templateId) {
                                        // Need to specifically reload: setting the same location with $location doesn't work.
                                        $route.reload();
                                    } else {
                                        interface Search {
                                            template: number;
                                            planner?: number;
                                        }
                                        const search: Search = {
                                            template: data.id,
                                        };
                                        if ($scope.plannerId) {
                                            search.planner = $scope.plannerId;
                                        }
                                        $location.path('/emailBuilder').search(search);
                                    }
                                },
                                (data: any) => {
                                    cleanup();
                                    mvNotifier.unexpectedErrorWithData(
                                        i18nService.text.build.error.failedToSaveTemplate(),
                                        data,
                                    );
                                },
                            )
                    );
                },
                data => {
                    cleanup();
                    mvNotifier.unexpectedErrorWithData(i18nService.text.build.error.failedToUploadImages(), data);
                },
            );
        }

        // eslint-disable-next-line consistent-return
        $scope.saveAsExistingTemplate = (deleteLocationDrafts: boolean) => {
            if ($scope.templateId) {
                return saveAsEmailTemplate($scope.templateId, deleteLocationDrafts);
            }
        };

        $scope.saveAsNewTemplate = () => saveAsEmailTemplate();

        // eslint-disable-next-line consistent-return
        $scope.deleteTemplate = () => {
            if ($scope.templateId) {
                return BuilderTemplateApiClient.deleteBuilderTemplate($scope.templateId).then(
                    () => {
                        mvNotifier.notify(i18nService.text.build.templateDeleted());
                        $scope.isDirty = false;
                        $location.path('/builderTemplateGallery');
                    },
                    (data: any) => {
                        mvNotifier.unexpectedErrorWithData(i18nService.text.build.error.failedToDeleteTemplate(), data);
                    },
                );
            }
        };

        /*
         * Resource picker
         */

        $scope.showImageChooser = (section: ImageSection): ng.IPromise<void> => {
            $scope.assetLibraryMediaFilter = ['image'];
            $scope.resourcePickerMediaTypeFilter = 'image';
            $scope.resourcePickerImageSection = section;
            $scope.isResourcePickerModalShown = true;
            return Promise.resolve();
        };

        $scope.canChooseLocationLogo = false;
        $scope.plannerResources = [];
        $scope.resourcePickerMediaTypeFilter = 'image';
        $scope.isAssetLibraryModalShown = false;
        $scope.isResourcePickerModalShown = false;

        $scope.handleCloseResourcePicker = () => {
            $scope.isAssetLibraryModalShown = false;
            $scope.isResourcePickerModalShown = false;
        };

        $scope.handleCloseAssetLibrary = () => {
            $scope.isAssetLibraryModalShown = false;
            $scope.isResourcePickerModalShown = true;
        };

        $scope.openAssetLibraryModal = () => {
            $scope.plannerResources = $scope.plannerImages || [];
            $scope.assetResources = [];
            $scope.isAssetLibraryModalShown = true;
            $scope.isResourcePickerModalShown = false;
        };

        $scope.handleAssetChosen = (asset: ViewFileDto) => {
            $scope.handleResourcePicked({ asset, type: 'assetLibrary' });
        };

        $scope.handleReplaceImageDirectly = async (file: File, section: ImageSection) => {
            // This is needed to allow the modal to close properly. (Related to DS-4802?)
            await new Promise(resolve => {
                setTimeout(resolve, 1);
            });
            // If replacing image in an existing layer which uses a local file, remove the old file from our file cache.
            // (TODO: If it's not used in existing layers?)
            if (section && section.locationType === 'local' && section.location !== null) {
                $scope.fileCache.remove(section.location);
            }
            $scope.emailBuilder.setImageLocationFromFile(section, file);
            $scope.isImageSelectConfirmationModalShown = false;
            $scope.$digest();
        };

        $scope.handleResourcePicked = async (resource: AnyResourceType) => {
            const section = $scope.resourcePickerImageSection;
            $scope.handleCloseAssetLibrary();
            $scope.handleCloseResourcePicker();

            const { file, filePath } = await getFilePathAndResource(resource);
            if (!isSupportedFormat(file.type)) {
                mvNotifier.expectedError(i18nService.text.build.image.unsupportedFormat());
                return;
            }

            if (section) {
                $scope.emailBuilder.linkedAssetLibraryAsset = $scope.emailBuilder.linkedAssetLibraryAsset.filter(
                    ({ layerId }) => layerId !== section.id,
                );
            }
            $scope.selectedFile = file;
            $scope.selectedFilePath = filePath;

            /**
             * DS-6540 - If the dimensions of the uploaded image match that of the image it is replacing,
             * don't show the image cropper - just use the actual image provided.
             */
            const { height, width } = section;
            const dimensions = { height, width };
            const originalDimensions = await fileUtils.getImageDimensionsFromFile(file);
            if (dimensions.height === originalDimensions.height || dimensions.width === originalDimensions.width) {
                $scope.emailBuilder.setImageLocationFromFile(section, file);
            } else {
                $scope.isImageSelectConfirmationModalShown = true;
            }
            $scope.$digest();
        };

        $scope.onFileSelectForImage = async (file: File, section: ImageSection): Promise<void> => {
            if (!section) {
                throw new Error(i18nService.text.build.email.cantAddImageElement());
            }
            const { height, width } = section;
            const dimensions = { height, width };
            /*
             * This is a hacky fix for DS-4802. Opening the cropping modal too soon causes the 'modal-open' class to be
             * removed from the body element when the new modal opens. Adding this delay fixes this issue..........
             *
             * @see https://digitalstack.atlassian.net/browse/DS-4802
             */
            await new Promise(resolve => {
                setTimeout(resolve, 500);
            });

            $scope.isImageSelectConfirmationModalShown = false;
            const croppedImage = await ImageCropperService.showCropperModal(file, dimensions, true);

            // If replacing image in an existing layer which uses a local file, remove the old file from our file cache.
            // (TODO: If it's not used in existing layers?)
            if (section && section.locationType === 'local' && section.location !== null) {
                $scope.fileCache.remove(section.location);
            }
            $scope.emailBuilder.setImageLocationFromFile(section, croppedImage);
        };

        async function getFilePathAndResource(resource: AnyResourceType): Promise<{ file: File; filePath: string }> {
            if (resource.type === 'local') {
                return { file: resource.file, filePath: URL.createObjectURL(resource.file) };
            } else if (resource.type === 'assetLibrary') {
                return imageLoaderService.loadFileFromExternal(resource.asset.url).then(loadedFile => ({
                    file: loadedFile,
                    filePath: resource.asset.url,
                }));
            } else if (resource.type === 'planner') {
                return imageLoaderService.loadFileFromExternal(resource.upload.url!).then(loadedFile => ({
                    file: loadedFile,
                    filePath: resource.upload.url!,
                }));
            } else if (resource.type === 'logo') {
                // This shouldn't happen because logos can't be used in the email builder
                return Promise.reject(new Error('Logos cannot be used in the email builder'));
            } else {
                throw assertNever(resource);
            }
        }

        /*
         * End resource picker
         */

        $scope.setFormat = (format: BuilderTemplateFormat) => {
            $scope.selectedBuilderTemplateFormats[0] = format;
            setMailMergeFieldType();
        };

        $scope.isFormatSelected = (format: BuilderTemplateFormat): boolean =>
            $scope.selectedBuilderTemplateFormats.length > 0 &&
            $scope.selectedBuilderTemplateFormats[0].id === format.id;

        function scrollToTop() {
            window.scrollTo(0, 0);
        }

        function setView(view: EmailBuilderView) {
            $scope.boundData.view = view;
            scrollToTop();
        }

        function preparePublishData(compiledHtml?: HTMLDocument) {
            const promise = compiledHtml ? $q.resolve(compiledHtml) : getCompiledTemplateHtml();
            return promise.then(htmlDocument => {
                $scope.boundData.publishData = {
                    builderDocument: $scope.emailBuilder.document,
                    fileCache: $scope.fileCache,
                    htmlDocument,
                    linkedAssetLibraryAssetIds: $scope.emailBuilder.linkedAssetLibraryAsset.map(value => ({
                        assetId: value.asset.id,
                        layerId: value.layerId,
                    })),
                    location: $scope.location!,
                    plannerDetails: $scope.plannerDetails || undefined,
                    plannerId: $scope.plannerId || undefined,
                    templateId: $scope.templateId!,
                    textSubstitutionValues: $scope.emailBuilder.textSubstitutionValues,
                };
            });
        }

        function startPublishing(compiledHtml?: HTMLDocument) {
            $scope.boundData.isPublishing = true;
            return preparePublishData(compiledHtml).then(() => setView($scope.VIEWS.PUBLISH));
        }

        function checkIfTemplateCanBePublishedAndStartPublishing() {
            confirmModal.checkAndOpen(
                () => $scope.boundData.isDraft,
                i18nService.text.build.unpublishedTemplate(),
                i18nService.text.build.unpublishedTemplatePublishWarning(),
                startPublishing,
                noop,
            );
        }

        function getCompiledTemplateHtml(): IPromise<HTMLDocument> {
            $scope.emailBuilder.updateDocumentButtonMsoLinks();
            return emailPublishService.getCompiledTemplateHtml($scope.boundData);
        }

        $scope.onClickPublish = checkIfTemplateCanBePublishedAndStartPublishing;

        $scope.isTemplateValid = () => {
            const templateValidationResults = $scope.emailBuilder
                .validateDocument()
                .filter(
                    fieldValidationResult => fieldValidationResult.severity === FieldValidationResultSeverity.Error,
                );

            const templateValid = templateValidationResults.length === 0;

            $scope.linkValidationMessages = templateValid
                ? []
                : templateValidationResults.map(result => result?.message);

            return templateValid && !!$scope.templateId;
        };

        $scope.togglePlannerDetails = () => {
            if ($scope.plannerDetails) {
                $scope.plannerDetails.visible = !$scope.plannerDetails.visible;
            }
        };

        function getScheduledTimeDisplayText(scheduledTime: Date) {
            return $filter('date')(scheduledTime, "HH:mm (Z) 'on' d MMM yyyy");
        }

        $scope.getPublishedDescription = () => {
            let desc = '';
            const result: PublishResult | null = $scope.boundData.publishResult;
            if (result) {
                if (result.scheduledTime) {
                    desc = i18nService.text.build.publish.scheduledSuccessfully({
                        platform: result.platform.displayName,
                        scheduledTime: getScheduledTimeDisplayText(result.scheduledTime),
                    });
                } else if (result.platform === ExportPlatforms.Local) {
                    desc = i18nService.text.build.publish.savedSuccessfully({
                        platform: result.platform.displayName,
                    });
                } else {
                    desc = i18nService.text.build.publish.publishedSuccessfully({
                        platform: result.platform.displayName,
                    });
                }
            }

            return desc;
        };

        function onBuilderPublishCancelEvent(event: IAngularEvent) {
            if (event.stopPropagation) {
                event.stopPropagation();
            }
            $scope.cancelPublishing();
        }

        $scope.cancelPublishing = () => {
            $scope.boundData.isPublishing = false;
            $scope.boundData.publishData = undefined;
            setView($scope.VIEWS.BUILDER);
            resetPublishData();
        };

        function onBuilderPublishFinishEvent(event: IAngularEvent, result: PublishResult) {
            if (event.stopPropagation) {
                event.stopPropagation();
            }
            $scope.finishPublishing(result);
        }

        $scope.finishPublishing = (publishResult: PublishResult) => {
            $scope.boundData.isPublishing = false;
            $scope.isDirty = false;
            setView($scope.VIEWS.PUBLISHED);
            $scope.boundData.publishResult = publishResult;
        };

        function goToPlanner() {
            $location.search({});
            $location.path('/planner');
        }

        function goToGallery() {
            $location.url(linkToGallery($scope.plannerDetails ? $scope.plannerDetails.id : undefined));
        }
        $scope.goToGallery = goToGallery;

        $scope.choosePublishAgain = () => {
            if ($scope.boundData.publishAgain === $scope.PUBLISH_AGAIN.YES) {
                return startPublishing($scope.boundData.publishData!.htmlDocument); // Reuse the HTML
            } else {
                resetPublishData();
                setView($scope.VIEWS.BUILDER);
                if ($scope.boundData.publishAgain === $scope.PUBLISH_AGAIN.DIFFERENT_TEMPLATE && $scope.plannerId) {
                    return updatePlannerStatus().then(goToGallery);
                } else if ($scope.boundData.publishAgain === $scope.PUBLISH_AGAIN.LATER && $scope.plannerId) {
                    return updatePlannerStatus().then(goToPlanner);
                } else {
                    return goToPlanner();
                }
            }
        };

        function updatePlannerStatus(): IPromise<any> {
            return interactionUtils.handleRemote(
                $scope,
                i18nService.text.build.email.updatePlannerStatus(),
                'setPlannerStatusToPlanned',
                mvPlanner.setStatusToPlanned($scope.plannerId),
            );
        }

        function resetPublishData() {
            $scope.boundData.isPublishing = false;
            $scope.boundData.publishResult = null;
        }

        // eslint-disable-next-line consistent-return
        $scope.constructPlannerUrl = (): string | undefined => {
            if ($scope.plannerDetails) {
                return PlannerUIService.getPlannerUrl($scope.plannerDetails);
            }
        };

        $scope.shouldShowPlannerDetails = () =>
            $scope.boundData.view === $scope.VIEWS.BUILDER &&
            !!$scope.plannerDetails &&
            !$scope.emailBuilder.advancedMode;

        $scope.advancedMode = () => $scope.emailBuilder.advancedMode;

        $scope.onSelectedCategoryUpdate = (categories: BuilderTemplateCategoryId[]) => {
            $scope.selectedCategoryIds = categories;
        };

        // eslint-disable-next-line consistent-return
        $scope.onClickEditSource = () => {
            if ($scope.emailBuilder.document) {
                $scope.boundData.showAllSections = true;
                return getCompiledTemplateHtml().then(
                    htmlDocument => {
                        $scope.boundData.showAllSections = false;
                        $scope.editSourceData = {
                            htmlDocument,
                        };
                        $scope.boundData.view = EmailBuilderView.EDIT_SOURCE;
                    },
                    err => {
                        $scope.boundData.showAllSections = false;
                        throw err;
                    },
                );
            }
        };

        function closeEditSourceView() {
            $scope.editSourceData = undefined;
            $scope.boundData.view = EmailBuilderView.BUILDER;
        }

        $scope.onUpdateHtml = (result: ICampaignMonitorTemplateParserResult) => {
            $scope.emailBuilder.setHtmlAndSections(result.html, result.sections);
            closeEditSourceView();
        };

        $scope.onCancelEditSource = closeEditSourceView;

        $scope.isButtonSection = isButtonSection;
        $scope.isImageSection = isImageSection;
        $scope.isTextSection = isTextSection;

        async function loadLinkedAssetLibraryAssetsForLocationDraft(locationDraftGraphqlId: string): Promise<void> {
            $scope.emailBuilder.linkedAssetLibraryAsset =
                await BuilderTemplateApiClient.getAllAssetLibraryAssetsUsedInLocationDraft(locationDraftGraphqlId);
        }

        $scope.loadLocationDraft = async (locationDraftGraphqlId: string): Promise<void> => {
            $scope.isLoading = true;
            try {
                const draftBuilderTemplate = await BuilderTemplateApiClient.getLocationDraftAsBuilderTemplate(
                    locationDraftGraphqlId,
                );
                await loadLinkedAssetLibraryAssetsForLocationDraft(locationDraftGraphqlId);
                await loadFromTemplate(draftBuilderTemplate as BuilderTemplate);
            } catch (error: unknown) {
                mvNotifier.unexpectedErrorWithData(i18nService.text.build.error.failedToRetrieveTemplate(), error);
                $scope.isLoading = false;
            }
            $scope.isLoading = false;
        };

        $scope.loadOriginalTemplate = async (): Promise<void> => {
            $scope.isLoading = true;
            try {
                const originalTemplate = $scope.originalTemplate;
                if (!originalTemplate) {
                    throw new Error('Original template could not be found');
                }
                await loadLinkedAssetLibraryAssets(originalTemplate.id);
                await loadFromTemplate(clone(originalTemplate));
            } catch (error: unknown) {
                mvNotifier.unexpectedErrorWithData(i18nService.text.build.error.failedToRetrieveTemplate(), error);
                $scope.isLoading = false;
            }
            $scope.isLoading = false;
        };

        initPage();
    },
]);
