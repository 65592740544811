import * as React from 'react';
import { AssignedLocation } from '@deltasierra/shared';
import { useAngularServiceContext } from '../../../common/componentUtils/angularServiceContexts';
import { SELECTED_LOCATION_CHANGED_EVENT_NAME } from '../../../directives/locationPicker/locationPicker';

export function useLeftNavigationClientLocation(): {
    clientId: string | null;
    locationId: string | null;
} {
    const mvLocation = useAngularServiceContext('mvLocation');
    const $rootScope = useAngularServiceContext('$rootScope');

    const [assignedLocation, setAssignedLocation] = React.useState<AssignedLocation | null>(null);

    const fetchCurrentClientLocation = React.useCallback(async () => {
        const currentLocation = await mvLocation.getLocationOrUpdateWithDefault();
        setAssignedLocation(currentLocation);
    }, [mvLocation]);

    React.useEffect(() => {
        const locationUpdate = $rootScope.$on(SELECTED_LOCATION_CHANGED_EVENT_NAME, () => {
            void fetchCurrentClientLocation();
        });
        return () => locationUpdate();
    }, [$rootScope, fetchCurrentClientLocation]);

    React.useEffect(() => {
        void fetchCurrentClientLocation();
    }, [fetchCurrentClientLocation]);

    return {
        clientId: assignedLocation?.clientGraphqlId ?? null,
        locationId: assignedLocation?.graphqlId ?? null,
    };
}
