import { gql } from '@apollo/client';

export const USER_LEFT_NAVIGATION_DRAWER_OPEN_PREFERENCE_QUERY = gql`
    query UserLeftNavigationDrawerOpenPreferenceQuery {
        me {
            id
            lavPreferenceOpen
        }
    }
`;
