import * as React from 'react';
import {
    Briefcase01,
    Calendar,
    GraduationHat02,
    Image01,
    LineChartUp01,
    MarkerPin01,
    PieChart01,
    Send01,
    Settings02,
    Ticket01,
    Tool01,
    UserCircle,
} from '@untitled-ui/icons-react';
import { useLazyQuery } from '@apollo/client';
import { Navigation } from '@deltasierra/components';
import { t } from '@deltasierra/shared';
import { useAngularServiceContext } from '../../../common/componentUtils/angularServiceContexts';
import {
    EnableNewGalleryPageFeatureFlagQuery,
    EnableNewGalleryPageFeatureFlagQueryVariables,
} from '../queries/__graphqlTypes/EnableNewGalleryPageFeatureFlagQuery';
import { ENABLE_NEW_GALLERY_PAGE_FEATURE_FLAG_QUERY } from '../queries/EnableNewGalleryPageFeatureFlag.query';
import { useAppFrontendRouter } from '../../../common/hooks/useAppFrontendRouter';

type UseLeftNavigationMenuProps = {
    clientId: string | null;
    locationId: string | null;
};

// eslint-disable-next-line max-lines-per-function
export function useLeftNavigationMenu({ clientId, locationId }: UseLeftNavigationMenuProps): {
    isLoading: boolean;
    navigation: Navigation;
} {
    const $location = useAngularServiceContext('$location');
    const $rootScope = useAngularServiceContext('$rootScope');

    const appFrontendRouter = useAppFrontendRouter();

    const [currentUrlPath, setCurrentUrlPath] = React.useState<string>($location.url());

    React.useEffect(() => {
        const unsub = $rootScope.$on('$routeChangeSuccess', event => {
            setCurrentUrlPath($location.url());
        });
        return () => unsub();
    }, [setCurrentUrlPath, $rootScope, $location]);

    const [getFeatureFlagData, { data, loading }] = useLazyQuery<
        EnableNewGalleryPageFeatureFlagQuery,
        EnableNewGalleryPageFeatureFlagQueryVariables
    >(ENABLE_NEW_GALLERY_PAGE_FEATURE_FLAG_QUERY, {
        fetchPolicy: 'network-only',
    });

    React.useEffect(() => {
        if (clientId && locationId) {
            void getFeatureFlagData({
                variables: {
                    clientId,
                    locationId,
                },
            });
        }
    }, [clientId, getFeatureFlagData, locationId]);

    const isNewGalleryPageEnabled: boolean =
        !loading &&
        (data?.me.features.enableNewGalleryPage ||
            data?.client?.features.enableNewGalleryPage ||
            data?.location?.features.enableNewGalleryPage ||
            false);

    // eslint-disable-next-line max-lines-per-function
    const navigation: Navigation = React.useMemo(() => {
        const leftNavigator: Navigation = [
            {
                id: 'SECTION 1',
                isMaintainedOnClose: true,
                items: [
                    {
                        icon: <GraduationHat02 />,
                        id: 'Learn',
                        navigationUrl: '/trythis',
                        title: t('MENU.LEARN'),
                    },
                    {
                        icon: <Calendar />,
                        id: 'Plan',
                        navigationUrl: '/planner',
                        title: t('MENU.PLAN'),
                    },
                    {
                        icon: <Image01 />,
                        id: 'Templates',
                        navigationUrl:
                            appFrontendRouter && isNewGalleryPageEnabled && clientId && locationId
                                ? appFrontendRouter.get(`/templates/?c=${clientId}&l=${locationId}`)
                                : '/builderTemplateGallery',
                        title: t('MENU.TEMPLATES'),
                    },
                    {
                        icon: <Briefcase01 />,
                        id: 'Assets',
                        navigationUrl: '/assets/view',
                        title: t('MENU.ASSETS'),
                    },
                    {
                        icon: <PieChart01 />,
                        id: 'Report',
                        navigationUrl: '/reports',
                        title: t('MENU.REPORT'),
                    },
                    {
                        icon: <Ticket01 />,
                        id: 'Special requests',
                        navigationUrl: '/specialRequests',
                        title: t('MENU.REQUESTS'),
                    },
                ],
            },
            {
                hasDividerAbove: true,
                headerTitle: 'Brand',
                id: 'SECTION 2',
                isMaintainedOnClose: false,
                items: [
                    {
                        icon: <LineChartUp01 />,
                        id: 'Reports',
                        navigationUrl: `/brand/client/${clientId}/engagement/overview`,
                        title: t('MENU.REPORTS'),
                    },
                    {
                        icon: <Send01 />,
                        id: 'Communicate',
                        navigationUrl: '/brand/communicate/send',
                        title: t('MENU.COMMUNICATE'),
                    },
                    {
                        icon: <Settings02 />,
                        id: 'Manage',
                        navigationUrl: '/brand/agency/brands',
                        title: t('MENU.MANAGE'),
                    },
                ],
            },
            {
                hasDividerAbove: true,
                headerTitle: 'Settings',
                id: 'SECTION 3',
                isMaintainedOnClose: false,
                items: [
                    {
                        icon: <Tool01 />,
                        id: 'Connect',
                        navigationUrl: '/connect',
                        title: t('MENU.CONNECT'),
                    },
                    {
                        icon: <MarkerPin01 />,
                        id: 'Location',
                        navigationUrl: '/locationDetails',
                        subItems: [
                            {
                                id: 'Hashtags',
                                navigationUrl: '/location-hashtag-sets',
                                title: t('MENU.HASHTAGS'),
                            },
                            {
                                id: 'Details',
                                navigationUrl: '/locationDetails',
                                title: t('MENU.DETAILS'),
                            },
                        ],
                        title: t('MENU.LOCATION'),
                    },
                ],
            },
            {
                hasDividerAbove: true,
                id: 'SECTION 4',
                isMaintainedOnClose: false,
                items: [
                    {
                        icon: <UserCircle />,
                        id: 'Account',
                        navigationUrl: '/profile',
                        title: t('MENU.ACCOUNT'),
                    },
                ],
            },
        ];

        const test = leftNavigator.map(section => ({
            ...section,
            items: section.items.map(item => ({
                ...item,
                isSelected:
                    currentUrlPath.startsWith(item.navigationUrl) ||
                    item.subItems?.some(subItem => currentUrlPath.startsWith(subItem.navigationUrl)),
                subItems: item.subItems?.map(subItem => ({
                    ...subItem,
                    isSelected: currentUrlPath.startsWith(subItem.navigationUrl),
                })),
            })),
        }));

        return test;
    }, [appFrontendRouter, clientId, currentUrlPath, isNewGalleryPageEnabled, locationId]);

    return { isLoading: loading, navigation };
}
