/// <reference path="../../../typings/browser.d.ts" />
import { noop } from '@deltasierra/utilities/object';
import {
    BasicUserDto,
    CanRemovePlatformResponse,
    Client,
    ClientApi,
    ClientBrandColour,
    ClientExternalLink,
    ClientId,
    ClientUserApi,
    CreateClientBrandColour,
    emptyRequest,
    GetPlatformsForClientsResult,
    justParams,
    justQuery,
    LocationsAdminView,
    LocationSubscriptionMapDto,
    paramsAndBody,
    Platform,
    Untyped,
    UpdateClientBrandColour,
    UpdateClientUserDto,
    UpdateConversionAmountsBody,
    UpdateFour51ConfigBody,
    UpdateNotificationEmailAddressesBody,
    User,
    UserId,
} from '@deltasierra/shared';
import { ClientSubscription } from '../admin/subscriptions/models';
import { $httpSID, $qSID } from '../common/angularData';
import { getData, invokeApiRoute } from '../common/httpUtils';
import { MvClientResource, mvClientResourceSID } from './mvClientResource';
import IPromise = angular.IPromise;

export class MvClient {
    public static SID = 'mvClient';

    public static readonly $inject: string[] = [$httpSID, $qSID, mvClientResourceSID];

    public constructor(
        private $http: ng.IHttpService,
        private $q: ng.IQService,
        private mvClientResource: MvClientResource,
    ) {}

    public getClients(): ng.IPromise<Client[]> {
        return invokeApiRoute(this.$http, ClientApi.getClients, emptyRequest());
    }

    public getClientById(clientId: ClientId): ng.IPromise<Client> {
        return this.$http.get<Client>(`/api/clients/${clientId}`).then(getData);
    }

    public createClient(newClientData: Untyped): ng.IPromise<Client> {
        const newClient = new this.mvClientResource(newClientData);
        const dfd = this.$q.defer<Client>();
        newClient
            .$save()
            .then(() => {
                dfd.resolve(newClient);
            })
            .catch(error => dfd.reject(error.data.reason));
        return dfd.promise;
    }

    public updateClient(clientData: Untyped): IPromise<unknown> {
        return this.$http.post('/api/clients/update', clientData).then(getData);
    }

    public getLocations(clientId: ClientId): IPromise<LocationsAdminView[]> {
        return this.$http.get<LocationsAdminView[]>(`/api/clients/${clientId}/locations`).then(getData);
    }

    public getUsers(clientId: ClientId): IPromise<User[]> {
        return invokeApiRoute(
            this.$http,
            ClientApi.getClientUsers,
            justParams({
                clientId,
            }),
        );
    }

    public getAddableClientUsersForClient(clientId: ClientId): IPromise<BasicUserDto[]> {
        return invokeApiRoute(
            this.$http,
            ClientApi.getAddableUsers,
            justParams({
                clientId,
            }),
        );
    }

    public getUser(userId: UserId): IPromise<User> {
        return invokeApiRoute(
            this.$http,
            ClientUserApi.getClientUser,
            justParams({
                userId,
            }),
        );
    }

    public updateUser(userId: UserId, userData: UpdateClientUserDto): IPromise<User> {
        return invokeApiRoute(
            this.$http,
            ClientUserApi.updateClientUser,
            paramsAndBody(
                {
                    userId,
                },
                userData,
            ),
        );
    }

    public deleteClientUser(userId: UserId): IPromise<void> {
        return invokeApiRoute(
            this.$http,
            ClientUserApi.deleteClientUser,
            justParams({
                userId,
            }),
        );
    }

    public updateDefaultSupportedPlannerLimit(clientId: ClientId, newLimit: number): ng.IHttpPromise<unknown> {
        return this.$http.post(`/api/clients/${clientId}/defaultSupportedPlannerLimit`, {
            defaultSupportedPlannerLimit: newLimit,
        });
    }

    public updateCountry(clientId: ClientId, country: string): ng.IHttpPromise<unknown> {
        return this.$http.post(`/api/clients/${clientId}/country`, {
            country,
        });
    }

    public updateCurrency(clientId: ClientId, currency: string): ng.IHttpPromise<unknown> {
        return this.$http.post(`/api/clients/${clientId}/currency`, {
            currency,
        });
    }

    public updateConversionAmounts(
        clientId: ClientId,
        conversionDetails: UpdateConversionAmountsBody,
    ): ng.IHttpPromise<unknown> {
        const { fitwareConversionAmountInCents, fitwareConversionCurrency } = conversionDetails;

        return this.$http.post(`/api/clients/${clientId}/conversionAmounts`, {
            fitwareConversionAmountInCents,
            fitwareConversionCurrency,
        });
    }

    public updateFitwareBusiness(
        clientId: ClientId,
        fitwareBusinessCode: string | null,
        fitwareBusinessName: string | null,
    ): ng.IHttpPromise<unknown> {
        return this.$http.post(`/api/clients/${clientId}/fitwareBusiness`, {
            id: fitwareBusinessCode,
            name: fitwareBusinessName,
        });
    }

    public updateNotificationEmailAddresses(
        clientId: ClientId,
        notificationEmailAddresses: UpdateNotificationEmailAddressesBody,
    ): IPromise<void> {
        return invokeApiRoute(
            this.$http,
            ClientApi.updateNotificationEmailAddresses,
            paramsAndBody({ clientId }, notificationEmailAddresses),
        );
    }

    public getSubscription(clientId: ClientId): ng.IHttpPromise<ClientSubscription> {
        return this.$http.get<ClientSubscription>(`/api/clients/${clientId}/subscription`);
    }

    public getSubscriptions(): ng.IHttpPromise<ClientSubscription[]> {
        return this.$http.get<ClientSubscription[]>('/api/clients/subscriptions');
    }

    public getLocationSubscriptions(clientId: ClientId): IPromise<LocationSubscriptionMapDto> {
        return this.$http
            .get<LocationSubscriptionMapDto>(`/api/clients/${clientId}/locationSubscriptions`)
            .then(getData);
    }

    public updateSubscription(
        clientId: ClientId,
        subscriptionData: UpdateClientSubscriptionData,
    ): ng.IHttpPromise<unknown> {
        return this.$http.post(`/api/clients/${clientId}/subscription`, subscriptionData);
    }

    public addPlatform(clientId: ClientId, platformId: number): ng.IPromise<void> {
        return this.$http.post(`/api/clients/${clientId}/platforms/${platformId}`, {}).then(noop);
    }

    public getPlatforms(clientId: ClientId): ng.IPromise<Platform[]> {
        return invokeApiRoute(
            this.$http,
            ClientApi.getPlatforms,
            justParams({
                clientId,
            }),
        );
    }

    public getPlatformsForClients(clientIds: ClientId[]): ng.IPromise<GetPlatformsForClientsResult[]> {
        return invokeApiRoute(
            this.$http,
            ClientApi.getPlatformsForClients,
            justQuery({
                clientIds,
            }),
        );
    }

    public removePlatform(clientId: ClientId, platformId: number): ng.IPromise<void> {
        return this.$http.delete(`/api/clients/${clientId}/platforms/${platformId}`).then(noop);
    }

    public canRemovePlatform(clientId: ClientId, platformId: number): ng.IPromise<CanRemovePlatformResponse> {
        return this.$http
            .get<CanRemovePlatformResponse>(`/api/clients/${clientId}/platforms/${platformId}/canRemove`)
            .then(getData);
    }

    public getClientLinks(clientId: number): IPromise<ClientExternalLink[]> {
        return this.$http.get<ClientExternalLink[]>(`/api/clients/${clientId}/links`).then(getData);
    }

    public createNewClientLink(
        clientId: number,
        newLink: { title: string; url: string; description: string },
    ): IPromise<ClientExternalLink> {
        return this.$http.put<ClientExternalLink>(`/api/clients/${clientId}/links`, newLink).then(getData);
    }

    public deleteClientLink(clientId: number, linkId: number): IPromise<ClientExternalLink> {
        return this.$http.delete<ClientExternalLink>(`/api/clients/${clientId}/links/${linkId}`).then(getData);
    }

    public updateClientLinksOrder(ids: number[], clientId: number): IPromise<ClientExternalLink[]> {
        return this.$http.patch<ClientExternalLink[]>(`/api/clients/${clientId}/links/order`, { ids }).then(getData);
    }

    public getClientBrandColours(clientId: number): IPromise<ClientBrandColour[]> {
        return this.$http.get<ClientBrandColour[]>(`/api/clients/${clientId}/brandColours`).then(getData);
    }

    public createNewClientBrandColour(
        clientId: number,
        newBrandColour: CreateClientBrandColour,
    ): IPromise<ClientBrandColour> {
        return this.$http.put<ClientBrandColour>(`/api/clients/${clientId}/brandColours`, newBrandColour).then(getData);
    }

    public deleteClientBrandColours(clientId: number, brandColourId: number): IPromise<ClientBrandColour> {
        return this.$http
            .delete<ClientBrandColour>(`/api/clients/${clientId}/brandColours/${brandColourId}`)
            .then(getData);
    }

    public updateClientBrandColour(
        clientId: number,
        brandColourId: number,
        data: UpdateClientBrandColour,
    ): IPromise<ClientBrandColour> {
        return this.$http
            .patch<ClientBrandColour>(`/api/clients/${clientId}/brandColours/${brandColourId}`, data)
            .then(getData);
    }

    public updateClientBrandColoursOrder(ids: number[], clientId: number): IPromise<ClientBrandColour[]> {
        return this.$http
            .post<ClientBrandColour[]>(`/api/clients/${clientId}/brandColours/order`, { ids })
            .then(getData);
    }

    public updateFour51Company(clientId: ClientId, body: UpdateFour51ConfigBody): ng.IPromise<void> {
        return invokeApiRoute(
            this.$http,
            ClientApi.updateFour51Config,
            paramsAndBody(
                {
                    clientId,
                },
                body,
            ),
        );
    }
}

export interface UpdateClientSubscriptionData {
    amountInCents: number;
    currency: string;
}

angular.module('app').service(MvClient.SID, MvClient);
