import { gql } from '@apollo/client';

export const ENABLE_NEW_GALLERY_PAGE_FEATURE_FLAG_QUERY = gql`
    query EnableNewGalleryPageFeatureFlagQuery($clientId: ID!, $locationId: ID!) {
        me {
            id
            features {
                enableNewGalleryPage
            }
        }
        client(id: $clientId) {
            id
            features {
                enableNewGalleryPage
            }
        }
        location(id: $locationId) {
            id
            features {
                enableNewGalleryPage
            }
        }
    }
`;
