import { BlobWithData, Upload, UploadCategory } from '@deltasierra/shared';

import { dataUrlToBlob } from '@deltasierra/utilities/web-image';
import { $qSID, $scopeSID, $timeoutSID } from '../../../common/angularData';
import { MvNotifier } from '../../../common/mvNotifier';
import { UploadService } from '../../../common/uploadService';
import { GraphqlService } from '../../../graphql/GraphqlService';
import { I18nService } from '../../../i18n';
import { MvLocation } from '../../../locations/mvLocation';
import { BuilderConstants, builderConstantsSID } from '../../builderConstants';
import { ExportData } from '../../contentBuilderRenderer';
import { ImageLoaderService } from '../../imageLoaderService';
import { BaseContentBuilderMultipleLocationPublishCtrl } from './BaseContentBuilderMultipleLocationPublishCtrl';
import IPromise = angular.IPromise;
import IQService = angular.IQService;
import IScope = angular.IScope;

const UPLOAD_TYPE: UploadCategory = 'publishedArtifacts';

export class MvMultipleLocationPublishCtrl extends BaseContentBuilderMultipleLocationPublishCtrl {
    public static SID = 'mvMultipleLocationPublishCtrl';

    public static readonly $inject: string[] = [
        $scopeSID,
        $qSID,
        $timeoutSID,
        MvNotifier.SID,
        ImageLoaderService.SID,
        builderConstantsSID,
        I18nService.SID,
        MvLocation.SID,
        UploadService.SID,
        GraphqlService.SID,
    ];

    // eslint-disable-next-line max-params
    public constructor(
        $scope: IScope,
        $q: IQService,
        $timeout: ng.ITimeoutService,
        mvNotifier: MvNotifier,
        protected readonly imageLoaderService: ImageLoaderService,
        builderConstants: BuilderConstants,
        i18n: I18nService,
        mvLocation: MvLocation,
        protected readonly uploadService: UploadService,
        graphqlService: GraphqlService,
    ) {
        super($scope, $q, $timeout, mvNotifier, imageLoaderService, builderConstants, i18n, mvLocation, graphqlService);
        this.start(this.publishCtrl.templateId);
    }

    public uploadArtifact(): IPromise<Upload> {
        return this.publishCtrl
            .prepareImage()
            .then((exportData: ExportData[]) => {
                if (exportData.length > 1) {
                    throw new Error('This publish flow does not support multi-image publishing.');
                }
                const exportDataInstance = exportData[0];
                if (!exportDataInstance) {
                    throw new Error('No export data found.');
                }
                const blob: BlobWithData = dataUrlToBlob(exportDataInstance.dataUrl!);
                // Safari does not support the File constructor
                // Const file = new File(
                //     [blob],
                //     This.publishCtrl.generateImageFileName(exportDataInstance.imageFormat),
                //     { type: blob.type },
                // );
                const now = new Date();
                blob.lastModified = now.getTime();
                blob.name = this.publishCtrl.generateImageFileName(exportDataInstance.imageFormat);
                const promises = this.uploadService.upload([blob], UPLOAD_TYPE, [], this);
                return this.$q.all<Upload>(promises);
            })
            .then((uploads: Upload[]) => uploads[0]);
    }
}

angular.module('app').controller(MvMultipleLocationPublishCtrl.SID, MvMultipleLocationPublishCtrl);
