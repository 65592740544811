import { gql } from '@apollo/client';

export const LEFT_NAVIGATION_TOP_BAR_NOTIFICATIONS_FRAGMENT = gql`
    fragment LeftNavigationTopBarNotificationsFragment on InAppNotification {
        id
        createdAt
        link
        message
        severity
        subject
        type
    }
`;
