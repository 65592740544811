/// <reference path="../_references.d.ts" />

import { getAllStringUnionValues } from '@deltasierra/shared';
import { parseBoolean } from '@deltasierra/utilities/string';
import { $locationSID, $rootScopeSID, $windowSID, IKookies } from './angularData';

declare type BootstrapBreakpointName = 'lg' | 'md' | 'sm' | 'xs';

declare interface IBootstrapBreakpoint {
    name: BootstrapBreakpointName;
    isEqualTo(size: BootstrapBreakpointName): boolean;
    isLessThan(size: BootstrapBreakpointName): boolean;
    isLessThanEqualTo(size: BootstrapBreakpointName): boolean;
    isGreaterThan(size: BootstrapBreakpointName): boolean;
    isGreaterThanEqualTo(size: BootstrapBreakpointName): boolean;
}

interface BootstrapBreakpointInfo {
    label: string;
    order: number;
}

const BOOTSTRAP_BREAKPOINT_INFO_BY_NAME: { [K in BootstrapBreakpointName]: BootstrapBreakpointInfo } = {
    xs: {
        label: 'Extra Small',
        order: 0,
    },
    sm: {
        label: 'Small',
        order: 1,
    },
    md: {
        label: 'Medium',
        order: 3,
    },
    lg: {
        label: 'Large',
        order: 4,
    },
};

const ALL_BOOTSTRAP_BREAKPOINT_NAMES = getAllStringUnionValues(BOOTSTRAP_BREAKPOINT_INFO_BY_NAME);

export type BootstrapBreakpointSizeElementMap = {
    [K in BootstrapBreakpointName]: ng.IAugmentedJQuery;
};

export type BootstrapBreakpointSizeListener = (
    size: BootstrapBreakpointName,
    oldSize?: BootstrapBreakpointName,
) => void;

function compareSizes(a: BootstrapBreakpointName, b: BootstrapBreakpointName): number {
    return BOOTSTRAP_BREAKPOINT_INFO_BY_NAME[a].order - BOOTSTRAP_BREAKPOINT_INFO_BY_NAME[b].order;
}

export class BootstrapBreakpoint implements IBootstrapBreakpoint {
    constructor(public name: BootstrapBreakpointName) {}

    isEqualTo(breakpointName: BootstrapBreakpointName): boolean {
        return this.name == breakpointName;
    }

    isLessThan(breakpointName: BootstrapBreakpointName): boolean {
        return compareSizes(this.name, breakpointName) < 0;
    }

    isLessThanEqualTo(breakpointName: BootstrapBreakpointName): boolean {
        return compareSizes(this.name, breakpointName) <= 0;
    }

    isGreaterThan(breakpointName: BootstrapBreakpointName): boolean {
        return compareSizes(this.name, breakpointName) > 0;
    }

    isGreaterThanEqualTo(breakpointName: BootstrapBreakpointName): boolean {
        return compareSizes(this.name, breakpointName) >= 0;
    }
}

export class BootstrapBreakpointService {
    static SID = 'BootstrapBreakpointService';

    private static SHOW_BREAKPOINT_QUERY_PARAM_NAME = 'showBreakpoint';

    private static SHOW_BREAKPOINT_COOKIE_NAME = 'isBreakpointLabelVisible' as const;

    private breakpointSizeElements!: BootstrapBreakpointSizeElementMap;

    private readonly currentBreakpoint: BootstrapBreakpoint;

    static readonly $inject: string[] = [$windowSID, $locationSID, '$kookies', $rootScopeSID];

    constructor(
        private readonly $window: ng.IWindowService,
        private readonly $location: ng.ILocationService,
        private readonly $kookies: IKookies,
        private readonly $rootScope: ng.IRootScopeService & { bootstrapBreakpoint: BootstrapBreakpoint },
    ) {
        this.currentBreakpoint = new BootstrapBreakpoint('sm');

        const isBreakpointLabelVisible = this.readIsBreakpointLabelVisible();
        this.writeIsBreakpointLabelVisible(isBreakpointLabelVisible);

        this.injectBreakpointMonitor(isBreakpointLabelVisible);
    }

    private readIsBreakpointLabelVisible(): boolean {
        let value = this.$location.search()[BootstrapBreakpointService.SHOW_BREAKPOINT_QUERY_PARAM_NAME];
        if (typeof value === 'undefined') {
            value = this.$kookies.get(BootstrapBreakpointService.SHOW_BREAKPOINT_COOKIE_NAME, { path: '/' });
        }
        const result = parseBoolean(value, false);
        return result;
    }

    private writeIsBreakpointLabelVisible(value: boolean): void {
        this.$kookies.set(BootstrapBreakpointService.SHOW_BREAKPOINT_COOKIE_NAME, value, { path: '/', secure: true });
    }

    private injectBreakpointMonitor(isVisible: boolean) {
        const container = angular.element(
            `<ul id="breakpointMonitor"${isVisible ? '' : ' style="display: none;"'}></ul>`,
        );

        this.breakpointSizeElements = ALL_BOOTSTRAP_BREAKPOINT_NAMES.reduce((result, name) => {
            const element = angular.element(
                `<li class="label-${name} visible-${name}-block">${BOOTSTRAP_BREAKPOINT_INFO_BY_NAME[name].label}</li>`,
            );
            result[name] = element;
            container.append(element);
            return result;
        }, {} as BootstrapBreakpointSizeElementMap);

        this.$window.document.body.appendChild(container[0]);

        this.$window.addEventListener('resize', () => this.updateBreakpointSize());

        this.updateBreakpointSize();
    }

    private determineCurrentBreakpointName(): BootstrapBreakpointName | null {
        const currentBreakpointName: BootstrapBreakpointName | null = ALL_BOOTSTRAP_BREAKPOINT_NAMES.reduce(
            (result, name) => {
                const isInBreakpoint = this.breakpointSizeElements[name].css('display') == 'block';
                result ||= isInBreakpoint ? name : null;
                return result;
            },
            null as BootstrapBreakpointName | null,
        );

        return currentBreakpointName;
    }

    private updateBreakpointSize() {
        const currentBreakpointName = this.determineCurrentBreakpointName();

        if (currentBreakpointName) {
            this.currentBreakpoint.name = currentBreakpointName;
        }
    }

    augmentRootScope() {
        this.$rootScope.bootstrapBreakpoint = this.currentBreakpoint;
    }

    getCurrentBreakpoint() {
        return this.currentBreakpoint;
    }

    onChange(listener: BootstrapBreakpointSizeListener): Function {
        return this.$rootScope.$watch(() => this.currentBreakpoint.name, listener);
    }
}

angular.module('app').service(BootstrapBreakpointService.SID, BootstrapBreakpointService);
