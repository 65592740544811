import { gql } from '@apollo/client';

export const MARK_USER_ALL_NOTIFICATIONS_READ = gql`
    mutation MarkReadAllNotifications {
        markReadAllNotifications {
            ... on MarkReadSuccess {
                numberMarkedRead
            }
            ... on MarkReadError {
                code
                message
            }
        }
    }
`;
