/// <reference path="../../../../../typings/browser.d.ts" />

import { noop } from '@deltasierra/utilities/object';
import { Upload, User } from '@deltasierra/shared';
import { $qSID } from '../../../common/angularData';
import { FileUtils } from '../../../common/fileUtils';
import { ImageCropperService } from '../../../common/imageCropper';
import { MvNotifier } from '../../../common/mvNotifier';
import { UploadContext, UploadService } from '../../../common/uploadService';
import { ImageLoaderService } from '../../../contentBuilder/imageLoaderService';
import { I18nService } from '../../../i18n/i18nService';
import { MvIdentity } from '../../mvIdentity';

import IPromise = angular.IPromise;

const CROP_SIZE = 512;
const MINIMUM_IMAGE_SIZE = 256;

export class ProfilePicturePickerController {
    static SID = 'ProfilePicturePickerController';

    user!: User;

    uploadContext = new UploadContext();

    static readonly $inject: string[] = [
        $qSID,
        I18nService.SID,
        FileUtils.SID,
        ImageCropperService.SID,
        ImageLoaderService.SID,
        UploadService.SID,
        MvIdentity.SID,
        MvNotifier.SID,
    ];

    constructor(
        private $q: ng.IQService,
        private i18n: I18nService,
        private fileUtils: FileUtils,
        private imageCropperService: ImageCropperService,
        private imageLoaderService: ImageLoaderService,
        private uploadService: UploadService,
        private identity: MvIdentity,
        private notifier: MvNotifier,
    ) {}

    private cropImage(image: File): ng.IPromise<File> {
        return this.imageCropperService.showCropperModal(image, { width: CROP_SIZE, height: CROP_SIZE });
    }

    private uploadProfilePicture(image: File): ng.IPromise<Upload> {
        const uploadPromises = this.uploadService.upload([image], 'profilePicture', [], this);
        return this.$q.all<Upload>(uploadPromises).then((uploads: Upload[]) => uploads[0]);
    }

    getImageUrl() {
        const profilePictureUrl = this.user && this.user.profilePicture && this.user.profilePicture.url;
        return profilePictureUrl || this.identity.DEFAULT_PROFILE_PICTURE_URL;
    }

    hasProfilePicture() {
        return !!(this.user && this.user.profilePicture);
    }

    isImageFormatValid(image: File): boolean {
        return this.fileUtils.isImageMimeType(image.type);
    }

    areImageDimensionsValid(image: File): ng.IPromise<boolean> {
        return this.imageLoaderService
            .getFileDimensions(image)
            .then(size => size.width >= MINIMUM_IMAGE_SIZE && size.height >= MINIMUM_IMAGE_SIZE);
    }

    setProfilePicture([file]: File[]): ng.IPromise<void> {
        if (!file) {
            return this.$q.resolve();
        }

        return this.$q
            .resolve(file)
            .then((originalImage: File) => {
                if (this.isImageFormatValid(originalImage)) {
                    return this.areImageDimensionsValid(originalImage).then(
                        (dimensionsAreValid: boolean): IPromise<Upload | void> => {
                            if (dimensionsAreValid) {
                                return this.cropImage(originalImage)
                                    .then(image =>
                                        this.uploadProfilePicture(image).then(upload => ({
                                            image,
                                            upload,
                                        })),
                                    )
                                    .then(result => this.user.profilePicture = result.upload);
                            } else {
                                this.notifier.expectedError(
                                    this.i18n.text.profile.minimumImageDimensionRequirement({
                                        width: `${MINIMUM_IMAGE_SIZE}`,
                                        height: `${MINIMUM_IMAGE_SIZE}`,
                                    }),
                                );
                                return this.$q.resolve();
                            }
                        },
                    );
                } else {
                    this.notifier.expectedError(
                        this.i18n.text.common.unsupportedImageFileType({
                            fileTypes: this.fileUtils.imageFileTypes.join(', '),
                        }),
                    );
                }
            })
            .catch(err => {
                if (err) {
                    this.notifier.unexpectedError(err.message || err);
                }
            })
            .then(noop);
    }

    clearProfilePicture() {
        this.user.profilePicture = undefined;

        // TODO: Delete uploaded resource (see DS-254)
    }
}

angular.module('app').controller(ProfilePicturePickerController.SID, ProfilePicturePickerController);
