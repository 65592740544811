import {
    AgencyId,
    AgencyUsersApi,
    AgencyUserDto,
    BasicUserDto,
    InviteAgencyUserDto,
    SanitizedUser,
    UpdateManagerDto,
    User,
    UserId,
    justParams,
    paramsAndBody,
} from '@deltasierra/shared';
import { noop } from '@deltasierra/utilities/object';
import { MvIdentity } from '../account/mvIdentity';
import { $httpSID } from '../common/angularData';
import { invokeApiRoute } from '../common/httpUtils';

import IPromise = angular.IPromise;

export class AgencyUserApiClient {
    public static readonly SID = 'AgencyUserApiClient';

    public static readonly $inject: string[] = [$httpSID, MvIdentity.SID];

    public constructor(protected readonly $http: ng.IHttpService, protected readonly mvIdentity: MvIdentity) {}

    public deleteManager(agencyId: AgencyId, userId: UserId): IPromise<void> {
        return invokeApiRoute(
            this.$http,
            AgencyUsersApi.deleteManager,
            justParams({
                agencyId,
                userId,
            }),
        ).then(noop);
    }

    public inviteManager(agencyId: AgencyId, data: InviteAgencyUserDto): IPromise<SanitizedUser> {
        return invokeApiRoute(
            this.$http,
            AgencyUsersApi.inviteManager,
            paramsAndBody(
                {
                    agencyId,
                },
                data,
            ),
        );
    }

    public getManager(agencyId: AgencyId, userId: UserId): IPromise<User> {
        return invokeApiRoute(
            this.$http,
            AgencyUsersApi.getManager,
            justParams({
                agencyId,
                userId,
            }),
        );
    }

    public updateManager(agencyId: AgencyId, userId: UserId, userUpdates: UpdateManagerDto): IPromise<User> {
        return invokeApiRoute(
            this.$http,
            AgencyUsersApi.updateManager,
            paramsAndBody(
                {
                    agencyId,
                    userId,
                },
                userUpdates,
            ),
        );
    }

    public getManagers(): IPromise<AgencyUserDto[]> {
        const agencyId = this.mvIdentity.currentUser!.agencyId;
        return invokeApiRoute(
            this.$http,
            AgencyUsersApi.getManagers,
            justParams({
                agencyId,
            }),
        );
    }

    public getClientUsers(): IPromise<BasicUserDto[]> {
        const agencyId = this.mvIdentity.currentUser!.agencyId;
        return invokeApiRoute(
            this.$http,
            AgencyUsersApi.getClientUsers,
            justParams({
                agencyId,
            }),
        );
    }

    public getOrphanClientUsers(): IPromise<BasicUserDto[]> {
        const agencyId = this.mvIdentity.currentUser!.agencyId;
        return invokeApiRoute(
            this.$http,
            AgencyUsersApi.getOrphanClientUsers,
            justParams({
                agencyId,
            }),
        );
    }
}

angular.module('app').service(AgencyUserApiClient.SID, AgencyUserApiClient);
