import { config as i18nConfig } from '@deltasierra/i18n';
import { AnyError, HttpResponseCode, Locale, Untyped } from '@deltasierra/shared';
import { camelCase, upperCaseWithUnderscores } from '@deltasierra/utilities/string';
import i18next from 'i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

// Hack to allow this file to compile outside of webpack. (Required for generating directive data for use by tcat)
const config: {
    countryCodeToLocaleCode: { [key: string]: string };
    locales: Array<{
        code: string;
        title: string;
    }>;
    namespaces: string[];
    sourceLocaleCode: string;
} = i18nConfig;

export const LOCALES: ReadonlyArray<Locale> = Object.freeze(config.locales);
export const SOURCE_LOCALE: Readonly<Locale> = Object.freeze(
    LOCALES.find(locale => locale.code === config.sourceLocaleCode)!,
);
export const NAMESPACES: ReadonlyArray<string> = Object.freeze(config.namespaces);

function downloadFile(url: string, callback: (err: Error | null, data?: any) => void) {
    const xmlHttp = new XMLHttpRequest();
    xmlHttp.onreadystatechange = function onreadystatechange() {
        if (this.readyState === XMLHttpRequest.DONE) {
            if (this.status === HttpResponseCode.Okay) {
                callback(null, this.responseText);
            } else {
                callback(new Error(`Failed to retrieve international currency data. Status: ${this.status}`));
            }
        }
    };
    xmlHttp.open('GET', url, true);
    xmlHttp.send();
}

function loadCurrencyDefinitions(callback: (err?: Error) => void) {
    function addCurrencyCodeToCurrencies(currencies: Record<string, unknown>) {
        for (const currencyCode of Object.getOwnPropertyNames(currencies)) {
            (currencies as Untyped)[currencyCode].code = currencyCode;
        }
    }

    downloadFile(`api/currencyDefinitions?h=${DELTASIERRA_I18N_HASH}`, (err, content) => {
        if (err) {
            return callback(err);
        }

        const currencies = JSON.parse(content);
        addCurrencyCodeToCurrencies(currencies);
        angular.module('app').constant('currencyDefinitions', currencies);
        return callback();
    });
}

export function initialise(cb: (err: Error, t: any) => any): void {
    loadCurrencyDefinitions(currencyLoadingError => {
        if (currencyLoadingError) {
            console.error(currencyLoadingError);
            return cb(currencyLoadingError, null);
        }
        return i18next
            .use(HttpApi)
            .use(LanguageDetector)
            .init(
                {
                    appendNamespaceToMissingKey: true,
                    /**
                     * For backend options
                     *
                     * @see https://github.com/i18next/i18next-http-backend
                     */
                    backend: {
                        loadPath: 'api/translations/{{lng}}/{{ns}}',
                        // eslint-disable-next-line id-length
                        queryStringParams: { h: DELTASIERRA_I18N_HASH },
                    },
                    defaultNS: '',
                    /**
                     * For detection options
                     *
                     * @see https://github.com/i18next/i18next-browser-languageDetector
                     */
                    detection: {
                        // Cache user language on
                        caches: ['cookie'],
                        cookieOptions: { sameSite: 'Lax', secure: true },
                        // Optional htmlTag with lang attribute, the default is:
                        htmlTag: document.documentElement,
                        lookupCookie: 'i18next',
                        // Keys or params to lookup language from
                        lookupQuerystring: 'lng',
                        // Order and from where user language should be detected
                        order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag'],
                    },
                    fallbackLng: window.environmentType === 'development' ? (false as any) : SOURCE_LOCALE.code,
                    fallbackNS: '',
                    keySeparator: '.',
                    load: 'currentOnly',
                    missingKeyHandler: (languages: string[], ns: string, key: string, fallbackValue: string) =>
                        // This handler doesn't really do anything, but you have to specify one for
                        // The parseMissingKeyHandler to work properly
                        fallbackValue,
                    ns: NAMESPACES.map(namespace => camelCase(namespace)),
                    nsSeparator: '.',
                    parseMissingKeyHandler: (key: string) => {
                        if (window.environmentType === 'development') {
                            const dotNotationKey = key.replace(':', '.');
                            const [namespace, ...keyParts] = dotNotationKey.split('.');
                            const namespaceWithUnderscores = upperCaseWithUnderscores(namespace);
                            return `NO TRANS: ${namespaceWithUnderscores}.${keyParts.join('.')}`;
                        }

                        return '';
                    },
                    pluralSeparator: '_',
                    saveMissing: true,
                },
                (err: AnyError, translateFunction: any) => {
                    if (err) {
                        console.error(err.message || err);
                    }
                    return cb(err, translateFunction);
                },
            );
    });
}

(window as Untyped).i18nBootstrap = initialise;
