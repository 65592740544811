import * as React from 'react';
import { getEntries, noop } from '@deltasierra/utilities/object';
import { useAngularServiceContext } from './angularServiceContexts';
import IScope = angular.IScope;

/**
 * Create an AngularJS scope as a React hook. This is useful for integrating React with AngularJS (ie: accessing AngularJS components
 * from React).
 *
 * @param defaults - Default values for the scope
 * @param watchers - Watchers for any of the values in the scope
 * @param eventListeners - Event listeners
 * @returns The new AngularJS scope with the default values applied
 */
export function useAngularScope<T>(
    defaults: Partial<T> = {},
    watchers: Partial<{ [K in keyof T]: (newValue: T[K]) => void }> = {},
    eventListeners: { [key: string]: (args: any) => void } = {},
): React.MutableRefObject<IScope & T> {
    const $rootScope = useAngularServiceContext('$rootScope');

    const watcherCallbacks = React.useRef<Array<(newValue: any, oldValue: any) => void>>([]);

    const eventListenersDeregisterCallbacks = React.useRef<Array<() => void>>([]);
    const scope = React.useRef($rootScope.$new(true) as IScope & T);
    const $timeout = useAngularServiceContext('$timeout');

    React.useEffect(() => {
        for (const [key, value] of Object.entries(defaults)) {
            scope.current[key] = value;
        }

        // Wrap the apply to only occur after the $digest step
        void $timeout(() => {
            scope.current.$apply();
        });
    }, [scope, defaults, $timeout]);

    const watcherEntries = getEntries(watchers);

    // These are the ref functions to make sure we hit the right values
    watcherCallbacks.current = watcherEntries.map(([key, value]) => (newValue, oldValue) => {
        if (newValue !== oldValue) {
            value?.(scope.current[key]);
        }
    });

    // Register the watchers
    React.useEffect(() => {
        // For each of the entries, we will watch the entry and call the up to date ref callback
        const deregisterEntries = watcherEntries.map(
            ([key, value = noop], index) =>
                scope.current.$watch(key.toString(), (newValue, oldValue) =>
                    watcherCallbacks.current[index](newValue, oldValue),
                ) as () => void,
        );
        return () => {
            deregisterEntries.forEach(deregister => deregister());
        };
        // This here will make the useEffect run if the string keys change in the watchers object
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...watcherEntries.map(entry => entry[0])]);

    eventListenersDeregisterCallbacks.current = getEntries(eventListeners).map(
        ([key, callback]) => scope.current.$on(key.toString(), callback) as () => void,
    );

    useDeregisterCallbacks(eventListenersDeregisterCallbacks.current);

    React.useEffect(
        () => () => {
            scope.current.$destroy();
        },
        [scope],
    );

    return scope;
}

/**
 * React hook to easily render an AngularJS component.
 *
 * @example
 * const scope = useAngularScope<{ name: string, onChange: (newName: string) => void }>();
 * scope.current.name = 'Frank Smith';
 * scope.current.onChange = newName => {
 *     console.log(newName);
 * };
 * return useAngularComponentRenderer('<my-component name="name" ng-change="onChange()"></my-component>', scope);
 * @param html - The component to render
 * @param scope - The AngularJS scope for the component to use and integrate with
 * @returns - A div element that contains the rendered AngularJS component
 */
export function useAngularComponentRenderer(html: string, scope: React.RefObject<IScope>): JSX.Element {
    const [ref, setRef] = React.useState<HTMLDivElement | null>();
    const [didRender, setDidRender] = React.useState(false);
    const $compile = useAngularServiceContext('$compile');

    React.useEffect(() => {
        if (!didRender && scope.current && ref) {
            ref.innerHTML = html;
            $compile(ref)(scope.current);
            setDidRender(true);
        }
    }, [$compile, didRender, html, ref, scope]);

    return <div ref={setRef} />;
}

function useDeregisterCallbacks(deregisterCallbacks: Array<() => void>) {
    React.useEffect(() => () => {
        deregisterCallbacks.forEach(deregister => deregister());
    });
}
