import { gql } from '@apollo/client';
import { LEFT_NAVIGATION_TOP_BAR_NOTIFICATIONS_FRAGMENT } from '../fragments/LeftNavigationTopBarNotifications.fragment';

export const USER_UNREAD_NOTIFICATIONS = gql`
    query UserUnreadNotifications {
        me {
            id
            unreadNotifications {
                edges {
                    node {
                        id
                        ...LeftNavigationTopBarNotificationsFragment
                    }
                }
            }
        }
    }
    ${LEFT_NAVIGATION_TOP_BAR_NOTIFICATIONS_FRAGMENT}
`;
