/// <reference path="../../../../typings/browser.d.ts" />
import { InAppNotification } from '@deltasierra/shared';
import { startsWith } from '@deltasierra/utilities/string';
import { MvIdentity } from '../../account/mvIdentity';
import { $rootScopeSID, $routeParamsSID, $scopeSID } from '../../common/angularData';
import { InteractionUtils } from '../../common/interactionUtils';
import { I18nService } from '../../i18n/i18nService';
import { BaseInAppNotificationsController } from '../baseInAppNotificationController';
import { InAppNotificationApiClient } from '../inAppNotificationApiClient';
import IPromise = angular.IPromise;
import IRootScopeService = angular.IRootScopeService;
import IScope = angular.IScope;

export class InAppNotificationsDetailsController extends BaseInAppNotificationsController {
    static SID = 'inAppNotificationsDetailsController';

    notificationId: number;

    notification: InAppNotification | undefined;

    notifications: InAppNotification[] = []; // Silly hack so we can re-use the existing templates, which expect an array.

    readonly fetchNotification = this.interactionUtils.createFuture('fetch notification', () =>
        this.inAppNotificationApiClient.getNotification(this.notificationId).then((notification: InAppNotification) => {
            this.notification = notification;
            this.notifications.length = 0;
            this.notifications.push(notification);
        }),
    );

    static readonly $inject: string[] = [
        $routeParamsSID,
        $rootScopeSID,
        $scopeSID,
        I18nService.SID,
        InteractionUtils.SID,
        InAppNotificationApiClient.SID,
        MvIdentity.SID,
    ];

    constructor(
        $routeParams: { notificationId: string },
        $rootScope: IRootScopeService,
        $scope: IScope,
        i18nService: I18nService,
        interactionUtils: InteractionUtils,
        inAppNotificationApiClient: InAppNotificationApiClient,
        mvIdentity: MvIdentity,
    ) {
        super($rootScope, $scope, i18nService, interactionUtils, inAppNotificationApiClient, mvIdentity);
        this.notificationId = parseInt($routeParams.notificationId, 10);
        void this.onInit(); // This is called automatically for components, but not directive controllers... how annoying!
    }

    onInit() {
        this.listenToMarkedAsReadEvent((event, data) => this.handleMarkedAsRead(data));
        this.listenToMarkedAllAsReadEvent(event => this.handleMarkedAllAsRead());
        return this.fetchNotification.run({});
    }

    protected handleMarkedAsRead(notification: InAppNotification): void {
        notification.readAt = new Date(); // This date is not entirely accurate, but it doesn't really matter here
    }

    protected handleMarkedAllAsRead(): IPromise<void> | void {
        this.notifications.length = 0;
    }

    markAsRead(notification: InAppNotification): IPromise<any> | void {
        if (!notification.readAt) {
            return this.submitMarkAsRead.run({ notification });
        }
    }

    markAsClicked(notification: InAppNotification): IPromise<any> | void {
        if (!notification.clickedAt) {
            return this.submitMarkAsClicked.run({ notification });
        }
    }

    onClickNotification(notification: InAppNotification): IPromise<void> | void {
        this.markAsRead(notification);
        this.markAsClicked(notification);
    }

    getNotificationLink(notification: InAppNotification): string | null {
        return notification.link;
    }

    getLinkTargetWindow(notification: InAppNotification): '_blank' | undefined {
        const link = this.getNotificationLink(notification);
        if (link && startsWith(link, 'http')) {
            return '_blank';
        }
        return undefined;
    }

    shouldShowSubject(notification: InAppNotification): boolean {
        return true;
    }

    shouldShowMessage(notification: InAppNotification): boolean {
        return true;
    }

    shouldShowCallToActionButton(notification: InAppNotification): boolean {
        return notification.type === 'Agency.CustomMessage' && !!notification.link;
    }
}

angular.module('app').controller(InAppNotificationsDetailsController.SID, InAppNotificationsDetailsController);
