import * as React from 'react';
import { findByPredicate, t } from '@deltasierra/shared';
import { contains } from '@deltasierra/utilities/array';

import { OptionsList, useCleanedOptionsAndStoredTypes } from './common';
import { FieldIntegrationProps, withFormField } from './helpers';

export type CheckboxesProps<TValue extends string | { toString(): string }> = {
    dataCy?: string;
    valueDisabled?: (value: TValue) => boolean;
    options: OptionsList<TValue>;
};

function Checkboxes<T extends string | { toString(): string }>(
    props: CheckboxesProps<T> & FieldIntegrationProps<T[]>,
): React.ReactElement {
    const [cleanedOptions, typedValueMap] = useCleanedOptionsAndStoredTypes(props.options);
    const onChange = (event: React.ChangeEvent<any>) => {
        const { checked, value } = event.target;
        const typedValue = typedValueMap[value]!;
        if (checked && !contains(props.field.value, typedValue)) {
            props.field.onChange([...props.field.value, typedValue]);
        } else if (!checked) {
            props.field.onChange(props.field.value.filter(x => x !== typedValue));
        }
    };
    const isChecked = (value: T) => !!findByPredicate(x => x === value, props.field.value);

    return (
        <div>
            {cleanedOptions.map(({ label, value }) => (
                <Checkbox
                    checked={isChecked(value)}
                    dataCy={props.dataCy}
                    disabled={props.valueDisabled?.(value)}
                    key={value.toString()}
                    label={label.toString()}
                    name={props.field.name}
                    value={value.toString()}
                    onChange={onChange}
                />
            ))}
        </div>
    );
}
Checkboxes.displayName = 'Checkboxes';

export const CheckboxesFieldInput = withFormField()(Checkboxes);

type CheckboxProps = {
    disabled?: boolean;
    dataCy?: string;
    label: string;
    value: string;
    checked: boolean;
    name?: string;
    onChange: React.ChangeEventHandler<any>;
};

const Checkbox: React.FunctionComponent<CheckboxProps> = ({
    checked,
    dataCy,
    disabled,
    label,
    name,
    onChange,
    value,
}) => (
    <div className="checkbox" data-cy={dataCy}>
        <label>
            <input
                checked={checked}
                disabled={disabled}
                name={name}
                type="checkbox"
                value={value}
                onChange={onChange}
            />
            <span style={{ opacity: disabled ? 0.5 : 1 }}>{label}</span>
        </label>
    </div>
);
Checkbox.displayName = 'Checkbox';

const CheckboxToggle: React.FunctionComponent<FieldIntegrationProps<boolean>> = ({ field }) => (
    <Checkbox
        checked={field.value}
        label={t('COMMON.ENABLED')}
        name={field.name}
        value="enabled"
        onChange={event => field.onChange(event.target.checked)}
    />
);
CheckboxToggle.displayName = 'CheckboxToggle';

export const CheckboxToggleFieldInput = withFormField()(CheckboxToggle);
