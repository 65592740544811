import { ApolloProvider } from '@apollo/client';
import { LegacyThemeProvider } from '@deltasierra/components';
import { mapValues } from '@deltasierra/utilities/object';
import * as React from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import { createRoot, Root } from 'react-dom/client';
import { client } from '../../graphql';
import {
    $elementSID,
    $scopeSID,
    actualComponent,
    BindingType,
    ChangesObject,
    ExpressionBinding,
    ILifecycleHooks,
    InjecteeClassConstructor,
    OptionalBindingType,
    OptionalExpressionBinding,
} from '../angularData';
import { NotifierProvider } from '../components/Notifier';
import IScope = angular.IScope;
import IComponentOptions = angular.IComponentOptions;
import IAugmentedJQuery = angular.IAugmentedJQuery;

const USE_REACT_18_WRAPPER = false;

type ComponentBindings<T> = { [K in keyof T]: BindingType | OptionalBindingType };

function withProviders<T extends {}>(WrappedComponent: React.ComponentType<T>): React.ComponentType<T> {
    const WrappedWithProviders: React.FC<T> = (props: T) => (
        <React.StrictMode>
            <ApolloProvider client={client}>
                <LegacyThemeProvider>
                    <NotifierProvider>
                        <WrappedComponent {...props} />
                    </NotifierProvider>
                </LegacyThemeProvider>
            </ApolloProvider>
        </React.StrictMode>
    );
    WrappedWithProviders.displayName = 'WrappedWithProviders';
    return WrappedWithProviders;
}
/**
 * Makes a React component available for AngularJS
 *
 * Example one:
 *
 *  ```ts
 *  interface MyProps { foobar: string }
 *  class MyComponent<MyProps, MyState> {...}
 *  export default withAngularIntegration(MyComponent, 'myComponent', { foobar: OneWayBinding })
 *  ```
 *
 * Example two:
 *
 *  ```ts
 *  interface MyProps { foobar: string }
 *  function MyComponent(props: MyProps) {...}
 *  export default withAngularIntegration(MyComponent, 'myComponent', { foobar: OneWayBinding })
 *  ```
 *
 * @param Component - The component to register
 * @param componentName - The name of the component (ie: myComponent)
 * @param bindings - The AngularJS component bindings
 * @returns React component
 */
export function withAngularIntegration<TProps extends {}>(
    Component: React.ComponentType<TProps>,
    componentName: string,
    bindings: ComponentBindings<TProps>,
): React.ComponentType<TProps> & { angularComponentConfig: IComponentOptions; SID: string } {
    const WrappedComponent = withProviders(Component);
    const controllerClass = wrapReactComponentInAngularController(WrappedComponent, bindings);
    const config = actualComponent<TProps>(controllerClass, '', bindings);
    angular.module('app').component(componentName, config);

    const innerComponentName = WrappedComponent.displayName || 'Component';
    return class extends React.PureComponent<TProps> {
        public static readonly angularComponentConfig = config;

        public static readonly displayName: string = `WithAngularIntegration(${innerComponentName})`;

        public static readonly SID = componentName;

        public render() {
            return <WrappedComponent {...this.props} />;
        }
    };
}

export interface IReactAngularController extends ILifecycleHooks {
    // Override the ILifecycleHooks interface $onChanges method with a more generic one
    $onChanges(changes: ChangesObject<any>): void;

    $onDestroy(): void;

    $onInit(): void;
}

/**
 * Creates an AngularJS Controller that manages the lifecycle of a react component. This includes rendering and
 * updating props.
 *
 * @param WrappedComponent - The React component to register with AngularJS
 * @param bindings - The AngularJS bindings
 * @returns The AngularJS component controller
 */
export function wrapReactComponentInAngularController<TProps extends Record<string, any>>(
    WrappedComponent: React.ComponentType<TProps>,
    bindings: ComponentBindings<TProps>,
): InjecteeClassConstructor<IReactAngularController & TProps> {
    return USE_REACT_18_WRAPPER
        ? (class implements ILifecycleHooks {
              // This is a work around for this potential issue: https://github.com/angular/angular.js/issues/14240
              // Noinspection JSUnusedGlobalSymbols
              public static $$ngIsClass = true;

              // Noinspection JSMismatchedCollectionQueryUpdate
              public static $inject: string[] = [$scopeSID, $elementSID];

              private isFirstRender = true;

              private props!: TProps;

              private root!: Root;

              public constructor(private $scope: IScope, private $element: IAugmentedJQuery) {}

              public $onChanges(changes: ChangesObject<this>) {
                  const typeAdjustedChanges = changes as ChangesObject<TProps>;
                  const newProps = mapValues(typeAdjustedChanges, value => value!.currentValue);
                  const newPropsWithCallbacksWrapped = wrapCallbacksInAngularTracking(this.$scope, newProps, bindings);
                  const oldProps = this.props;
                  this.props = { ...oldProps, ...newPropsWithCallbacksWrapped };

                  if (!this.isFirstRender && this.didPropsChange(newProps, oldProps)) {
                      this.render();
                  }
              }

              public $onDestroy() {
                  this.root.unmount();
              }

              public $onInit(): void {
                  this.root = createRoot(this.$element[0]);

                  const props = mapValues(bindings, (_, bindingKey) => (this as any)[bindingKey]);
                  const newPropsWithCallbacksWrapped = wrapCallbacksInAngularTracking(this.$scope, props, bindings);
                  const oldProps = this.props;
                  this.props = { ...oldProps, ...newPropsWithCallbacksWrapped };
                  this.render();
                  this.isFirstRender = false;
              }

              private didPropsChange(newProps: Partial<TProps>, oldProps: Partial<TProps>): boolean {
                  return Object.keys(newProps).some(key => newProps[key] !== oldProps[key]);
              }

              private render() {
                  this.root.render(<WrappedComponent {...this.props} />);
              }
          } as unknown as InjecteeClassConstructor<IReactAngularController & TProps>)
        : (class implements ILifecycleHooks {
              // This is a work around for this potential issue: https://github.com/angular/angular.js/issues/14240
              // Noinspection JSUnusedGlobalSymbols
              public static $$ngIsClass = true;

              // Noinspection JSMismatchedCollectionQueryUpdate
              public static $inject: string[] = [$scopeSID, $elementSID];

              private isFirstRender = true;

              private props!: TProps;

              public constructor(private $scope: IScope, private $element: IAugmentedJQuery) {}

              public $onChanges(changes: ChangesObject<this>) {
                  const typeAdjustedChanges = changes as ChangesObject<TProps>;
                  const newProps = mapValues(typeAdjustedChanges, value => value!.currentValue);
                  const oldProps = this.props;
                  this.props = { ...oldProps, ...newProps };

                  if (!this.isFirstRender && this.didPropsChange(newProps, oldProps)) {
                      this.render();
                  }
              }

              public $onDestroy() {
                  unmountComponentAtNode(this.$element[0]);
              }

              public $onInit(): void {
                  const props = mapValues(bindings, (_, bindingKey) => (this as any)[bindingKey]);
                  const newPropsWithCallbacksWrapped = wrapCallbacksInAngularTracking(this.$scope, props, bindings);
                  const oldProps = this.props;
                  this.props = { ...oldProps, ...newPropsWithCallbacksWrapped };
                  this.render();
                  this.isFirstRender = false;
              }

              private didPropsChange(newProps: Partial<TProps>, oldProps: Partial<TProps>): boolean {
                  return Object.keys(newProps).some(key => newProps[key] !== oldProps[key]);
              }

              private render() {
                  render(<WrappedComponent {...this.props} />, this.$element[0]);
              }
          } as unknown as InjecteeClassConstructor<IReactAngularController & TProps>);
}

function wrapCallbacksInAngularTracking<TProps>(
    $scope: IScope,
    props: TProps,
    bindings: ComponentBindings<TProps>,
): TProps {
    return mapValues(props as unknown as Record<string, unknown>, (value, key) => {
        const bindingType = bindings[key as keyof TProps];
        return isAngularExpressionBinding(value, bindingType)
            ? convertCallbackToAngularTrackedCallback(value, key as string & keyof TProps, bindingType, $scope)
            : value;
    }) as TProps;
}

/**
 * Marks each expression binding so it is tracked by AngularJS and any changes will be reflected in the UI. Also wraps the expression
 * bindings so that it's easy to call.
 *
 * Example:
 * If you bind your expression/function like `<foo on-change="ctrl.onChange" />` you can call it like a regular function and not
 * have to worry about locals or double invoking, ie: `onChange(arg1, agr2)`
 *
 * @param callback - The callback to wrap in Angular
 * @param bindingName - The name of the binding/callback
 * @param bindingType - The type of binding (one-way, expression, ...)
 * @param $scope - The AngularJS scope
 * @returns The wrapped callbacks
 */
function convertCallbackToAngularTrackedCallback<TProps>(
    callback: ((...args: any[]) => any) | undefined,
    bindingName: string & keyof TProps,
    bindingType: BindingType | OptionalBindingType,
    $scope: IScope,
): ((...args: any[]) => any) | undefined {
    if (bindingType === ExpressionBinding && !callback) {
        throw new Error(`Expression binding '${bindingName}' is marked as required but is undefined`);
    } else if (!callback) {
        return undefined;
    } else {
        return (...args: any[]): any => {
            const digestPhases = ['$apply', '$digest'];
            if (digestPhases.indexOf($scope.$$phase) >= 0 || digestPhases.indexOf($scope.$root.$$phase) >= 0) {
                return callback()(...args);
            } else {
                return $scope.$apply(() => callback()(...args));
            }
        };
    }
}

function isAngularExpressionBinding(
    object: any,
    bindingType: BindingType | OptionalBindingType,
): object is (() => any) | undefined {
    return bindingType === ExpressionBinding || bindingType === OptionalExpressionBinding;
}
