import * as React from 'react';
import { AssignedLocation } from '@deltasierra/shared';
import { noop } from '@deltasierra/utilities/object';
import { useAngularComponentRenderer, useAngularScope } from '../../common/componentUtils/angularRendering';

type LocationPickerAngularScope = { selectedLocationId?: string, onChange: (location: AssignedLocation) => void };

export type LocationPickerWrapperProps = {
    selectedLocationId?: string;
    onChange?: (location: AssignedLocation) => void;
};

const LocationPickerWrapper: React.FunctionComponent<LocationPickerWrapperProps> = ({ onChange, selectedLocationId }) => {
    const scope = useAngularScope<LocationPickerAngularScope>({
        onChange: onChange ?? noop,
        selectedLocationId,
    });
    const html = '<location-picker selected-location-id="selectedLocationId" change="onChange(location)"></location-picker>';
    return useAngularComponentRenderer(html, scope);
};
LocationPickerWrapper.displayName = 'LocationPickerWrapper';

export default LocationPickerWrapper;
