import { useLazyQuery, useQuery } from '@apollo/client';
import { hasAtLeastOneItem } from '@deltasierra/utilities/array';
import { t } from '@deltasierra/shared';
import * as React from 'react';
import { FormikErrors } from 'formik';
import { useConnectionFetchMore } from '../../../graphql/hooks';
import {
    GET_CLIENTS_FOR_COPY_TEMPLATE_MODAL_QUERY,
    GET_CURRENT_CLIENT_FOR_COPY_TEMPLATE_MODAL_QUERY,
    GET_LOCATION_FOR_COPY_TEMPLATE_MODAL_QUERY,
} from './CopyTemplateModal.queries';
import { GetClientsForCopyTemplateModal, GetClientsForCopyTemplateModalVariables } from './__graphqlTypes/GetClientsForCopyTemplateModal';
import { GetLocationForCopyTemplateModal } from './__graphqlTypes/GetLocationForCopyTemplateModal';
import { GetCurrentClientForCopyTemplateModal, GetCurrentClientForCopyTemplateModalVariables } from './__graphqlTypes/GetCurrentClientForCopyTemplateModal';

export const useCopyTemplateModal = (
    builderTemplateId: string,
    onSubmit: (
        clientIds: string[],
        templateId: string,
        includeCategories: boolean,
        isCopyAsUnpublished: boolean,
    ) => void,
) => {
    const [searchFilter, setSearchFilter] = React.useState<string>('');

    const { data: locationData, loading: locationLoading } = useQuery<GetLocationForCopyTemplateModal>(
        GET_LOCATION_FOR_COPY_TEMPLATE_MODAL_QUERY,
    );

    const [fetchCurrentClient, { data: currentClientData, loading: currentClientLoading }] = useLazyQuery<
        GetCurrentClientForCopyTemplateModal,
        GetCurrentClientForCopyTemplateModalVariables
    >(GET_CURRENT_CLIENT_FOR_COPY_TEMPLATE_MODAL_QUERY, {
        notifyOnNetworkStatusChange: true,
    });

    const currentClientId = locationData?.location?.client.id ?? null;

    React.useEffect(() => {
        if (currentClientId) {
            void fetchCurrentClient({ variables: { id: currentClientId } });
        }
    }, [currentClientId, fetchCurrentClient]);

    const currentBrandId = currentClientData?.client?.brand?.id ?? null;

    const [fetchBrandClients, { data: clientData, fetchMore, loading: clientsLoading }] = useLazyQuery<
        GetClientsForCopyTemplateModal,
        GetClientsForCopyTemplateModalVariables
    >(GET_CLIENTS_FOR_COPY_TEMPLATE_MODAL_QUERY, {
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-and-network',
        notifyOnNetworkStatusChange: true,
    });

    React.useEffect(() => {
        if (currentBrandId) {
            void fetchBrandClients({
                variables: {
                    brandId: currentBrandId,
                    first: 30,
                    title: searchFilter,
                },
            });
        }
    }, [currentBrandId, fetchBrandClients, searchFilter]);

    const targetClientOptions = React.useMemo(() => {
        if (!clientData || !clientData.brand || !currentBrandId) {
            return [];
        }
        return clientData.brand?.clients.edges
            .filter(({ node }) => node.id !== currentClientId)
            .map(client => client.node);
    }, [clientData, currentClientId, currentBrandId]);

    const [handleFetchMore, hasNextPage] = useConnectionFetchMore(
        clientData?.brand?.clients,
        async (after, first = 30) => fetchMore?.({ variables: { after, first } }),
    );

    const handleValidation = (values: typeof initialValues): FormikErrors<typeof initialValues> => {
        const errors: FormikErrors<typeof initialValues> = {};
        if (!hasAtLeastOneItem(values.selectedClientIds)) {
            errors.selectedClientIds = t('COMMON.REQUIRED_FIELD_MESSAGE');
        }
        return errors;
    };

    const handleSubmit = (values: typeof initialValues) => {
        onSubmit(values.selectedClientIds, builderTemplateId, values.includeCategories, values.isCopyAsUnpublished);
    };

    const initialValues: {
        includeCategories: boolean;
        isCopyAsUnpublished: boolean;
        selectedClientIds: string[];
    } = {
        includeCategories: false,
        isCopyAsUnpublished: true,
        selectedClientIds: [],
    };

    return {
        clientsLoading,
        currentClientLoading,
        handleFetchMore,
        handleSubmit,
        handleValidation,
        hasNextPage,
        initialValues,
        locationLoading,
        searchFilter,
        setSearchFilter,
        targetClientOptions,
    };
};
