/* eslint-disable max-statements */
/* eslint-disable max-lines-per-function */
/// <references path="../../_references.d.ts" />
import { reduceMergeArray } from '@deltasierra/utilities/array';
import {
    BuilderDocument,
    BuilderDocumentDimensions,
    BuilderDocumentRenderer,
    BuilderFontCustomDto,
    BuilderPrintDocument,
    checkFontContainsCharacters,
    ClientId,
    convertUnits,
    DomTextMeasurer,
    FALLBACK_FONTS,
    getPageDimensions,
    ImageLayer,
    IRectangle,
    isImageLayer,
    isMapLayer,
    isTextLayer,
    Layer,
    MapLayer,
    PrintUnits,
    TaskTracker,
    TextLayer,
    TextSubstitutionValues,
    TextToFallbackFontLink,
} from '@deltasierra/shared';
import { isNullOrUndefined, Untyped } from '@deltasierra/type-utilities';
import { IQResolveReject, IQService } from 'angular';
import * as linq from 'linq';
import { $qSID } from '../../common/angularData';
import { FileUtils } from '../../common/fileUtils';
import { ngPromiseToPromiseLike } from '../../common/QPromiseLibrary';
import { BuilderFontApiClient } from '../builderFontClientApi';
import { TextSubstitutionService } from '../common/textSubstitutionService';
import { ImageCache } from '../imageLoaderService';
import { PdfRenderContext } from './pdfRenderContext';

declare const PDFDocument: PDFKit.PDFDocument;

declare const blobStream: () => BlobStream.IBlobStream;

export type CheckPdfRequiresFallbackFontsOptions = {
    clientId: ClientId;
    document: BuilderDocument;
    textSubstitutionValues: TextSubstitutionValues;
};
export interface IExportOptions {
    clientId: ClientId;
    document: BuilderDocument;
    imageCache: ImageCache;
    textSubstitutionValues: TextSubstitutionValues;
    includeBleedAndTrim: boolean;
    updateProgress?: (value: number, max: number) => void;
    dimensions?: BuilderDocumentDimensions;
}

export class PdfBuilderService {
    public static readonly $inject: string[] = [
        $qSID,
        FileUtils.SID,
        TextSubstitutionService.SID,
        BuilderFontApiClient.SID,
    ];

    public static readonly SID = 'PdfBuilderService';

    private static readonly MapIconFont = 'Font Awesome 5 Pro';

    private static readonly MapIconFontUrl = '/vendor/fontawesome/webfonts/fa-solid-900.ttf';

    private marginInPixels = convertUnits(10, PrintUnits.millimetres, PrintUnits.pixels);

    private documentRenderer!: BuilderDocumentRenderer;

    public constructor(
        private $q: IQService,
        private fileUtils: FileUtils,
        private textSubstitutionService: TextSubstitutionService,
        private builderFontApiClient: BuilderFontApiClient,
    ) {
        this.documentRenderer = new BuilderDocumentRenderer();
    }

    /**
     * Copies the functionality from the export pdf function below, but just
     * checks whether we use fallback fonts or not.
     *
     * @param options - The options
     * @returns A Promise booleanE
     */
    public async checkPdfRequiresFallbackFonts(options: CheckPdfRequiresFallbackFontsOptions): Promise<boolean> {
        const substituteText = (text: string | null) =>
            this.textSubstitutionService.substitute(options.textSubstitutionValues, text);

        const fontFamilies = this.getUsedFontFamilies(options.document);
        const fontUrlMap = await ngPromiseToPromiseLike(this.getFontUrlMap(options.clientId, fontFamilies));

        const loadedFonts = await Promise.all(
            fontFamilies.map(async family => {
                const font = await ngPromiseToPromiseLike(this.downloadFont(family, fontUrlMap[family]));
                return {
                    [font.family]: font.buffer,
                };
            }),
        );
        const fontDataMap = reduceMergeArray(loadedFonts);

        return this.shouldLoadFallbackFonts(options.document, substituteText, fontDataMap);
    }

    public exportToPdf(options: IExportOptions): ng.IPromise<PDFKit.PDFDocument> {
        return this.$q<PDFKit.PDFDocument>(
            async (resolve: ng.IQResolveReject<PDFKit.PDFDocument>, reject: ng.IQResolveReject<any>) => {
                try {
                    let pdf: PDFKit.PDFDocument = null as unknown as PDFKit.PDFDocument;
                    const substituteText = (text: string | null) =>
                        this.textSubstitutionService.substitute(options.textSubstitutionValues, text);

                    const fontFamilies = this.getUsedFontFamilies(options.document);
                    let fontUrlMap: { [family: string]: string } = {};
                    const fontDataMap: { [family: string]: ArrayBuffer } = {};
                    const imageUrls = this.getUsedImageUrls(options.document);
                    const imageDataMap: { [url: string]: any } = {};
                    const customFonts: BuilderFontCustomDto[] = [];
                    const fallbackFontDataMap: { [family: string]: ArrayBuffer } = {};
                    let textToFallbackFontLink: TextToFallbackFontLink[] = [];

                    const taskTracker = new TaskTracker(task => {
                        if (options.updateProgress) {
                            options.updateProgress(task.resolvedCount + task.rejectedCount, task.count);
                        }
                    });

                    const tasks = {
                        createPdf: taskTracker.createTask(() => {
                            pdf = new PDFDocument({ autoFirstPage: false });
                        }),
                        downloadFallbackFonts: taskTracker.createTask(async () => {
                            if (this.shouldLoadFallbackFonts(options.document, substituteText, fontDataMap)) {
                                const promises = Object.keys(FALLBACK_FONTS).map(async fontFamily => {
                                    await this.downloadFont(
                                        fontFamily,
                                        `/fonts/fallback/${FALLBACK_FONTS[fontFamily]}`,
                                    ).then(font => {
                                        fallbackFontDataMap[font.family] = font.buffer;
                                    });
                                });
                                await Promise.all(promises);
                                // Create a link between text and fonts for use in rendering at retrieving font metrics.
                                textToFallbackFontLink = this.mapTextToFallbackFont(
                                    options.document,
                                    substituteText,
                                    fontDataMap,
                                    fallbackFontDataMap,
                                );
                            }
                        }),
                        downloadFonts: fontFamilies.map(family =>
                            taskTracker.createTask(
                                async () =>
                                    await this.downloadFont(family, fontUrlMap[family]).then(font => {
                                        fontDataMap[font.family] = font.buffer;
                                    }),
                            ),
                        ),
                        downloadImages: taskTracker.createTask(() =>
                            Promise.all(
                                imageUrls.map(url =>
                                    taskTracker.createTask(
                                        async () =>
                                            await this.downloadImage(options.document, url).then(image => {
                                                imageDataMap[image.url] = image.data;
                                            }),
                                    )(),
                                ),
                            ),
                        ),
                        embedFonts: taskTracker.createTask(async () => {
                            const promises = [];
                            for (const family in fontDataMap) {
                                if (Object.prototype.hasOwnProperty.call(fontDataMap, family)) {
                                    const data = fontDataMap[family];
                                    promises.push(this.embedFont(pdf, { data, family }));
                                }
                            }
                            for (const link of textToFallbackFontLink) {
                                if (Object.prototype.hasOwnProperty.call(fallbackFontDataMap, link.fallbackFont)) {
                                    const data = fallbackFontDataMap[link.fallbackFont];
                                    promises.push(this.embedFont(pdf, { data, family: link.fallbackFont }));
                                }
                            }
                            await Promise.all(promises);
                        }),
                        getClientFonts: taskTracker.createTask(async () =>
                            this.builderFontApiClient.getFontsForClient(options.clientId).then(fontConfig => {
                                customFonts.push(...fontConfig.custom);
                            }),
                        ),
                        getFontUrlMap: taskTracker.createTask(async () =>
                            this.getFontUrlMap(options.clientId, fontFamilies).then(map => {
                                fontUrlMap = map;
                            }),
                        ),
                        renderDocument: taskTracker.createTask(async () =>
                            this.renderDocument(
                                pdf,
                                imageDataMap,
                                substituteText,
                                options,
                                customFonts,
                                textToFallbackFontLink,
                            ),
                        ),
                        requireBlobStreamLibrary: taskTracker.createTask(async () =>
                            this.fileUtils.requireScript('/vendor/blob-stream/blob-stream.min.js'),
                        ),
                        requirePdfKitLibrary: taskTracker.createTask(async () =>
                            this.fileUtils.requireScript('/lib/pdfkit/js/pdfkit.standalone.js'),
                        ),
                    };

                    await tasks.requireBlobStreamLibrary();
                    await tasks.requirePdfKitLibrary();
                    await tasks.createPdf();
                    await tasks.getClientFonts();
                    await tasks.getFontUrlMap();
                    await Promise.all(tasks.downloadFonts.map(downloadFontAction => downloadFontAction()));
                    await tasks.downloadFallbackFonts();
                    await tasks.embedFonts();
                    await tasks.downloadImages();
                    await tasks.renderDocument();

                    return resolve(pdf);
                } catch (error) {
                    return reject(error);
                }
            },
        );
    }

    public pdfToBlob(pdf: PDFKit.PDFDocument): ng.IPromise<BlobStream.IBlobStream> {
        return this.$q((resolve: IQResolveReject<BlobStream.IBlobStream>, reject: IQResolveReject<any>) => {
            const blob = blobStream();

            // To determine when the PDF has finished being written successfully
            // We need to confirm the following 2 conditions:
            //
            //   1. The write stream has been closed
            //   2. PDFDocument.end() was called synchronously without an error being thrown
            let pendingStepCount = 2;

            const stepFinished = () => {
                if (--pendingStepCount === 0) {
                    resolve(blob);
                }
            };

            blob.on('error', () => reject());

            blob.on(window ? 'finish' : 'close', () => {
                stepFinished();
            });
            pdf.pipe(blob);

            pdf.end();

            stepFinished();
        });
    }

    private getUsedFontFamilies(document: BuilderDocument): string[] {
        const clientFonts: string[] = linq
            .from(document.layers)
            .where((layer: Layer) => layer.visible && isTextLayer(layer))
            .select((layer: Layer) => (layer as TextLayer).fontFamily)
            .where((fontFamily: string) => !!fontFamily)
            .distinct()
            .toArray();

        if (document.layers.some(x => isMapLayer(x))) {
            clientFonts.push(PdfBuilderService.MapIconFont);
        }

        return clientFonts;
    }

    private getUsedImageUrls(document: BuilderDocument): string[] {
        return linq
            .from(document.layers)
            .where(
                (layer: Layer) =>
                    layer && layer.visible && (isImageLayer(layer) || isMapLayer(layer)) && !!layer.location,
            )
            .select((layer: Layer) => (layer as ImageLayer | MapLayer).location)
            .distinct()
            .toArray() as string[];
    }

    private downloadImage(document: BuilderDocument, url: string): ng.IPromise<{ url: string; data: any }> {
        return this.fileUtils.downloadToArrayBuffer(url).then(data => ({
            data,
            url,
        }));
    }

    private getFontUrlMap(clientId: ClientId, families: string[]): ng.IPromise<{ [family: string]: string }> {
        const getFontConfig = (): ng.IPromise<{
            custom: Array<{
                family: string;
                upload: { url: string | null };
            }>;
        }> => this.builderFontApiClient.getFontsForClient(clientId);

        const result = getFontConfig().then(config =>
            config.custom
                .concat([
                    {
                        family: PdfBuilderService.MapIconFont,
                        upload: {
                            url: PdfBuilderService.MapIconFontUrl,
                        },
                    },
                ])
                .filter(font => families.indexOf(font.family) > -1)
                .map(font => ({ family: font.family, url: font.upload.url }))
                .reduce((map, font) => {
                    map[font.family] = font.url!;
                    return map;
                }, {} as { [family: string]: string }),
        );

        return result;
    }

    private downloadFont(family: string, url: string): ng.IPromise<{ family: string; buffer: ArrayBuffer }> {
        return this.fileUtils.downloadToArrayBuffer(url).then(buffer => ({ buffer, family }));
    }

    private embedFont(pdf: PDFKit.PDFDocument, font: { family: string; data: ArrayBuffer }) {
        return this.$q((resolve: IQResolveReject<void>, reject: IQResolveReject<any>) => {
            pdf.registerFont(font.family, font.data as any);
            resolve();
        });
    }

    private getPageDimensions(
        document: BuilderPrintDocument,
        options: IExportOptions,
        pageIndex: number,
    ): BuilderDocumentDimensions {
        const dimensions = getPageDimensions(document, pageIndex, options.dimensions);
        return dimensions;
    }

    private addPage(pdf: PDFKit.PDFDocument, options: IExportOptions, pageIndex: number) {
        const document = options.document as BuilderPrintDocument;
        const { includeBleedAndTrim } = options;
        const dimensions = this.getPageDimensions(document, options, pageIndex);
        const bleedInPoints =
            (includeBleedAndTrim || 0) &&
            convertUnits(document.bleed.amount || 0, document.bleed.units || PrintUnits.millimetres, PrintUnits.points);
        const marginInPoints =
            (includeBleedAndTrim || 0) && convertUnits(this.marginInPixels, PrintUnits.pixels, PrintUnits.points);

        const size = [
            convertUnits(dimensions.width, dimensions.unit as any, PrintUnits.points) +
                bleedInPoints * 2 +
                marginInPoints * 2,
            convertUnits(dimensions.height, dimensions.unit as any, PrintUnits.points) +
                bleedInPoints * 2 +
                marginInPoints * 2,
        ];

        pdf.addPage({ size });

        this.setupTrimBox(pdf, document, options, pageIndex);
        this.setupBleedBox(pdf, document, options, pageIndex);
    }

    private calculateTrimBox(document: BuilderPrintDocument, options: IExportOptions, pageIndex: number): IRectangle {
        const dimensions = this.getPageDimensions(document, options, pageIndex);

        const bleedInPoints =
            (options.includeBleedAndTrim || 0) &&
            convertUnits(document.bleed.amount || 0, document.bleed.units || PrintUnits.millimetres, PrintUnits.points);
        const marginInPoints =
            (options.includeBleedAndTrim || 0) &&
            convertUnits(this.marginInPixels, PrintUnits.pixels, PrintUnits.points);

        return {
            height: convertUnits(dimensions.height, dimensions.unit as any, PrintUnits.points),
            width: convertUnits(dimensions.width, dimensions.unit as any, PrintUnits.points),
            x: marginInPoints + bleedInPoints,
            y: marginInPoints + bleedInPoints,
        };
    }

    private setupTrimBox(
        pdf: PDFKit.PDFDocument,
        document: BuilderPrintDocument,
        options: IExportOptions,
        pageIndex: number,
    ) {
        const trimBox = this.calculateTrimBox(document, options, pageIndex);

        (pdf.page.dictionary.data as Untyped).TrimBox = [
            trimBox.x,
            trimBox.y,
            trimBox.x + trimBox.width,
            trimBox.y + trimBox.height,
        ];
    }

    private calculateBleedBox(document: BuilderPrintDocument, options: IExportOptions, pageIndex: number) {
        const dimensions = this.getPageDimensions(document, options, pageIndex);

        const bleedInPoints =
            (options.includeBleedAndTrim || 0) &&
            convertUnits(document.bleed.amount || 0, document.bleed.units || PrintUnits.millimetres, PrintUnits.points);
        const marginInPoints =
            (options.includeBleedAndTrim || 0) &&
            convertUnits(this.marginInPixels, PrintUnits.pixels, PrintUnits.points);

        return {
            height: convertUnits(dimensions.height, dimensions.unit as any, PrintUnits.points) + bleedInPoints * 2,
            width: convertUnits(dimensions.width, dimensions.unit as any, PrintUnits.points) + bleedInPoints * 2,
            x: marginInPoints,
            y: marginInPoints,
        };
    }

    private setupBleedBox(
        pdf: PDFKit.PDFDocument,
        document: BuilderPrintDocument,
        options: IExportOptions,
        pageIndex: number,
    ) {
        const bleedBox = this.calculateBleedBox(document, options, pageIndex);

        // Remember... http://goo.gl/w3WPS7
        (pdf.page.dictionary.data as Untyped).BleedBox = [
            bleedBox.x,
            bleedBox.y,
            bleedBox.x + bleedBox.width,
            bleedBox.y + bleedBox.height,
        ];
    }

    // eslint-disable-next-line max-params
    private renderDocument(
        pdf: PDFKit.PDFDocument,
        imageUrlMap: { [url: string]: any },
        substituteText: (text: string) => string,
        options: IExportOptions,
        customFonts: BuilderFontCustomDto[],
        textToFallbackFontLink: TextToFallbackFontLink[],
    ): ng.IPromise<void> {
        return this.$q((resolve: IQResolveReject<void>, reject: IQResolveReject<any>) => {
            const textMeasurer = new DomTextMeasurer(
                (): HTMLDivElement => document.createElement('div'),
                (element: HTMLElement) => document.body.appendChild(element),
            );
            const originalSize = options.document.dimensions;
            const targetSize = options.dimensions;

            const doc = options.document as BuilderPrintDocument;

            const globalTransform = { scale: { x: 1, y: 1 } };

            // If a target size was supplied, then we need to scale the trim area
            // Note we are not using a global scale because we want margin and bleed
            // To be the same amount regardless of our export size
            // E.g. an A5 doc with 5mm bleed should still have 5mm bleed when exported to A3
            const trimTransform = targetSize
                ? {
                      scale: {
                          x:
                              convertUnits(targetSize.width, targetSize.unit as any, PrintUnits.pixels) /
                              convertUnits(originalSize.width, originalSize.unit as any, PrintUnits.pixels),
                          y:
                              convertUnits(targetSize.height, targetSize.unit as any, PrintUnits.pixels) /
                              convertUnits(originalSize.height, originalSize.unit as any, PrintUnits.pixels),
                      },
                  }
                : {
                      scale: { x: 1, y: 1 },
                  };

            for (let pageIndex = 0; pageIndex < doc.pages.length; pageIndex++) {
                this.addPage(pdf, options, pageIndex);

                this.documentRenderer.renderDocument({
                    bleedScale: 1,
                    clipToTrimBox: false,
                    context: new PdfRenderContext(pdf, customFonts, textToFallbackFontLink, {
                        textMeasurer,
                        useOldTextRenderingLineHeightFix: options.document.useOldTextRenderingLineHeightFix,
                        useTextRenderingLineHeightFix: options.document.useTextRenderingLineHeightFix,
                    }),
                    customFonts,
                    document: options.document,
                    globalTransform,
                    imageLayerToImageSource: layer => (layer.location ? imageUrlMap[layer.location] : null),
                    includeBleed: options.includeBleedAndTrim,
                    includeTrimMarks: options.includeBleedAndTrim,
                    margin: (options.includeBleedAndTrim || 0) && this.marginInPixels,
                    pageIndex,
                    substituteText,
                    textToFallbackFontLink,
                    trimTransform,
                    useOldTextRenderingLineHeightFix: options.document.useOldTextRenderingLineHeightFix,
                    useTextRenderingLineHeightFix: options.document.useTextRenderingLineHeightFix,
                    videoLayerToVideoSource: layer => {
                        throw new Error("Can't include a video layer on a PDF");
                    },
                });
            }
            textMeasurer.delete();
            return resolve();
        });
    }

    private shouldLoadFallbackFonts(
        document: BuilderDocument,
        substituteText: (text: string | null) => string,
        fontDataMap: { [family: string]: ArrayBuffer } = {},
    ): boolean {
        const layers = document.layers;
        for (const layer of layers) {
            if (isTextLayer(layer) && !isNullOrUndefined(fontDataMap[layer.fontFamily])) {
                const fontFamily = layer.fontFamily;
                const substitutedText = substituteText(layer.text);
                if (!checkFontContainsCharacters(fontFamily, fontDataMap[fontFamily], substitutedText)) {
                    return true;
                }
            }
        }
        return false;
    }

    private mapTextToFallbackFont(
        document: BuilderDocument,
        substituteText: (text: string | null) => string,
        fontDataMap: { [family: string]: ArrayBuffer } = {},
        fallbackFontDataMap: { [family: string]: ArrayBuffer } = {},
    ): TextToFallbackFontLink[] {
        const layers = document.layers;
        const result: TextToFallbackFontLink[] = [];
        for (const layer of layers) {
            if (isTextLayer(layer) && !isNullOrUndefined(fontDataMap[layer.fontFamily])) {
                const fontFamily = layer.fontFamily;
                const substitutedText = substituteText(layer.text);
                if (!checkFontContainsCharacters(fontFamily, fontDataMap[fontFamily], substitutedText)) {
                    const fallbackFonts = Object.keys(fallbackFontDataMap);
                    // Go through avaliable fallback fonts and try to find a font that can render the text.
                    for (const fallbackFont of fallbackFonts) {
                        if (
                            checkFontContainsCharacters(
                                fallbackFont,
                                fallbackFontDataMap[fallbackFont],
                                substitutedText,
                            )
                        ) {
                            result.push({
                                fallbackFont,
                                selectedFont: fontFamily,
                                text: substitutedText,
                            });
                            break;
                        }
                    }
                }
            }
        }
        return result;
    }
}

angular.module('app').service(PdfBuilderService.SID, PdfBuilderService);
