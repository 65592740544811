
import { noop } from '@deltasierra/utilities/object';
import {
    AssignedLocation,
    CreatePayableServiceCost,
    Currency,
    PayableService,
    PayableServiceCost,
    UpdatePayableServiceCost,
} from '@deltasierra/shared';
import { $qSID } from '../../common/angularData';
import { InteractionUtils } from '../../common/interactionUtils';
import { MvNotifier } from '../../common/mvNotifier';
import { I18nService } from '../../i18n/i18nService';
import { CurrencyService } from '../../payments/currencyService';
import { PayableServiceService } from '../../payments/payableServiceService';

interface LocalPayableServiceCost extends PayableServiceCost {
    amount: number;
}

interface GroupedPayableService extends PayableService {
    costs: LocalPayableServiceCost[];
}

export class PayableServicesController {
    public static SID = 'PayableServicesController';

    public static readonly $inject: string[] = [
        $qSID,
        MvNotifier.SID,
        InteractionUtils.SID,
        CurrencyService.SID,
        PayableServiceService.SID,
        I18nService.SID,
    ];

    public fetchData = this.interactionUtils.createFuture(this.i18n.text.common.fetchData(), () =>
        this.$q
            .all([this.payableServiceService.getAllServices(), this.payableServiceService.getAllServicesCosts()])
            .then((data: [PayableService[], PayableServiceCost[]]) => {
                const [services, costs] = data;
                const costsWithDollars = this.getCostsInDollars(costs);
                this.services = this.groupServiceCosts(services, costsWithDollars);
            }),
    );

    public updateServiceCost = this.interactionUtils.createFuture(
        this.i18n.text.admin.payableService.updateServiceCost(),
        (context: { costId: number; data: UpdatePayableServiceCost }) =>
            this.payableServiceService.updateServiceCost(context.costId, context.data),
    );

    public createServiceCost = this.interactionUtils.createFuture(
        this.i18n.text.admin.payableService.createServiceCost(),
        (context: { serviceId: number; data: CreatePayableServiceCost; localCost: LocalPayableServiceCost }) =>
            this.payableServiceService.createServiceCost(context.serviceId, context.data).then(cost => {
                context.localCost.id = cost.id;
            }),
    );

    public deleteServiceCost = this.interactionUtils.createFuture(
        this.i18n.text.admin.payableService.deleteServiceCost(),
        (context: { costId: number }) => this.payableServiceService.deleteServiceCost(context.costId),
    );

    public services: GroupedPayableService[] = [];

    public availableCurrencies: ReadonlyArray<Currency> = [];

    public location: AssignedLocation | null = null;

    public constructor(
        private readonly $q: ng.IQService,
        private readonly notifier: MvNotifier,
        private readonly interactionUtils: InteractionUtils,
        private readonly currencyService: CurrencyService,
        private readonly payableServiceService: PayableServiceService,
        private readonly i18n: I18nService,
    ) {
        this.availableCurrencies = this.currencyService.CURRENCIES;
        void this.fetchData.run({});
    }

    public getAvailableCurrencies(service: GroupedPayableService): unknown {
        return this.currencyService.CURRENCIES.filter(
            x => service.costs && !service.costs.some(cost => cost.currency == x.code),
        );
    }

    public addCurrency(service: GroupedPayableService, currency: Currency): void {
        const newCost: Partial<LocalPayableServiceCost> = {
            amount: 0,
            amountInCents: 0,
            currency: currency.code,
        };
        service.costs.push(newCost as any);
    }

    public async deleteCurrency(service: GroupedPayableService, cost: LocalPayableServiceCost): Promise<void> {
        // We only create new costs on save
        let promise;
        if (cost.id) {
            promise = this.deleteServiceCost.run({ costId: cost.id }).then(noop);
        } else {
            promise = this.$q.resolve();
        }

        return promise.then(() => {
            service.costs = service.costs.filter(x => !(x.currency == cost.currency));
        });
    }

    public getCurrencySymbol(currencyCode: string): string {
        const currency = this.currencyService.findCurrencyWithDefault(currencyCode);
        return currency.symbol || '$';
    }

    public updateServiceCosts(service: GroupedPayableService): void {
        const serviceCostsToUpdate = service.costs
            .filter(cost => cost.amount * 100 != cost.amountInCents && cost.id)
            .map(serviceCost => ({
                costId: serviceCost.id,

                data: {
                    amountInCents: serviceCost.amount * 100,
                } as UpdatePayableServiceCost,
            }));

        const costsToCreate = service.costs
            .filter(cost => !cost.id)
            .map(cost => ({
                data: {
                    amountInCents: cost.amount * 100,
                    currency: cost.currency,
                },
                localCost: cost,
                serviceId: service.id,
            }));

        serviceCostsToUpdate.map(update => this.updateServiceCost.run(update));
        costsToCreate.forEach(cost => this.createServiceCost.run(cost));

        this.notifier.notify(this.i18n.text.admin.payableService.notifyServiceCostsUpdated());
    }

    private getCostsInDollars(costs: PayableServiceCost[]): LocalPayableServiceCost[] {
        return costs.map(cost => ({
            ...cost,
            amount: cost.amountInCents / 100,
        }));
    }

    private groupServiceCosts(services: PayableService[], costs: LocalPayableServiceCost[]): GroupedPayableService[] {
        return services.map(service => ({
            ...service,
            costs: costs.filter(cost => cost.payableServiceId == service.id),
        }));
    }
}

angular.module('app').controller(PayableServicesController.SID, PayableServicesController);
