
/// <reference path="../../../../typings/browser.d.ts" />

import { Activity, sanitiseActivityLabel, Channel, Platform, ClientId } from '@deltasierra/shared';


import { noop } from '@deltasierra/utilities/object';

import { MvNotifier } from '../../common/mvNotifier';
import { InteractionUtils } from '../../common/interactionUtils';
import { DataUtils } from '../../common/dataUtils';
import { ChannelMigratorService } from '../channelMigrator';
import { I18nService } from '../../i18n/i18nService';
import { MvClient } from '../mvClient';
import { MvActivity } from '../../activities/mvActivity';
import { $qSID, $routeParamsSID, $scopeSID, IRouteParams } from '../../common/angularData';
import IQService = angular.IQService;

interface ChannelTypeEntry {
    label: string;
    cssClass: string;
}

interface ClientActivitiesScope extends ng.IScope {
    newActivityForm: ng.IFormController;
}

export class ClientActivitiesController {
    public static SID = 'ClientActivitiesController';

    public static readonly $inject: string[] = [
        $routeParamsSID,
        $qSID,
        $scopeSID,
        ChannelMigratorService.SID,
        MvNotifier.SID,
        MvActivity.SID,
        InteractionUtils.SID,
        DataUtils.SID,
        I18nService.SID,
        MvClient.SID,
    ];

    public CHANNEL_TYPES = Object.freeze({
        GENERAL: {
            cssClass: '',
            label: 'General',
        },
        SELF_SERVICE: {
            cssClass: 'text-success',
            label: 'Self service',
        },
        SUPPORTED: {
            cssClass: 'text-info',
            label: 'Supported',
        },
    });


    public client_id: ClientId; // Tslint:disable-line

    public activityLabel?: string;

    public buildingPlatforms?: Platform[];

    public activities?: Activity[];

    public readonly addActivity = this.interactionUtils.createFuture(this.i18n.text.agency.client.activities.addActivity(), () => {
        const newActivityData = {
            clientId: this.client_id,
            label: this.activityLabel,
            order: this.activities!.length,
        };

        return this.activityService.createActivity(newActivityData).then(activity => {
            this.notifier.notify(this.i18n.text.agency.client.location.activities.activityAdded());
            this.activityLabel = '';
            this.$scope.newActivityForm.$setPristine();
            return this.refreshData.run({});
        });
    });

    public readonly deleteActivity = this.interactionUtils.createFuture<void, { activity: Activity }>(
        this.i18n.text.agency.client.activities.deleteActivity(),
        context =>
            this.activityService.deleteActivity(this.client_id, context.activity.id).then(() => {
                this.notifier.notify(this.i18n.text.agency.client.location.activities.activityRemoved());
                // eslint-disable-next-line promise/no-nesting
                return this.refreshData.run({}).then(noop);
            }),
    );

    public readonly deleteChannel = this.interactionUtils.createFuture<void, { channel: Channel }>(
        this.i18n.text.agency.client.activities.deleteChannel(),
        async context =>
            this.channelMigratorService
                .showModal({
                    activities: this.activities!,
                    channel: context.channel,
                })
                .then(() => this.refreshData.run({}))
                .catch(error => {
                    // This is required to ignore modal escape key and blank error notifications
                    if (error && error !== 'escape key press') {
                        throw error;
                    }
                })
                .then(noop),
    );

    private readonly retrieveBuildingPlatforms = this.interactionUtils.createFuture(
        this.i18n.text.agency.client.activities.retrieveBuildingPlatforms(),
        () =>
            this.clientService
                .getPlatforms(this.client_id)
                .then(platforms => platforms.filter(platform => platform.building))
                .then(platforms => {
                    this.buildingPlatforms = platforms;
                }),
    );

    private readonly retrieveActivities = this.interactionUtils.createFuture(
        this.i18n.text.agency.client.activities.retrieveActivities(),
        () =>
            this.activityService
                .getActivities(this.client_id)
                .then(activities => this.dataUtils.filterBy('deletedAt', activities, null))
                .then(activities => {
                    this.activities = activities.map(activity => {
                        activity.channels = this.dataUtils.filterBy('deletedAt', activity.channels || [], null);
                        return activity;
                    });
                }),
    );

    private readonly refreshData = this.interactionUtils.createFuture(this.i18n.text.agency.client.activities.refreshData(), () =>
        this.$q.all([this.retrieveActivities.run({}), this.retrieveBuildingPlatforms.run({})]).then(noop),
    );

    private readonly updateActivityOrder = this.interactionUtils.createFuture(
        this.i18n.text.agency.client.activities.updateActivityOrder(),
        () => {
            const ids: number[] = this.dataUtils.pluckByGetter(activity => activity.id, this.activities!);

            return this.activityService.updateActivityOrder(ids, this.client_id).then(() => {
                for (const activity of this.activities!) {
                    activity.order = ids.indexOf(activity.id);
                }
            });
        },
    );

    // eslint-disable-next-line max-params
    public constructor(
        $routeParams: IRouteParams,
        private readonly $q: IQService,
        private readonly $scope: ClientActivitiesScope,
        private readonly channelMigratorService: ChannelMigratorService,
        private readonly notifier: MvNotifier,
        private readonly activityService: MvActivity,
        private readonly interactionUtils: InteractionUtils,
        private readonly dataUtils: DataUtils,
        private readonly i18n: I18nService,
        private readonly clientService: MvClient,
    ) {
        this.client_id = ClientId.from(parseInt($routeParams.id, 10));
        void this.refreshData.run({});
    }

    // Need to declare like this (rather than method) because our current version of
    // Angular wouldn't allow binding or using an arrow function within the template?
    // Seems to have been fixed somewhere between angular 1.5.11 and 1.6.5
    // (See it working as a method fine here in 1.6.5: https://codepen.io/codeandcats/pen/eEzjVa/)
    public isActivityLabelUnique = (label: string): boolean => {
        if (!this.activities) {
            return true;
        }
        const sanitizedLabel = sanitiseActivityLabel(label);
        const isUnique = !this.activities.some(activity => sanitiseActivityLabel(activity.label) === sanitizedLabel);
        return isUnique;
    };

    public getChannelTypeLabel(channel: Channel): string {
        return this.getChannelType(channel).label;
    }

    public getChannelClass(channel: Channel): string {
        return this.getChannelType(channel).cssClass;
    }

    // eslint-disable-next-line consistent-return
    public moveActivityUp(activityToMove: Activity): ng.IPromise<void> | void {
        const wasMoved = this.dataUtils.moveUp(activityToMove, this.activities!);
        if (wasMoved) {
            return this.updateActivityOrder.run({});
        }
    }

    // eslint-disable-next-line consistent-return
    public moveActivityDown(activityToMove: Activity): ng.IPromise<void> | void {
        const wasMoved = this.dataUtils.moveDown(activityToMove, this.activities!);
        if (wasMoved) {
            return this.updateActivityOrder.run({});
        }
    }

    private getChannelType(channel: Channel): ChannelTypeEntry {
        if (channel.isSelfService) {
            return this.CHANNEL_TYPES.SELF_SERVICE;
        } else if (channel.isSupported) {
            return this.CHANNEL_TYPES.SUPPORTED;
        } else {
            return this.CHANNEL_TYPES.GENERAL;
        }
    }
}

angular.module('app').controller(ClientActivitiesController.SID, ClientActivitiesController);
