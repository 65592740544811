import { gql } from '@apollo/client';

export const MARK_USER_NOTIFICATION_READ = gql`
    mutation MarkUserNotificationRead($input: MarkReadNotificationsInput!) {
        markReadNotification(input: $input) {
            ... on MarkReadSuccess {
                numberMarkedRead
            }
            ... on MarkReadError {
                code
                message
            }
        }
    }
`;
