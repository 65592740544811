import { LEFT_NAV_COLLAPSED_ENABLED_BODY_CLASS, LEFT_NAV_EXTENDED_ENABLED_BODY_CLASS } from '@deltasierra/components';
import * as React from 'react';

export function useUpdateLeftNavigationBodyClasses({ isDrawerOpen }: { isDrawerOpen: boolean }) {
    React.useEffect(() => {
        if (isDrawerOpen) {
            document.body.classList.add(LEFT_NAV_EXTENDED_ENABLED_BODY_CLASS);
            if (document.body.classList.contains(LEFT_NAV_COLLAPSED_ENABLED_BODY_CLASS)) {
                document.body.classList.remove(LEFT_NAV_COLLAPSED_ENABLED_BODY_CLASS);
            }
        } else {
            document.body.classList.add(LEFT_NAV_COLLAPSED_ENABLED_BODY_CLASS);
            if (document.body.classList.contains(LEFT_NAV_EXTENDED_ENABLED_BODY_CLASS)) {
                document.body.classList.remove(LEFT_NAV_EXTENDED_ENABLED_BODY_CLASS);
            }
        }
    }, [isDrawerOpen]);
}
