/* eslint-disable max-lines-per-function */
import { gql, useApolloClient, useMutation } from '@apollo/client';
import {
    DSButton,
    DSDialog,
    DSDialogActions,
    DSDialogContent,
    DSDialogTitle,
    DSFormControl,
    DSFormControlLabel,
    DSRadio,
    DSRadioGroup,
    DSTable,
    DSTableBody,
    DSTextField,
    HelpPopper,
    Loading,
    Translate,
} from '@deltasierra/components';
import { isOldFlowCreatedAtWithinSafeRange, isScheduledTimeWithinSafeRange } from '@deltasierra/integrations/clubready';
import { noop } from '@deltasierra/utilities/object';
import { useControlledInput, useDebouncedValue, useRadioInput } from '@deltasierra/react-hooks';
import { assertNever, MAX_FACEBOOK_SCHEDULED_HOURS, MAX_INSTAGRAM_SCHEDULED_DAYS, t } from '@deltasierra/shared';
import { titleCaseFirst } from '@deltasierra/utilities/string';
import { DateTime, DurationObjectUnits } from 'luxon';
import * as React from 'react';
import { ClubReadyPostArtifactScheduledWorkflowType, SubChannel } from '../../../../../__graphqlTypes/globalTypes';
import { useAngularServiceContext } from '../../../common/componentUtils/angularServiceContexts';
import { useRateLimitedLocations } from '../../../common/hooks/useRateLimitedLocations';
import { MoreLocationsButton } from '../MoreLocationsButton/MoreLocationsButton';
import { ScheduledPostRow } from '../ScheduledPostRow';
import { GetAllPostsForLocation_location_platforms_pastPosts_edges_node_timestamps as Timestamp } from '../ScheduledPostTable/__graphqlTypes/GetAllPostsForLocation';
import { GetPostArtifactForModalsQueryPostArtifact, usePostArtifact } from './use-post-artifact';
import { useWorkflow } from './use-workflow';
import {
    ReschedulePostGroupMutationForModal,
    ReschedulePostGroupMutationForModalVariables,
} from './__graphqlTypes/ReschedulePostGroupMutationForModal';
import {
    ReschedulePostMutationForModal,
    ReschedulePostMutationForModalVariables,
} from './__graphqlTypes/ReschedulePostMutationForModal';

const RESCHEDULE_POST_MUTATION = gql`
    mutation ReschedulePostMutationForModal($input: ReschedulePostArtifactsInput!) {
        reschedulePostArtifacts(input: $input) {
            __typename
            ... on ReschedulePostArtifactsPayload {
                workflow {
                    id
                    status {
                        message
                        state
                    }
                }
            }
        }
    }
`;

const RESCHEDULE_POST_GROUP_MUTATION = gql`
    mutation ReschedulePostGroupMutationForModal($input: ReschedulePostArtifactGroupInput!) {
        reschedulePostArtifactGroup(input: $input) {
            __typename
            ... on ReschedulePostArtifactGroupPayload {
                workflow {
                    id
                    status {
                        message
                        state
                    }
                }
            }
        }
    }
`;

export type ReschedulePostModalProps = {
    isOpen: boolean;
    postId: string;
    onCancel?: () => void;
    onConfirm?: () => void;
};

export function ReschedulePostModal(props: ReschedulePostModalProps): JSX.Element {
    return (
        <DSDialog maxWidth="md" open={props.isOpen}>
            {props.isOpen && <ReschedulePostModalInnerWrapper {...props} />}
        </DSDialog>
    );
}

ReschedulePostModal.displayName = 'ReschedulePostModal';

function ReschedulePostModalInnerWrapper(props: ReschedulePostModalProps): JSX.Element {
    const post = usePostArtifact(props.postId);
    if (!post) {
        return <Loading />;
    } else {
        return <ReschedulePostModalInner {...props} post={post} />;
    }
}

type ReschedulePostModalInnerProps = {
    post: GetPostArtifactForModalsQueryPostArtifact;
    onCancel?: () => void;
    onConfirm?: () => void;
};

// eslint-disable-next-line max-statements
function ReschedulePostModalInner(props: ReschedulePostModalInnerProps): JSX.Element {
    const client = useApolloClient();

    const notifier = useAngularServiceContext('mvNotifier');

    const [reschedulePost, { loading: postLoading }] = useMutation<
        ReschedulePostMutationForModal,
        ReschedulePostMutationForModalVariables
    >(RESCHEDULE_POST_MUTATION);

    const [reschedulePostGroup, { loading: groupLoading }] = useMutation<
        ReschedulePostGroupMutationForModal,
        ReschedulePostGroupMutationForModalVariables
    >(RESCHEDULE_POST_GROUP_MUTATION);

    const [getStatus, { isRunning: jobRunning }] = useWorkflow({
        onComplete: job => {
            const postArtifactCacheId = client.cache.identify({ __typename: 'PostArtifact', id: post.id });
            const hasPostBeenDeleted = job.results.edges?.some(edge => edge.node.info === 'DELETED');
            const hasPostBeenPublishedImmediately = job.results.edges?.some(
                edge => edge.node.info === 'PUBLISHED_IMMEDIATELY',
            );

            if (hasPostBeenDeleted) {
                notifier.success(
                    t('SCHEDULE.PAGE.DIALOGS.RESCHEDULE.DELETED', { platform: titleCaseFirst(post.platform.name) }),
                );
                client.cache.evict({ id: postArtifactCacheId });
                client.cache.gc();
            } else {
                notifier.success(t('SCHEDULE.PAGE.DIALOGS.RESCHEDULE.SUCCESSFULLY_RESCHEDULED_POST'));
                client.cache.modify<{ timestamps: any }>({
                    fields: {
                        timestamps(existing: Timestamp): any {
                            return {
                                ...existing,
                                scheduled: hasPostBeenPublishedImmediately ? new Date().toISOString() : time,
                            };
                        },
                    },
                    id: postArtifactCacheId,
                });
            }
            onConfirm?.();
        },
        onError: (message?: string | null) => {
            if (message) {
                // If we have a translated custom error message returned
                notifier.unexpectedError(message);
            } else {
                notifier.unexpectedError(t('SCHEDULE.PAGE.DIALOGS.RESCHEDULE.FAILED_TO_RESCHEDULE'));
            }
        },
    });
    const { onCancel, onConfirm, post } = props;

    const [value, onChange] = useRadioInput(['one', 'all'], 'one');

    const [time, , { onChange: onDateChange }] = useControlledInput(
        noop,
        DateTime.fromISO(post.timestamps.scheduled ?? '', { zone: 'utc' })
            .toLocal()
            .startOf('second')
            .toISO({ includeOffset: false, suppressMilliseconds: true }),
    );

    const [dateHelperText, setDateHelperText] = React.useState<string>('');

    const isUsingScheduleService =
        post.meta?.__typename === 'ClubReadyPostArtifactMeta' &&
        post.meta.scheduledWorkflowType === ClubReadyPostArtifactScheduledWorkflowType.SCHEDULE_SERVICE;

    const isClubReadyPostWithinReschedulableTimeRange = React.useMemo(() => {
        if (
            post.timestamps.scheduled &&
            post.timestamps.created &&
            post.platform.name === 'clubReady'
        ) {
            if (isUsingScheduleService && post.timestamps.scheduled) {
                return isScheduledTimeWithinSafeRange(new Date(post.timestamps.scheduled));
            }
            if (!isUsingScheduleService && post.timestamps.created) {
                return isOldFlowCreatedAtWithinSafeRange(new Date(post.timestamps.created));
            }
        }
        return false;
    }, [
        isUsingScheduleService,
        post.platform.name,
        post.timestamps.created,
        post.timestamps.scheduled,
    ]);

    const shouldDisableClubReadyPostReschedule =
        post.platform.name === 'clubReady' && !isClubReadyPostWithinReschedulableTimeRange;

    const isValidDate = React.useMemo(() => {
        const timeLuxon = DateTime.fromISO(time);
        const isValid = timeLuxon.isValid;
        if (!timeLuxon.isValid) {
            setDateHelperText('Invalid Date');
            return timeLuxon.isValid;
        }
        if (post.platform.name === 'instagram') {
            const diff: DurationObjectUnits = timeLuxon.diffNow('days').toObject();
            const isValidInstagramJobDate = diff.days ? diff.days < MAX_INSTAGRAM_SCHEDULED_DAYS : false;
            setDateHelperText(
                t('BUILD.PUBLISH.INSTAGRAM.SCHEDULED_INSTAGRAM_POST_MUST_BE_NO_MORE_THAN_75_DAYS_IN_FUTURE'),
            );
            return timeLuxon.isValid && isValidInstagramJobDate;
        }
        if (post.platform.name === 'facebook') {
            const diff: DurationObjectUnits = timeLuxon.diffNow('hours').toObject();
            const isValidFacebookJobDate = diff.hours ? diff.hours <= MAX_FACEBOOK_SCHEDULED_HOURS : false;
            setDateHelperText(
                t('BUILD.PUBLISH.FACEBOOK.SCHEDULED_FACEBOOK_POST_MUST_BE_NO_MORE_THAN_28_DAYS_IN_FUTURE'),
            );
            return timeLuxon.isValid && isValidFacebookJobDate;
        }
        setDateHelperText('');
        return isValid;
    }, [time, post.platform]);

    const selectedDate = React.useMemo(
        () => (isValidDate ? DateTime.fromISO(time).toJSDate() : DateTime.now().toJSDate()),
        [time, isValidDate],
    );
    const debouncedSelectedDate = useDebouncedValue(selectedDate, 300);

    const locationIds = React.useMemo(
        () => props.post.parent?.locations.edges.map(edge => edge.node.id) ?? [props.post.location.id],
        [props.post.location.id, props.post.parent?.locations.edges],
    );

    const postArtifacts = React.useMemo(
        () => props.post.parent?.postArtifacts.edges.map(edge => edge.node) ?? [],
        [props.post.parent?.postArtifacts],
    );

    const subChannel = React.useMemo(() => {
        switch (props.post.subChannel) {
            case SubChannel.DIRECT:
                return 'direct';
            case SubChannel.MOBILE_APP:
                return 'mobileApp';
            case null:
                return null;
            default:
                throw assertNever(props.post.subChannel);
        }
    }, [props.post.subChannel]);

    const [rateLimitState, rateLimitRenderElement] = useRateLimitedLocations(
        props.post.platform.name,
        locationIds,
        subChannel,
        debouncedSelectedDate,
    );

    const handleConfirm = async () => {
        if (value === 'one') {
            const result = await reschedulePost({
                variables: {
                    input: {
                        postArtifactIds: [post.id],
                        scheduledTime: DateTime.fromISO(time).toUTC().toISO(),
                    },
                },
            });
            if (result.data?.reschedulePostArtifacts.__typename === 'ReschedulePostArtifactsPayload') {
                getStatus({ variables: { id: result.data.reschedulePostArtifacts.workflow.id } });
            } else {
                onConfirm?.();
            }
        } else if (rateLimitState.rateLimitedLocationIds.length > 0 || rateLimitState.failedLocationIds.length > 0) {
            /*
                If one or more locations hit the rate limit, reschedule the locations that haven't hit the limit
                and put them into a new published artifact group.
            */
            const postArtifactIdsAbleToBeRescheduled = postArtifacts
                .filter(
                    postArtifact =>
                        !rateLimitState.rateLimitedLocationIds.includes(postArtifact.location.id) &&
                        !rateLimitState.failedLocationIds.includes(postArtifact.location.id),
                )
                .map(postArtifact => postArtifact.id);
            const result = await reschedulePost({
                variables: {
                    input: {
                        postArtifactIds: postArtifactIdsAbleToBeRescheduled,
                        scheduledTime: DateTime.fromISO(time).toUTC().toISO(),
                    },
                },
            });
            if (result.data?.reschedulePostArtifacts.__typename === 'ReschedulePostArtifactsPayload') {
                getStatus({ variables: { id: result.data.reschedulePostArtifacts.workflow.id } });
            } else {
                onConfirm?.();
            }
        } else {
            const result = await reschedulePostGroup({
                variables: {
                    input: {
                        postArtifactGroupId: post.parent!.id,
                        scheduledTime: DateTime.fromISO(time).toUTC().toISO(),
                    },
                },
            });
            if (result.data?.reschedulePostArtifactGroup.__typename === 'ReschedulePostArtifactGroupPayload') {
                getStatus({ variables: { id: result.data.reschedulePostArtifactGroup.workflow.id } });
            } else {
                onConfirm?.();
            }
        }
    };

    return (
        <>
            <DSDialogTitle>
                <Translate keyId="BUILD.PUBLISH.RESCHEDULE.RESCHEDULE_ITEM" />
            </DSDialogTitle>
            <DSDialogContent>
                <DSTable>
                    <DSTableBody>
                        {/* Setting inactive to false so display looks good */}
                        <ScheduledPostRow
                            disableLinkToPost
                            disableReschedule
                            hideActions
                            hideLocationCount
                            post={{ ...post, timestamps: { ...post.timestamps, scheduled: time } }}
                        />
                    </DSTableBody>
                </DSTable>
                <br />
                <form noValidate style={{ display: 'flex', flexWrap: 'wrap' }}>
                    <DSTextField
                        InputLabelProps={{
                            shrink: true,
                        }}
                        defaultValue={time}
                        error={!isValidDate}
                        helperText={dateHelperText}
                        label={t('BUILD.PUBLISH.RESCHEDULE.NEW_TIME')}
                        type="datetime-local"
                        onChange={onDateChange}
                    />
                </form>
                <br />
                <DSFormControl>
                    <DSRadioGroup value={value} onChange={onChange}>
                        <DSFormControlLabel
                            control={<DSRadio />}
                            label={t('SCHEDULE.PAGE.DIALOGS.RESCHEDULE.OPTION_FOR_SINGLE', {
                                location: post.location.title,
                            })}
                            value="one"
                        />
                        <span>
                            {(post.parent?.locationCount ?? 0) > 1 && (
                                <DSFormControlLabel
                                    control={<DSRadio />}
                                    disabled={!post.parent}
                                    label={t('SCHEDULE.PAGE.DIALOGS.RESCHEDULE.OPTION_FOR_MULTIPLE', {
                                        location: post.location.title,
                                        num: (post.parent?.locationCount ?? 1) - 1,
                                    })}
                                    value="all"
                                />
                            )}
                            {post.parent && post.parent.locations.edges.length > 1 && (
                                <MoreLocationsButton postArtifactId={post.id} />
                            )}
                        </span>
                    </DSRadioGroup>
                </DSFormControl>
                {rateLimitRenderElement}
            </DSDialogContent>
            <DSDialogActions>
                <DSButton variant="outlined" onClick={onCancel}>
                    <Translate keyId="COMMON.CANCEL" />
                </DSButton>
                <DSButton
                    color="primary"
                    disabled={
                        jobRunning ||
                        postLoading ||
                        groupLoading ||
                        !isValidDate ||
                        rateLimitState.isLoading ||
                        shouldDisableClubReadyPostReschedule
                    }
                    variant="contained"
                    onClick={handleConfirm}
                >
                    {t('SCHEDULE.PAGE.DIALOGS.RESCHEDULE.CONFIRM')}
                </DSButton>
                {shouldDisableClubReadyPostReschedule && (
                    <HelpPopper margin="left" popperIcon="none">
                        {isUsingScheduleService
                                ? (
                                    <Translate keyId="SCHEDULE.PAGE.DIALOGS.RESCHEDULE.DISABLED_SCHEDULING" />
                                ) : (
                                    <Translate keyId="SCHEDULE.PAGE.DIALOGS.RESCHEDULE.DISABLED_LEGACY" />
                                )
                        }
                    </HelpPopper>
                )}
            </DSDialogActions>
        </>
    );
}

ReschedulePostModalInner.displayName = 'ReschedulePostModalInner';
