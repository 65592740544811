import { Navigation, Notification, ProfileNavigation, UserInfo } from '@deltasierra/components';
import { useLeftNavigationMenu } from './useLeftNavigationMenu';
import { useUpdateLeftNavigationBodyClasses } from './useUpdateLeftNavigationBodyClasses';
import { useLeftNavigationNotifications } from './useLeftNavigationNotifications';
import { useLeftNavigationAccountMenu } from './useLeftNavigationAccountMenu';
import { useLeftNavigationDrawerPreference } from './useLeftNavigationDrawerPreference';
import { useLeftNavigationClientLocation } from './useLeftNavigationClientLocation';

export function useLeftNavigation(): {
    isDrawerOpen: boolean,
    isLoadingDrawerPreference: boolean,
    isLoadingNavigationMenu: boolean;
    isLoadingNotifications: boolean;
    navigation: Navigation;
    notifications: Notification[];
    onClickDismissAllNotifications: () => void;
    onClickDismissNotification: (id: string) => void;
    profileNavigation: ProfileNavigation;
    userInfo: UserInfo;
    onDrawerToggle: () => Promise<void>;
} {
    const { clientId, locationId } = useLeftNavigationClientLocation();

    const { isLoading: isLoadingNavigationMenu, navigation } = useLeftNavigationMenu({ clientId, locationId });

    const { profileNavigation, userInfo } = useLeftNavigationAccountMenu();

    const {
        isLoadingNotifications,
        notifications,
        onClickDismissAllNotifications,
        onClickDismissNotification,
    } = useLeftNavigationNotifications();

    const { isDrawerOpen, loading: isLoadingDrawerPreference, onDrawerToggle } = useLeftNavigationDrawerPreference();

    useUpdateLeftNavigationBodyClasses({ isDrawerOpen });

    return {
        isDrawerOpen,
        isLoadingDrawerPreference,
        isLoadingNavigationMenu,
        isLoadingNotifications,
        navigation,
        notifications,
        onClickDismissAllNotifications,
        onClickDismissNotification,
        onDrawerToggle,
        profileNavigation,
        userInfo,
    }
}
