import { clock, midnight, t, Location, Planner } from '@deltasierra/shared';
import { lowerCaseWithDashes } from '@deltasierra/utilities/string';
import moment from 'moment-timezone';
import * as React from 'react';
import { OneWayBinding } from '../../common/angularData';
import { useAngularServiceContext } from '../../common/componentUtils/angularServiceContexts';
import { withAngularIntegration } from '../../common/componentUtils/reactComponentRegistration';
import { useDataRetrieval } from '../../common/futures';
import { Translate } from '../../directives/Translate';
import { PlannersByDate } from '../../planner/plannerDateService';
import { PlatformLogo } from '../../platforms/components/PlatformLogo';

interface UpcomingEventsProps {
    location?: Location;
}

const UpcomingEvents: React.FunctionComponent<UpcomingEventsProps> = ({ location }) => {
    const mvIdentity = useAngularServiceContext('mvIdentity');
    return <>{mvIdentity.canAccessPlanner() && <UpcomingEventsInner location={location} />}</>;
};

const UpcomingEventsInner: React.FunctionComponent<UpcomingEventsProps> = ({ location }) => {
    const [upcomingEventsResult] = useUpcomingEvents(location);
    if (upcomingEventsResult.isRunning) {
        return <div className="loading" />;
    } else if (upcomingEventsResult.isFinished && upcomingEventsResult.value.length) {
        return <UpcomingEventsWell plannersByDate={upcomingEventsResult.value} />;
    } else {
        return <></>;
    }
};

const UpcomingEventsWell: React.FunctionComponent<{ plannersByDate: PlannersByDate[] }> = ({ plannersByDate }) => (
    <div className="well">
        <h3>
            <Translate keyId="DASHBOARD.UPCOMING_EVENTS.UPCOMING" />
        </h3>
        <UpcomingEventsDays plannersByDate={plannersByDate} />
        <div className="dashboard-panel-footer">
            <a className="goto-calendar btn-text" href="/planner">
                <span className="icon-space">
                    <Translate keyId="DASHBOARD.UPCOMING_EVENTS.PLAN_CALENDAR" />
                </span>
                <span className="fal fa-angle-right fa-2x" />
            </a>
        </div>
    </div>
);

const UpcomingEventsDays: React.FunctionComponent<{ plannersByDate: PlannersByDate[] }> = ({ plannersByDate }) => {
    const plannerDateService = useAngularServiceContext('plannerDateService');

    const plannerDays = plannersByDate.map(day => (
        <li className="upcoming-events-day" key={day.date.getUTCDate()}>
            <div className="upcoming-events-day-label">{plannerDateService.getFriendlyDate(day.date)}</div>
            <UpcomingEventsPlanners planners={day.planners} />
        </li>
    ));
    return <ul className="upcoming-events-days">{plannerDays}</ul>;
};

const UpcomingEventsPlanners: React.FunctionComponent<{ planners: Planner[] }> = ({ planners }) => {
    const plannerUIService = useAngularServiceContext('PlannerUIService');
    const getPlannerClassName = (planner: Planner): string =>
        lowerCaseWithDashes(plannerUIService.getPlannerClass(planner));

    const plannerItems = planners.map((planner, index) => (
        <li className={`upcoming-events-planner ${getPlannerClassName(planner)}`} key={index}>
            <div className="upcoming-events-planner-icon-and-label">
                <PlatformLogo
                    fallbackFaClassName={plannerUIService.getPlannerLegendIconClassName(planner)}
                    name={plannerUIService.getPlannerLegendIconName(planner)}
                    size={15}
                />
                <a href={plannerUIService.getPlannerUrl(planner)}>
                    <span className="upcoming-events-planner-label" data-testid="upcoming-events-planner-label">
                        {planner.title}
                    </span>
                </a>
            </div>
        </li>
    ));
    return <ul className="upcoming-events-planners">{plannerItems}</ul>;
};

function getTodayAsUtcMidnight() {
    const today = clock.today();
    return midnight(today);
}

function useUpcomingEvents(location?: Location) {
    const locationService = useAngularServiceContext('mvLocation');
    const plannerDateService = useAngularServiceContext('plannerDateService');
    return useDataRetrieval(
        async (): Promise<PlannersByDate[]> => {
            const fromDate = getTodayAsUtcMidnight();
            const toDate = moment(fromDate).add(7, 'day').toDate();

            if (!location) {
                return [];
            }
            const planners = await locationService.getPlanners(location.clientId, location.id, fromDate, toDate);
            return plannerDateService.groupPlannersByDate(planners || []);
        },
        [location, locationService, plannerDateService],
        { description: t('DASHBOARD.FETCH_UPCOMING_EVENTS') },
    );
}

const bindings = {
    location: OneWayBinding,
};

export default withAngularIntegration(React.memo(UpcomingEvents), 'dsUpcomingEvents', bindings);
