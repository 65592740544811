import { BlobWithData, BuilderTemplateType, LocationIdHierarchy, Upload, UploadCategory } from '@deltasierra/shared';

import { dataUrlToBlob } from '@deltasierra/utilities/web-image';
import { $qSID, $scopeSID, $timeoutSID } from '../../../common/angularData';
import { MvNotifier } from '../../../common/mvNotifier';
import { UploadService } from '../../../common/uploadService';
import { GraphqlService } from '../../../graphql/GraphqlService';
import { I18nService } from '../../../i18n';
import { MvLocation } from '../../../locations/mvLocation';
import { BuilderConstants, builderConstantsSID } from '../../builderConstants';
import { ExportData } from '../../contentBuilderRenderer';
import { ImageLoaderService } from '../../imageLoaderService';
import { MultiImagePublishCallback } from '../types';
import { BaseContentBuilderMultipleLocationPublishCtrl } from './BaseContentBuilderMultipleLocationPublishCtrl';
import IPromise = angular.IPromise;
import IQService = angular.IQService;
import IScope = angular.IScope;

const UPLOAD_TYPE: UploadCategory = 'publishedArtifacts';

export class MvMultipleLocationMultiImagePublishCtrl extends BaseContentBuilderMultipleLocationPublishCtrl {
    public static SID = 'mvMultipleLocationMultiImagePublishCtrl';

    public static readonly $inject: string[] = [
        $scopeSID,
        $qSID,
        $timeoutSID,
        MvNotifier.SID,
        ImageLoaderService.SID,
        builderConstantsSID,
        I18nService.SID,
        MvLocation.SID,
        UploadService.SID,
        GraphqlService.SID,
    ];

    public publishMultiImageCallback!: () => MultiImagePublishCallback<unknown>;

    public uploadIds: string[] | null = null;

    // eslint-disable-next-line max-params
    public constructor(
        $scope: IScope,
        $q: IQService,
        $timeout: ng.ITimeoutService,
        mvNotifier: MvNotifier,
        protected readonly imageLoaderService: ImageLoaderService,
        builderConstants: BuilderConstants,
        i18n: I18nService,
        mvLocation: MvLocation,
        protected readonly uploadService: UploadService,
        graphqlService: GraphqlService,
    ) {
        super($scope, $q, $timeout, mvNotifier, imageLoaderService, builderConstants, i18n, mvLocation, graphqlService);
        this.start(this.publishCtrl.templateId);
    }

    public uploadMultiImageArtifact(): IPromise<Upload[]> {
        return this.publishCtrl.prepareImage().then((exportData: ExportData[]) => {
            const now = new Date();
            const blobWithData = exportData.map(data => {
                const blob: BlobWithData = dataUrlToBlob(data.dataUrl!);
                // Safari does not support the File constructor
                // Const file = new File([blob], this.publishCtrl.generateImageFileName(data.imageFormat), { type: blob.type });
                blob.lastModified = now.getTime();
                blob.name = this.publishCtrl.generateImageFileName(data.imageFormat);
                return blob;
            });
            const promises = this.uploadService.upload(blobWithData, UPLOAD_TYPE, [], this);
            return this.$q.all<Upload>(promises);
        });
    }

    /**
     * This method isn't used for multi-image publishing but it is an abstract method in the base class.
     *
     * @returns Upload of image artifact
     */
    public async uploadArtifact(): Promise<Upload> {
        const uploads = await this.uploadMultiImageArtifact();
        if (uploads.length === 0) {
            throw new Error('Failed to upload image artifact.');
        }
        return uploads[0];
    }

    protected async onAction(location: LocationIdHierarchy): Promise<void> {
        const newUploadIds = await this.uploadMultiImageArtifactOrUseExisting(this.uploadIds, location);
        await this.publishMultiImageCallback()({
            commonData: this.commonData,
            locationGraphqlId: location.locationGraphqlId,
            locationId: location.locationId,
            publishedArtifactGroupId: this.publishedArtifactGroupId,
            scheduledPublishGroupId: this.scheduledPublishGroupId,
            uploadIds: newUploadIds,
        });
    }

    protected async uploadMultiImageArtifactOrUseExisting(
        uploadIds: string[] | null,
        locationIdHierarchy: LocationIdHierarchy,
    ): Promise<string[]> {
        if (!uploadIds || this.hasSubstitutionContent()) {
            const location = await this.mvLocation.getAssignedLocation(locationIdHierarchy.locationId);
            await this.updateSubstitutionValuesForLocation(location, BuilderTemplateType.image);
            const uploads = await this.uploadMultiImageArtifact();
            return uploads.map(upload => upload.graphqlId);
        } else {
            return this.$q.when(uploadIds);
        }
    }
}

angular.module('app').controller(MvMultipleLocationMultiImagePublishCtrl.SID, MvMultipleLocationMultiImagePublishCtrl);
