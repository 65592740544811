import { IHttpService, IPromise } from 'angular';
import { AgencyContactDetails, CurrencyCode, CurrencyNumber, GoogleAdWordsSpendAdjustmentRequest } from '@deltasierra/shared';

import { noop } from '@deltasierra/utilities/object';

import { $httpSID } from '../common/angularData';
import { getData } from '../common/httpUtils';

export class AgencyService {
    static SID = 'AgencyService';

    private static readonly CONTACT_DETAILS_URL = '/api/agency/contactDetails';

    static readonly $inject : string[] = [
        $httpSID,
    ];

    constructor(
        private $http: IHttpService,
    ) {
    }

    getContactDetails(): IPromise<AgencyContactDetails> {
        return this.$http
            .get<AgencyContactDetails>(AgencyService.CONTACT_DETAILS_URL)
            .then(getData);
    }

    updateContactDetails(details: AgencyContactDetails): IPromise<AgencyContactDetails> {
        return this.$http
            .put<AgencyContactDetails>(AgencyService.CONTACT_DETAILS_URL, details)
            .then(getData);
    }

    requestGoogleAdWordsSpendAdjustment(
        locationId: number,
        adjustedSpendPerDay: CurrencyNumber,
        recommendedSpendPerDay: CurrencyNumber,
        currencyCode: CurrencyCode,
        additionalNotes: string | undefined,
    ) {
        const URL = '/api/agency/requestGoogleAdWordsSpendAdjustment';

        return this.$http
            .post<GoogleAdWordsSpendAdjustmentRequest>(URL, {
                locationId,
                adjustedSpendPerDay,
                recommendedSpendPerDay,
                currencyCode,
                additionalNotes,
            }).then(noop);
    }
}

angular.module('app').service(AgencyService.SID, AgencyService);
