import {
    DSFormControl,
    DSFormControlLabel,
    DSFormLabel,
    DSRadio,
    DSRadioGroup,
    Loading,
} from '@deltasierra/components';
import { filterUnique } from '@deltasierra/utilities/array';
import { MAX_INSTAGRAM_HASHTAGS_COUNT } from '@deltasierra/shared';
import { FormikProps } from 'formik';
import * as React from 'react';
import styled from 'styled-components';
import { Translate } from '../../../../directives/Translate';
import { HashtagsInputWithHashtagSetsDropdown, HashtagsLimitHelpBlock, PublishHelpBlock } from '../../components';
import { HashtagInsertType, InstagramDirectFormValues } from '../instagram-direct-form-values';

const StyledRadioGroup = styled(DSRadioGroup)`
    margin-bottom: 0;
`;

export type InstagramDirectFormHashtagsSectionInnerProps = {
    availableCharacterLength: number;
    formik: FormikProps<InstagramDirectFormValues>;
    locationId: string;
    locationIds: string[];
    requiredHashtags: string[];
    requiredHashtagsLoading: boolean;
};

export function InstagramDirectFormHashtagsSectionInner({
    availableCharacterLength,
    formik,
    locationId,
    locationIds,
    requiredHashtags,
    requiredHashtagsLoading,
}: InstagramDirectFormHashtagsSectionInnerProps): JSX.Element {
    const handleChange: React.Dispatch<React.SetStateAction<string[]>> = React.useCallback(
        newValue => {
            formik.setValues(prevState => ({
                ...prevState,
                hashtags: typeof newValue === 'function' ? newValue(prevState.hashtags) : newValue,
            }));
        },
        [formik],
    );

    // Anytime the required hashtags are updated, we append them to the start of the hashtag list
    React.useEffect(() => {
        formik.setValues(prevState => ({
            ...prevState,
            hashtags: [...requiredHashtags, ...prevState.hashtags].filter(filterUnique()),
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requiredHashtags]);

    const totalHashtagsPossibleLength = MAX_INSTAGRAM_HASHTAGS_COUNT * ' '.length;

    return (
        <>
            {requiredHashtagsLoading && <Loading inline size="small" />}
            <HashtagsInputWithHashtagSetsDropdown
                disabledHashtags={requiredHashtags}
                error={!!formik.errors.hashtags}
                gutterBottom
                helperText={
                    <>
                        <HashtagsLimitHelpBlock
                            hashtags={formik.values.hashtags}
                            maxCharacters={availableCharacterLength}
                            maxTags={MAX_INSTAGRAM_HASHTAGS_COUNT}
                        />
                        {formik.errors.hashtags && <div>{formik.errors.hashtags}</div>}
                    </>
                }
                locationId={locationId}
                maxHashtags={MAX_INSTAGRAM_HASHTAGS_COUNT}
                maxLength={availableCharacterLength - totalHashtagsPossibleLength}
                value={formik.values.hashtags}
                onChange={handleChange}
            />
            <DSFormControl>
                <DSFormLabel>
                    <Translate keyId="BUILD.PUBLISH.INSTAGRAM.INPUT_LABELS.DISPLAY_HASHTAGS_IN" />
                </DSFormLabel>
                <StyledRadioGroup
                    value={formik.values.hashtagsInsertType}
                    onChange={event =>
                        formik.setFieldValue('hashtagsInsertType', event.target.value as HashtagInsertType)
                    }
                >
                    <DSFormControlLabel
                        control={<DSRadio />}
                        label={
                            <Translate keyId="BUILD.PUBLISH.INSTAGRAM.INPUT_LABELS.DISPLAY_HASHTAGS_IN_FIRST_COMMENT" />
                        }
                        value="comment"
                    />
                    <DSFormControlLabel
                        control={<DSRadio />}
                        label={<Translate keyId="BUILD.PUBLISH.INSTAGRAM.INPUT_LABELS.DISPLAY_HASHTAGS_IN_CAPTION" />}
                        value="caption"
                    />
                </StyledRadioGroup>
                <PublishHelpBlock emphasis>
                    {formik.values.hashtagsInsertType === 'caption' && (
                        <Translate keyId="BUILD.PUBLISH.INSTAGRAM.HASHTAGS_HELP_TEXT_CAPTION" />
                    )}
                    {formik.values.hashtagsInsertType === 'comment' && (
                        <Translate keyId="BUILD.PUBLISH.INSTAGRAM.HASHTAGS_HELP_TEXT_FIRST_COMMENT" />
                    )}
                </PublishHelpBlock>
            </DSFormControl>
        </>
    );
}
InstagramDirectFormHashtagsSectionInner.displayName = 'InstagramFirstCommentHashtags';
