/// <reference path="../../../../typings/custom/pdfkit/pdfkit.d.ts" />

import { FontUnits,
    IImageOptions,
    IRenderContext,
    IRenderContextOptions,
    IRenderContextTextOptions,
    LineCap,
    RenderContextColor,
    TextBaseline,
 IEllipse, IPoint, IRectangle, ISize, convertUnits, PrintUnits, Pixels, Color, colorSchemeHelpers, convertToScheme, parseColor, RGB, DomTextMeasurer, drawLine, runInContextTransaction, BlendMode, CanvasFontWeight, letterSpacingCharacter, stripLetterSpacingCharacters, BuilderFontCustomDto, calculateLineHeightInPixels, findMatchingFontMetricsOrDefault, TextToFallbackFontLink } from '@deltasierra/shared';


import { clone } from '@deltasierra/utilities/object';


import { isNullOrUndefined } from '@deltasierra/type-utilities';


import { radiansToDegrees } from '@deltasierra/math-utilities';

// All distance amounts inputted to and outputted from an implementation of IRenderContext must be in pixels.
// Because PdfKit uses points for everything, we must convert from/to pixels/points as needed.


interface IManuallyTrackedOptions {
    characterSpacingWidth: number;
    fillColor?: Color | string;
    strokeColor?: Color | string;
    opacity?: number;
    lineDash: {
        dashLength: number;
        gapLength: number;
        offset: number;
    };
    fontFamily: string;
    fontSize: number;
    fontUnits: FontUnits;
    letterSpacing: number;
}

export class PdfRenderContext implements IRenderContext {
    private readonly baseline: TextBaseline;

    private readonly textMeasurer: DomTextMeasurer;

    private pdf: PDFKit.PDFDocument;

    private _lineWidth = 1;

    private manuallyTrackedOptions: IManuallyTrackedOptions = {
        characterSpacingWidth: 0,
        fillColor: '#000',
        fontFamily: 'sans-serif',
        fontSize: 10,
        fontUnits: 'px',
        letterSpacing: 0,
        lineDash: {
            dashLength: 0,
            gapLength: 0,
            offset: 0,
        },
        strokeColor: '#000',
    };

    private manuallyTrackedOptionHistory: IManuallyTrackedOptions[] = [];

    public constructor(
        pdf: PDFKit.PDFDocument,
        private readonly customFonts: BuilderFontCustomDto[],
        private readonly textToFallbackFontLink: TextToFallbackFontLink[],
        options: IRenderContextOptions,
    ) {
        this.pdf = pdf;
        this.textMeasurer = options.textMeasurer;

        this.baseline = 'top';
        if (options.useTextRenderingLineHeightFix) {
            this.baseline = 'alphabetic';
        } else if (options.useOldTextRenderingLineHeightFix) {
            this.baseline = 'middle';
        }

        this.pdf.lineWidth(this._lineWidth);
    }

    // Dimensions
    public getSize(): ISize {
        const sizeInPoints = {
            height: this.pdf.page.height,
            width: this.pdf.page.width,
        };

        const sizeInPixels = convertUnits(sizeInPoints, PrintUnits.points, PrintUnits.pixels);

        return sizeInPixels;
    }

    public save(): this {
        this.manuallyTrackedOptionHistory.push(clone(this.manuallyTrackedOptions));
        this.pdf.save();
        return this;
    }

    public restore(): this {
        if (!this.manuallyTrackedOptionHistory.length) {
            throw new Error('Restore cannot be called more times than save');
        }
        this.pdf.restore();
        if (this.manuallyTrackedOptionHistory.length > 0) {
            this.manuallyTrackedOptions = this.manuallyTrackedOptionHistory.pop()!;
        }
        this.applyManuallyTrackedOptions();
        return this;
    }

    public runInTransaction(render: (context: this) => void): this {
        runInContextTransaction(this, render);
        return this;
    }

    // Transformations
    public translate(offset: IPoint): this {
        const convertedOffset = convertUnits(offset, PrintUnits.pixels, PrintUnits.points);
        this.pdf.translate(convertedOffset.x, convertedOffset.y);
        return this;
    }

    public scale(factor: IPoint): this {
        // Factor = convertUnits(factor, PrintUnits.pixels, PrintUnits.points);
        this.pdf.scale(factor.x, factor.y);
        return this;
    }

    public rotate(radians: number): this {
        this.pdf.rotate(radiansToDegrees(radians));
        return this;
    }

    // eslint-disable-next-line max-params, id-length
    public transform(a: number, b: number, c: number, d: number, e: number, f: number): this {
        // Sheeeeeet... How to convert to points here?
        this.pdf.transform(a, b, c, d, e, f);
        return this;
    }

    public opacity(opacity?: number): any {
        // PDFKit.PDFDocument does have an opacity but it doesn't seem to
        // Be respected for text or shapes (see https://github.com/devongovett/pdfkit/issues/259)
        // So we track it manually and use it in the fillOpacity and strokeOpacity
        // Combined with the alpha channel of the fillColor and strokeColor if specified
        if (opacity === undefined) {
            return this.manuallyTrackedOptions.opacity;
        } else {
            this.manuallyTrackedOptions.opacity = opacity;
            this.applyManuallyTrackedOptions();
            return this;
        }
    }

    public compositeOperation(operation: BlendMode): this {
        // CompositeOperation is not supported by PdfRenderContext
        throw new Error('compositeOperation is not supported by PdfRenderContext');
    }

    // Creating Paths
    public beginPath(): this {
        // PdfKit no providey a beginPath method, seems to work without it
        return this;
    }

    public moveTo(point: IPoint): this {
        const convertedPoint = convertUnits(point, PrintUnits.pixels, PrintUnits.points);
        this.pdf.moveTo(convertedPoint.x, convertedPoint.y);
        return this;
    }

    public lineTo(point: IPoint): this {
        const convertedPoint = convertUnits(point, PrintUnits.pixels, PrintUnits.points);
        this.pdf.lineTo(convertedPoint.x, convertedPoint.y);
        return this;
    }

    public drawLine(...points: IPoint[]): this {
        drawLine(this, ...points);
        return this;
    }

    public rect(rect: IRectangle): this {
        const convertedRect = convertUnits(rect, PrintUnits.pixels, PrintUnits.points);
        this.pdf.rect(convertedRect.x, convertedRect.y, convertedRect.width, convertedRect.height);
        return this;
    }

    public ellipse(ellipse: IEllipse): this {
        const convertedEllipse = convertUnits(ellipse, PrintUnits.pixels, PrintUnits.points);
        this.pdf.ellipse(convertedEllipse.x, convertedEllipse.y, convertedEllipse.radiusX, convertedEllipse.radiusY);
        return this;
    }

    // Rendering
    public clip(): this {
        this.pdf.clip();
        return this;
    }

    public fillColor(color: RenderContextColor): this {
        this.manuallyTrackedOptions.fillColor = parseColor(color);
        this.applyManuallyTrackedOptions();
        return this;
    }

    public strokeColor(color: RenderContextColor): this {
        this.manuallyTrackedOptions.strokeColor = parseColor(color);
        this.applyManuallyTrackedOptions();
        return this;
    }

    public fill(color?: RenderContextColor): this {
        if (color !== undefined) {
            this.fillColor(color);
        }
        // Typings for PDFKit expects 1 parameter for fill but is actually optional
        (this.pdf.fill as any)();
        return this;
    }

    public stroke(color?: RenderContextColor): this {
        if (color !== undefined) {
            this.strokeColor(color);
        }
        this.pdf.stroke();
        return this;
    }

    /**
     * Note that calling fill and then stroke consecutively will not work because of a limitation in the PDF spec.
     * Use the fillAndStroke method if you want to accomplish both operations on the same path.
     *
     * @param fill - The fill color
     * @param stroke - The stroke color
     * @returns PdfRenderContext
     */
    public fillAndStroke(fill?: RenderContextColor, stroke?: RenderContextColor): this {
        if (fill !== undefined) {
            this.fillColor(fill);
        }

        if (stroke !== undefined) {
            this.strokeColor(stroke);
        }

        // Typings for PDFKit expects at least 1 parameter for fill but is actually optional
        (this.pdf.fillAndStroke as any)();
        return this;
    }

    public lineWidth(): Pixels;

    public lineWidth(width: Pixels): this;

    public lineWidth(width?: Pixels): any {
        if (width === undefined) {
            return convertUnits(this._lineWidth, PrintUnits.points, PrintUnits.pixels);
        }

        this._lineWidth = width;
        this.pdf.lineWidth(convertUnits(width, PrintUnits.pixels, PrintUnits.points));
        return this;
    }

    public lineCap(cap: LineCap): this {
        this.pdf.lineCap(cap);
        return this;
    }

    public lineDash(dashLength: Pixels, gapLength: Pixels): this {
        this.manuallyTrackedOptions.lineDash.dashLength = dashLength;
        this.manuallyTrackedOptions.lineDash.gapLength = gapLength;
        this.applyManuallyTrackedOptions();
        return this;
    }

    public lineDashOffset(offset: Pixels): this {
        this.manuallyTrackedOptions.lineDash.offset = offset;
        this.applyManuallyTrackedOptions();
        return this;
    }

    public image(source: any, point: IPoint, options: IImageOptions): this {
        this.applyManuallyTrackedOptions();

        const convertedPoint = convertUnits(point, PrintUnits.pixels, PrintUnits.points);

        const size = convertUnits(
            { height: options.height || 0, width: options.width || 0 },
            PrintUnits.pixels,
            PrintUnits.points,
        );

        this.pdf.image(source, convertedPoint.x, convertedPoint.y, size);

        return this;
    }

    // Text Rendering
    public text(options: IRenderContextTextOptions): this {
        this.applyManuallyTrackedOptions();

        const { text } = options;

        this.checkAndApplyFallbackFont(text);

        const position = convertUnits(options.position, PrintUnits.pixels, PrintUnits.points);

        this.renderMultiLineText(text, position.x, position.y, {
            baseline: this.baseline,
            characterSpacing: this.manuallyTrackedOptions.characterSpacingWidth,
            fill: true,
            lineBreak: false,
            lineGap: 0,
            stroke: !!(this.lineWidth() && options.stroke),
        });

        return this;
    }

    public textHeight(text: string, options: { fallbackFontFamily?: string }): number {
        const originalText = stripLetterSpacingCharacters(text);

        const { height } = this.textMeasurer.measureTextDimensions(
            originalText,
            options.fallbackFontFamily || this.manuallyTrackedOptions.fontFamily,
            this.manuallyTrackedOptions.fontSize,
            this.manuallyTrackedOptions.fontUnits,
        );
        return height;
    }

    public textWidth(text: string, options: { completeText?: string; isLastToken?: boolean } = {}): number {
        const originalText = stripLetterSpacingCharacters(text);

        this.checkAndApplyFallbackFont(options.completeText);

        const totalLetterSpacingWidth = convertUnits(
            this.getTotalCharacterSpacingWidthForText(originalText, options.isLastToken),
            PrintUnits.points,
            PrintUnits.pixels,
        );
        return (
            convertUnits(this.pdf.widthOfString(originalText), PrintUnits.points, PrintUnits.pixels) +
            totalLetterSpacingWidth
        );
    }

    public lineHeight(fallbackFontFamily?: string): number {
        const { fontFamily, fontSize, fontUnits } = this.manuallyTrackedOptions;
        const metrics = findMatchingFontMetricsOrDefault(fontFamily, this.customFonts, fallbackFontFamily);
        return calculateLineHeightInPixels(
            { family: fallbackFontFamily || fontFamily, size: fontSize, sizeUnit: fontUnits },
            metrics,
        );
    }

    public fontFamily(family: string): this {
        this.manuallyTrackedOptions.fontFamily = family;
        this.calculatePdfCharacterSpacingValue();
        this.pdf.font(family);
        return this;
    }

    public fontWeight(weight: CanvasFontWeight): this {
        // PDFKit doesn't allow specifying the weight... Instead you should change the font family.
        return this;
    }

    public fontSize(size: number, units: FontUnits): this {
        this.manuallyTrackedOptions.fontSize = size;
        this.manuallyTrackedOptions.fontUnits = units;
        this.calculatePdfCharacterSpacingValue();
        this.pdf.fontSize(
            convertUnits(
                this.manuallyTrackedOptions.fontSize,
                this.manuallyTrackedOptions.fontUnits as any,
                PrintUnits.points,
            ),
        );
        return this;
    }

    public letterSpacing(letterSpacing: number): this {
        this.manuallyTrackedOptions.letterSpacing = letterSpacing;
        return this;
    }

    private renderMultiLineText(text: string, left: number, top: number, options: PDFKit.Mixins.TextOptions) {
        const originalText = stripLetterSpacingCharacters(text);
        const lines = originalText.split(/\n/gi);
        for (const line of lines) {
            this.pdf.text(line, left, top, options);
        }
    }

    private extractColorAndOpacity(color: Color | string | undefined, callback: (color: any, opacity: number) => void) {
        // eslint-disable-next-line no-param-reassign
        color = typeof color === 'string' ? parseColor(color) : color;
        if (!color) {
            return callback(color, 1.0);
        }

        // For opacity, use the global opacity multiplied by the color alpha channel
        let { opacity } = this.manuallyTrackedOptions;
        opacity = (opacity === undefined ? 1 : opacity) * (color.a === undefined ? 1 : color.a);

        if (colorSchemeHelpers.cmyk.isMatch(color)) {
            const cmykArray = [color.c * 100, color.m * 100, color.y * 100, color.k * 100];
            callback(cmykArray, opacity);
        } else if (colorSchemeHelpers.rgb.isMatch(color)) {
            /* eslint-disable id-length */
            const rgbOpaque = {
                a: 1,
                b: color.b,
                g: color.g,
                r: color.r,
            };
            /* eslint-enable id-length */
            const rgbHex = colorSchemeHelpers.rgb.toString(rgbOpaque, 'hex');
            callback(rgbHex, opacity);
        } else {
            const rgb = convertToScheme(color, 'rgb') as RGB;
            callback(colorSchemeHelpers.rgb.toString(rgb, 'hex'), (rgb.a || 1.0) * opacity);
        }
        return undefined;
    }

    // State Stack
    private applyManuallyTrackedOptions() {
        const { fillColor, lineDash, strokeColor } = this.manuallyTrackedOptions;
        this.extractColorAndOpacity(fillColor, (color, opacity) => this.pdf.fillColor(color, opacity));
        this.extractColorAndOpacity(strokeColor, (color, opacity) => this.pdf.strokeColor(color, opacity));

        if (lineDash.dashLength === 0) {
            this.pdf.undash();
        } else {
            this.pdf.dash(lineDash.dashLength, {
                phase: lineDash.offset,
                space: lineDash.gapLength || lineDash.dashLength,
            });
        }
    }

    private calculatePdfCharacterSpacingValue(): void {
        if (this.manuallyTrackedOptions.letterSpacing === 0) {
            this.manuallyTrackedOptions.characterSpacingWidth = 0;
        } else {
            const { width } = this.textMeasurer.measureTextDimensions(
                letterSpacingCharacter,
                this.manuallyTrackedOptions.fontFamily,
                this.manuallyTrackedOptions.fontSize,
                this.manuallyTrackedOptions.fontUnits,
            );
            // Converted width for one single letter spacing character
            const convertedWidth = convertUnits(width, PrintUnits.pixels, PrintUnits.points);
            this.manuallyTrackedOptions.characterSpacingWidth =
                convertedWidth * this.manuallyTrackedOptions.letterSpacing;
        }
    }

    private getTotalCharacterSpacingWidthForText(text: string, isLastToken?: boolean): number {
        const numberOfCharacterSpacing =
            !isNullOrUndefined(isLastToken) && !isLastToken ? text.length : text.length - 1;
        return this.manuallyTrackedOptions.characterSpacingWidth * numberOfCharacterSpacing;
    }

    private checkAndApplyFallbackFont(text?: string): void {
        if (isNullOrUndefined(text)) {
            return;
        }
        const fontFamily = this.manuallyTrackedOptions.fontFamily;
        for (const link of this.textToFallbackFontLink) {
            const textWithoutLetterSpacing = stripLetterSpacingCharacters(text);
            if (link.text === textWithoutLetterSpacing && link.selectedFont === fontFamily) {
                const fallbackFont = link.fallbackFont;
                this.fontFamily(fallbackFont);
                return;
            }
        }
    }
}

export type IPromiseCreator = <T>(
    callback: (resolve: (value?: T) => void, reject: (err: any) => void) => void,
) => PromiseLike<T>;
