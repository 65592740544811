/// <reference path="../../../../typings/browser.d.ts" />

import { Client, AssignedLocation, Platform } from '@deltasierra/shared';

import { noop } from '@deltasierra/utilities/object';
import { MvIdentity } from '../../account/mvIdentity';
import { MvClientResource, mvClientResourceSID } from '../../clients/mvClientResource';
import { InteractionUtils } from '../../common/interactionUtils';

import { I18nService } from '../../i18n';
import { MvClient } from '../../clients/mvClient';
import { $qSID } from '../../common/angularData';

export class MvAppAuthClientCtrl {
    static SID = 'mvAppAuthClientCtrl';

    client : Client | null = null;

    location: AssignedLocation | null = null;

    availablePlatforms: Platform[] = [];

    fetchData = this.interactionUtils.createFuture<void, { location: AssignedLocation }>(this.i18n.text.common.fetchData(), context => this.$q.all([
            this.mvClientResource
                .get({ id: context.location.clientId }).$promise
                .then(client => this.client = client),
            this.clientService
                .getPlatforms(context.location.clientId)
                .then(platforms => this.availablePlatforms = platforms),
        ]).then(noop));

    static readonly $inject : string[] = [
        $qSID,
        mvClientResourceSID,
        MvClient.SID,
        MvIdentity.SID,
        InteractionUtils.SID,
        I18nService.SID,
    ];

    constructor(
        private readonly $q : angular.IQService,
        private readonly mvClientResource : MvClientResource,
        private readonly clientService: MvClient,
        public readonly identity : MvIdentity,
        private readonly interactionUtils : InteractionUtils,
        private readonly i18n: I18nService,
    ) {
    }

    isPlatformAvailable(platformName: string): boolean {
        return this.availablePlatforms.some(platform => platform.name == platformName);
    }

    updateLocation(location: AssignedLocation) {
        if (location && this.client && location.clientId == this.client.id) {
            return;
        }
        this.location = location;
        return this.fetchData.run({
            location,
        });
    }

    getLocationName() {
        return this.location ? this.location.displayName || this.location.title : this.i18n.text.common.location();
    }

    getClientName() {
        return this.location ? this.location.client : this.i18n.text.common.client();
    }
}

angular.module('app').controller(MvAppAuthClientCtrl.SID, MvAppAuthClientCtrl);
