/// <reference path="../../../typings/browser.d.ts" />
import { IPromise, IHttpService } from 'angular';
import { UpdateGuidedTourBody, GuidedTour, IntroName } from '@deltasierra/shared';

import { noop } from '@deltasierra/utilities/object';
import { getData } from '../common/httpUtils';
import { $httpSID } from '../common/angularData';
import { MvIdentity } from './mvIdentity';

export class UserService {
    static SID = 'UserService';
    // Private guidedTour: GuidedTour;

    static readonly $inject : string[] = [
        $httpSID,
        MvIdentity.SID,
    ];

    constructor(
        private $http : IHttpService,
        private mvIdentity : MvIdentity,
    ) {

    }

    updateGuidedTour(body : UpdateGuidedTourBody) : IPromise<void> {
        const userId = this.mvIdentity.currentUser!.id;
        return this.$http.put(`/api/users/${userId}/updateGuidedTour`, body)
            .then(noop);
    }

    getGuidedTour() : IPromise<GuidedTour> {
        const userId = this.mvIdentity.currentUser!.id;
        return this.$http.get<GuidedTour>(`/api/users/${userId}/getGuidedTour`)
            .then(getData);
    }

    setIntroCompleted(introName : IntroName) : IPromise<void> {
        const userId = this.mvIdentity.currentUser!.id;
        return this.$http.put(`/api/users/${userId}/completeIntro`, { introName })
            .then(noop);
    }

    resetIntros() : IPromise<void> {
        const userId = this.mvIdentity.currentUser!.id;
        return this.$http.post(`/api/users/${userId}/resetIntros`, undefined)
            .then(noop);
    }

}

angular.module('app').service(UserService.SID, UserService);
