import { useMutation, useQuery } from '@apollo/client';
import * as React from 'react';
import * as Sentry from '@sentry/browser';
import { Notification } from '@deltasierra/components';
import { t } from '@deltasierra/shared';
import { useNotifier } from '../../../common';
import { USER_UNREAD_NOTIFICATIONS } from '../queries/UserUnreadNotifications.query';
import { UserUnreadNotifications } from '../queries/__graphqlTypes/UserUnreadNotifications';
import { MARK_USER_NOTIFICATION_READ } from '../mutations/MarkUserNotificationRead.mutation';
import { MARK_USER_ALL_NOTIFICATIONS_READ } from '../mutations/MarkUserAllNotificationsRead.mutation';
import { MarkReadAllNotifications } from '../mutations/__graphqlTypes/MarkReadAllNotifications';
import { MarkUserNotificationRead, MarkUserNotificationReadVariables } from '../mutations/__graphqlTypes/MarkUserNotificationRead';
import { relayConnectionToArray } from '../../../graphql/utils';
import { mapNodesToNotifications } from '../helpers';
import { LeftNavigationTopBarNotificationsFragment } from '../fragments/__graphqlTypes/LeftNavigationTopBarNotificationsFragment';

export function useLeftNavigationNotifications(): {
    isLoadingNotifications: boolean;
    notifications: Notification[];
    onClickDismissAllNotifications: () => void;
    onClickDismissNotification: (id: string) => void;
} {
    const [notifications, setNotifications] = React.useState<Notification[]>([]);

    const notifier = useNotifier();

    const { data, loading, refetch } = useQuery<UserUnreadNotifications>(
        USER_UNREAD_NOTIFICATIONS,
    );

    const [markSingleNotificationRead] = useMutation<
        MarkUserNotificationRead,
        MarkUserNotificationReadVariables
    >(MARK_USER_NOTIFICATION_READ);
    const [markAllNotificationsRead] = useMutation<
        MarkReadAllNotifications
    >(MARK_USER_ALL_NOTIFICATIONS_READ);

    React.useEffect(() => {
        if (!data) {
            return;
        }
        const nodes: LeftNavigationTopBarNotificationsFragment[] = relayConnectionToArray(data.me.unreadNotifications);
        setNotifications(mapNodesToNotifications(nodes));
    }, [data]);

    const onClickDismissNotification = React.useCallback(
        async (notificationId: string) => {
            try {
                setNotifications(prev => [...prev].filter(({ id }) => id !== notificationId));
                await markSingleNotificationRead({ variables: { input: { notificationId } } });
            } catch (error) {
                Sentry.captureException(error);
                notifier.error(t('MENU.NOTIFICATION_COULD_NOT_BE_MARKED_AS_READ'));
                void refetch();
            }
        },
        [markSingleNotificationRead, notifier, refetch],
    );
    const onClickDismissAllNotifications = React.useCallback(async () => {
        try {
            setNotifications([]);
            await markAllNotificationsRead();
        } catch (error) {
            Sentry.captureException(error);
            notifier.error(t('MENU.NOTIFICATION_COULD_NOT_BE_MARKED_AS_READ'));
            void refetch();
        }
    }, [markAllNotificationsRead, notifier, refetch]);

    return {
        isLoadingNotifications: loading,
        notifications,
        onClickDismissAllNotifications,
        onClickDismissNotification,
    };
}
