import { gql } from '@apollo/client';

export const UPDATE_USER_LEFT_NAVIGATION_DRAWER_OPEN_PREFERENCE = gql`
    mutation UpdateUserLeftNavigationDrawerOpenPreference($input: UpdateLavPreferenceInput!) {
        updateLavPreference(input: $input) {
            ... on UpdateLavPreferenceError {
                code
                message
            }
        }
    }
`;
