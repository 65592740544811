import React from 'react';
import { InAppNotificationSeverity, InAppNotificationType } from '@deltasierra/shared';
import { assertNever } from '@deltasierra/type-utilities';
import { DateTime } from 'luxon';
import { AlertColor, Notification } from '@deltasierra/components';
import { Announcement02, Image01, Mail02, MarkerPin01, VideoRecorder } from '@untitled-ui/icons-react';
import { LeftNavigationTopBarNotificationsFragment } from '../fragments/__graphqlTypes/LeftNavigationTopBarNotificationsFragment';

function getIcon(type: InAppNotificationType): React.ReactNode | null {
    switch (type) {
        case 'ImagePublishFailed':
            return <Image01 />;
        case 'VideoPublishedLocally':
        case 'VideoPublishedExternally':
        case 'VideoPublishFailed':
            return <VideoRecorder />;
        case 'MapGenerated':
            return <MarkerPin01 />;
        case 'TestInAppNotifications':
        case 'ScheduledPost.NoNotifiableDevices':
            return null;
        case 'Agency.CustomMessage':
            return <Announcement02 />;
        case 'EmailPublishSuccess':
        case 'EmailPublishFailed':
            return <Mail02 />;
        default:
            return assertNever(type);
    }
}

function getSeverity(severity: InAppNotificationSeverity): AlertColor {
    switch (severity) {
        case 'danger':
            return 'error';
        case 'success':
        case 'info':
            return 'info';
        case 'warning':
            return 'warning';
        default:
            throw assertNever(severity);
    }
}

export function mapNodesToNotifications(
    notificationNode: ReadonlyArray<LeftNavigationTopBarNotificationsFragment>,
): Notification[] {
    return notificationNode.map(node => ({
        icon: getIcon(node.type as InAppNotificationType),
        id: node.id,
        link: node.link ?? null,
        severity: getSeverity(node.severity as InAppNotificationSeverity),
        timestamp: node.createdAt ? DateTime.fromISO(node.createdAt) : null,
        title: node.subject ?? node.message ?? '',
    }));
}
