import * as React from 'react';
import { withAngularIntegration } from '../../common/componentUtils/reactComponentRegistration';
import { OneWayBinding } from '../../common/angularData';
import { LeftNavigationContainer } from './LeftNavigationContainer';

type LeftNavigationAngularWrapperProps = {
    isLeftNavbarEnabled: boolean;
};

export function LeftNavigationAngularWrapper({ isLeftNavbarEnabled }: LeftNavigationAngularWrapperProps): JSX.Element {
    return <>{isLeftNavbarEnabled && <LeftNavigationContainer />}</>;
}

LeftNavigationAngularWrapper.displayName = 'LeftNavigationAngularWrapper';

export const LeftNavigationAngularWrapperWithAngular = withAngularIntegration(
    LeftNavigationAngularWrapper,
    'leftNavigationAngularWrapper',
    {
        isLeftNavbarEnabled: OneWayBinding,
    },
);
