/* eslint-disable max-lines-per-function */
import { contains } from '@deltasierra/utilities/array';
import { DSTypography } from '@deltasierra/components';
import { NoParamsKeys } from '@deltasierra/i18n';
import {
    addTermsAndConditionsToCaption,
    isValidUrl,
    t,
    AssignedLocation,
    CallToAction,
    CallToActionType,
    GetGoogleMyBusinessAccountNameAndLocationNameAndAddressResponse,
    GoogleMyBusinessEndpoints,
    GoogleMyBusinessPublishData,
    LocationIdHierarchy,
    PostTopicType,
    Url,
} from '@deltasierra/shared';
import moment from 'moment';
import * as React from 'react';
import { useAngularServiceContext } from '../../../common/componentUtils/angularServiceContexts';
import { DateTimeRangePicker } from '../../../common/form/dates/DateTimeRangePicker';
import { FormDirectionContext, SimpleForm } from '../../../common/form/Form';
import { RequiredFieldHelp } from '../../../common/form/helpers';
import { RadioButtons } from '../../../common/form/RadioButtons';
import { Textarea } from '../../../common/form/Textarea';
import { TextInput, UrlInput } from '../../../common/form/TextInput';
import { SchedulePublishTimePicker } from '../../../directives/SchedulePublishTimePicker';
import { Translate } from '../../../directives/Translate';
import MultipleLocationConfiguredServiceInformation from '../../../integration/auth/common/MultipleLocationConfiguredServiceInformation';
import { FormButtons } from '../common/FormButton';
import { useTermsAndConditions } from '../common/hooks';
import { TermsAndConditionsPublishMessage } from '../common/termsAndConditions/termsAndConditionsPublishMessage';
import { MvContentBuilderPublishCtrl } from '../mvContentBuilderPublishCtrl';
import { EditCallToActionButton } from './EditCallToActionButton';

export type PublishToGoogleMyBusinessFormProps = {
    location: AssignedLocation;
    locations: LocationIdHierarchy[];
    publishController: MvContentBuilderPublishCtrl;
    onCancel: () => void;
    onPublish: (data: GoogleMyBusinessPublishData) => void;
};

// eslint-disable-next-line complexity
export const PublishToGoogleMyBusinessForm: React.FunctionComponent<PublishToGoogleMyBusinessFormProps> = ({
    location,
    locations,
    onCancel,
    onPublish,
    publishController,
}) => {
    // TODO: Reduce the lines in this component by refactoring state into a reducer
    /*
     * This is a quick hack job to hide GMB standard and event post types for Club Pilates
     *
     * @see https://digitalstack.atlassian.net/browse/DS-5147
     */
    const [postType, setPostType] = React.useState(
        location.client.indexOf('Club Pilates') === 0 || location.client.indexOf('Rumble US') === 0
            ? PostTopicType.offer
            : PostTopicType.standard,
    );
    const [postBody, setPostBody] = React.useState('');
    const [postTitle, setPostTitle] = React.useState('');
    const [couponCode, setCouponCode] = React.useState('');
    const [redeemOnlineUrl, setRedeemOnlineUrl] = React.useState(Url.from(''));
    const [termsConditions, setTermsConditions] = React.useState('');
    const [eventRange, setEventRange] = React.useState<{ end: Date | null; start: Date | null }>(() => ({
        end: addMinutes(new Date(), 10),
        start: new Date(),
    }));
    const [scheduledDate, setScheduledDate] = React.useState<Date | null>(
        publishController.plannerDetails?.date ?? null,
    );
    const [callToActionButton, setCallToActionButton] = React.useState<CallToAction | null>(null);
    const [isSubmitting, setIsSubmitting] = React.useState(false);

    const postTypeOptions = usePostTypeOptions(location);
    const minScheduledDate = addMinutes(new Date(), 15);
    const isPostTypeWithEvent = contains([PostTopicType.offer, PostTopicType.event], postType);

    const [termsAndConditionsText, termsAndConditionsUrl] = useTermsAndConditions(publishController);

    const locationIds = React.useMemo(() => locations.map(x => x.locationId), [locations]);

    const onSubmit = () => {
        let postBodyWithTermsAndConditions = postBody;
        if (termsAndConditionsText || termsAndConditionsUrl) {
            postBodyWithTermsAndConditions = addTermsAndConditionsToCaption(
                postBody,
                termsAndConditionsText,
                termsAndConditionsUrl,
            );
        }

        const base: Pick<GoogleMyBusinessPublishData, 'scheduledTime' | 'summary' | 'type'> = {
            scheduledTime: scheduledDate,
            summary: postBodyWithTermsAndConditions,
            type: postType,
        };

        if (postType === PostTopicType.standard) {
            onPublish({
                ...base,
                callToAction: callToActionButton || undefined,
            });
        } else if (postType === PostTopicType.event) {
            onPublish({
                ...base,
                callToAction: callToActionButton || undefined,
                event: { end: eventRange.end!, start: eventRange.start!, title: postTitle },
            });
        } else if (postType === PostTopicType.offer) {
            onPublish({
                ...base,
                event: { end: eventRange.end!, start: eventRange.start!, title: postTitle },
                offer: { couponCode, redeemOnlineUrl, termsConditions },
            });
        }

        setIsSubmitting(true);
    };

    const onScheduledDateChange = (newDate: Date | null) => {
        if (scheduledDate) {
            setScheduledDate(newDate);
        } else {
            setScheduledDate(publishController.plannerDetails?.date ?? newDate);
        }
    };

    const isFormValid = [
        !postTitle || postTitle.length < 58,
        postType !== PostTopicType.standard || !!postBody,
        // Offers should have a valid redemption URL
        postType !== PostTopicType.offer || !redeemOnlineUrl || isValidUrl(redeemOnlineUrl),
        !couponCode || couponCode.length < 58,
        // All CTAs other than "call" should have a valid URL
        !callToActionButton ||
            (callToActionButton.actionType === CallToActionType.call && !callToActionButton.url) ||
            (callToActionButton.actionType !== CallToActionType.call && callToActionButton.url),
        !isPostTypeWithEvent || !!postTitle,
        !isPostTypeWithEvent || (!!eventRange.start && !!eventRange.end),
        !scheduledDate ||
            postType === PostTopicType.standard ||
            (eventRange.start && scheduledDate.getTime() <= eventRange.start.getTime()),
    ].every(Boolean);

    return (
        <FormDirectionContext.Provider value="vertical">
            <Title />

            <SimpleForm isValid={isFormValid} onSubmit={onSubmit}>
                <MultipleLocationConfiguredServiceInformation
                    endpoint={GoogleMyBusinessEndpoints.getConfiguredAccountNameAndLocationNameAndAddress}
                    fetchMessage={t('APPS.GOOGLE_MY_BUSINESS.ASYNC_DESCRIPTIONS.FETCHING_LOCATION_NAMES')}
                    label={t('BUILD.PUBLISH.GOOGLE_MY_BUSINESS.BUSINESS_LOCATION')}
                    locations={locationIds}
                >
                    {(response: GetGoogleMyBusinessAccountNameAndLocationNameAndAddressResponse) => (
                        <div
                            key={`${response.accountName}${response.businessLocationName}`}
                            style={{ marginBottom: '8px' }}
                        >
                            <DSTypography>{`${response.accountName} - ${response.businessLocationName}`}</DSTypography>
                            {response.address && response.address.addressLines.length > 0 && (
                                <DSTypography color="textSecondary">{`${response.address.addressLines.join('  ')}${
                                    response.address.locality ? `, ${response.address.locality}` : ''
                                }`}</DSTypography>
                            )}
                        </div>
                    )}
                </MultipleLocationConfiguredServiceInformation>

                <RadioButtons
                    label={{ keyId: 'BUILD.PUBLISH.GOOGLE_MY_BUSINESS.POST_TYPE' }}
                    name="post-type"
                    options={postTypeOptions}
                    value={postType}
                    onChange={setPostType}
                />

                <OptionalTitleInputWithPostTypeAwareLabel
                    postType={postType}
                    value={postTitle}
                    onChange={setPostTitle}
                />
                <PostBodyInputWithPostTypeAwareLabel postType={postType} value={postBody} onChange={setPostBody} />

                {postType === PostTopicType.offer && (
                    <>
                        <TextInput
                            errorMessage={
                                couponCode && couponCode.length >= 58
                                    ? t('BUILD.PUBLISH.GOOGLE_MY_BUSINESS.CHARACTER_LIMIT_REACHED', { count: 58 })
                                    : undefined
                            }
                            label={{ keyId: 'BUILD.PUBLISH.GOOGLE_MY_BUSINESS.OFFER_CODE' }}
                            value={couponCode}
                            onChange={setCouponCode}
                        />
                        <UrlInput
                            label={{ keyId: 'BUILD.PUBLISH.GOOGLE_MY_BUSINESS.OFFER_LINK' }}
                            value={redeemOnlineUrl}
                            onChange={value => setRedeemOnlineUrl(Url.from(value))}
                        />
                        <Textarea
                            initialValue={termsConditions}
                            label={t('BUILD.PUBLISH.GOOGLE_MY_BUSINESS.TERMS_AND_CONDITIONS')}
                            rows={4}
                            onChange={setTermsConditions}
                        />
                    </>
                )}

                {(postType === PostTopicType.offer || postType === PostTopicType.event) && (
                    <DateTimeRangePicker
                        min={scheduledDate || new Date()}
                        value={eventRange}
                        onChange={setEventRange}
                    />
                )}

                {postType !== PostTopicType.offer && (
                    <EditCallToActionButton value={callToActionButton} onChange={setCallToActionButton} />
                )}

                <TermsAndConditionsPublishMessage text={termsAndConditionsText} url={termsAndConditionsUrl} />

                <SchedulePublishTimePicker
                    minDate={minScheduledDate}
                    timezone={location.timezone}
                    value={scheduledDate}
                    onChange={onScheduledDateChange}
                />

                {isPostTypeWithEvent && scheduledDate && eventRange.start && (
                    <OptionalScheduledTimeIsBeforeEventStartWarning
                        eventStart={eventRange.start}
                        postType={postType}
                        scheduledDate={scheduledDate}
                    />
                )}

                <FormButtons
                    disabled={!isFormValid || isSubmitting}
                    isScheduled={!!scheduledDate}
                    onCancel={onCancel}
                />
                <RequiredFieldHelp />
            </SimpleForm>
        </FormDirectionContext.Provider>
    );
};

PublishToGoogleMyBusinessForm.displayName = 'PublishToGoogleMyBusinessForm';

type OptionalTitleInputProps = {
    onChange: (value: string) => void;
    postType: PostTopicType;
    value: string;
};

const OptionalTitleInputWithPostTypeAwareLabel: React.FunctionComponent<OptionalTitleInputProps> = ({
    onChange,
    postType,
    value,
}) => {
    const labels: { [K in PostTopicType]: NoParamsKeys } = {
        [PostTopicType.event]: 'BUILD.PUBLISH.GOOGLE_MY_BUSINESS.EVENT_TITLE',
        [PostTopicType.offer]: 'BUILD.PUBLISH.GOOGLE_MY_BUSINESS.OFFER_TITLE',
        [PostTopicType.standard]: 'COMMON.NONE',
        [PostTopicType.unspecified]: 'COMMON.NONE',
    };

    return (
        <>
            {[PostTopicType.event, PostTopicType.offer].indexOf(postType) >= 0 && (
                <Textarea
                    enableEmojis
                    initialValue={value}
                    invalidHelpText={t('BUILD.PUBLISH.GOOGLE_MY_BUSINESS.CHARACTER_LIMIT_REACHED', { count: 58 })}
                    isValid={!value || value.length < 58}
                    label={t(labels[postType])}
                    required
                    rows={3}
                    onChange={onChange}
                />
            )}
        </>
    );
};

OptionalTitleInputWithPostTypeAwareLabel.displayName = 'OptionalTitleInputWithPostTypeAwareLabel';

const PostBodyInputWithPostTypeAwareLabel: React.FunctionComponent<OptionalTitleInputProps> = ({
    onChange,
    postType,
    value,
}) => {
    const labels: { [K in PostTopicType]: NoParamsKeys } = {
        [PostTopicType.event]: 'BUILD.PUBLISH.GOOGLE_MY_BUSINESS.EVENT_DESCRIPTION',
        [PostTopicType.offer]: 'BUILD.PUBLISH.GOOGLE_MY_BUSINESS.OFFER_DESCRIPTION',
        [PostTopicType.standard]: 'BUILD.PUBLISH.GOOGLE_MY_BUSINESS.POST_BODY',
        [PostTopicType.unspecified]: 'BUILD.PUBLISH.GOOGLE_MY_BUSINESS.POST_BODY',
    };
    const isRequired = postType === PostTopicType.standard;

    return (
        <Textarea
            enableEmojis
            initialValue={value}
            label={t(labels[postType])}
            required={isRequired}
            onChange={onChange}
        />
    );
};

PostBodyInputWithPostTypeAwareLabel.displayName = 'PostBodyInputWithPostTypeAwareLabel';

const Title: React.FunctionComponent = () => (
    <div>
        <h3>
            <Translate keyId="BUILD.PUBLISH.GOOGLE_MY_BUSINESS.PUBLISH_TO_GOOGLE_MY_BUSINESS" />
        </h3>
    </div>
);

Title.displayName = 'Title';

type OptionalScheduledTimeIsBeforeEventStartWarningProps = {
    postType: PostTopicType;
    scheduledDate: Date;
    eventStart: Date;
};

const OptionalScheduledTimeIsBeforeEventStartWarning: React.FunctionComponent<
    OptionalScheduledTimeIsBeforeEventStartWarningProps
> = ({ eventStart, postType, scheduledDate }) => (
    <>
        {scheduledDate.getTime() > eventStart.getTime() && (
            <div className="alert alert-danger">
                <i aria-hidden="true" className="fa fa-exclamation-triangle icon-space" />
                {postType === PostTopicType.offer && (
                    <Translate keyId="BUILD.PUBLISH.GOOGLE_MY_BUSINESS.CANNOT_START_OFFER_BEFORE_SCHEDULED_TIME" />
                )}
                {postType === PostTopicType.event && (
                    <Translate keyId="BUILD.PUBLISH.GOOGLE_MY_BUSINESS.CANNOT_START_EVENT_BEFORE_SCHEDULED_TIME" />
                )}
            </div>
        )}
    </>
);

OptionalScheduledTimeIsBeforeEventStartWarning.displayName = 'OptionalScheduledTimeIsBeforeEventStartWarning';

function usePostTypeOptions(location: AssignedLocation): Array<{ label: string; value: PostTopicType }> {
    const i18n = useAngularServiceContext('I18nService');
    return React.useMemo(() => {
        /*
         * This is a quick hack job to hide GMB standard and event post types for Club Pilates
         *
         * @see https://digitalstack.atlassian.net/browse/DS-5147
         */
        if (location.client.indexOf('Rumble US') === 0) {
            return [{ label: i18n.text.build.publish.googleMyBusiness.postTypes.offer(), value: PostTopicType.offer }];
        } else {
            return [
                { label: i18n.text.build.publish.googleMyBusiness.postTypes.standard(), value: PostTopicType.standard },
                { label: i18n.text.build.publish.googleMyBusiness.postTypes.event(), value: PostTopicType.event },
                { label: i18n.text.build.publish.googleMyBusiness.postTypes.offer(), value: PostTopicType.offer },
            ];
        }
    }, [location, i18n.text.build.publish.googleMyBusiness.postTypes]);
}

function addMinutes(date: Date, minutes: number): Date {
    return moment(date).add(minutes, 'minutes').toDate();
}
