import { LocationPickerDto, AgencyNotificationWithReporting, User, Url } from '@deltasierra/shared';

import moment from 'moment-timezone';


import { noop } from '@deltasierra/utilities/object';
import { isNotNullOrUndefined } from '@deltasierra/type-utilities';
import { InteractionUtils } from '../../common/interactionUtils';
import { I18nService } from '../../i18n/i18nService';
import { $rootScopeSID } from '../../common/angularData';
import { agencyTabNavEntries } from '../tabNavEntries';
import { MvIdentity } from '../../account/mvIdentity';
import { Paging } from '../../common/paging';
import { ConfirmModal, confirmModalSID } from '../../common/confirmModal';
import { LocationEntry, REFRESH_LOCATIONS_EVENT_NAME } from '../../directives/locationPicker/multipleLocationPicker';
import { DataUtils } from '../../common/dataUtils';
import { AgencyNotificationsService } from './agencyNotificationsService';

interface NewNotificationForm {
    subject: string | null;
    message: string | null;
    link: string | null;
    locations: LocationPickerDto[];
}

interface AgencyNotificationReportEntry extends AgencyNotificationWithReporting {
    showFullMessage?: boolean;
}

export class AgencyNotificationsCtrl {
    public static readonly SID = 'AgencyNotificationsCtrl';

    public readonly tabNavEntries = agencyTabNavEntries;

    public readonly createNewNotification = this.interactionUtils.createFuture('Create new Notification', () =>
        this.agencyNotificationsService
            .createNewNotification(this.newNotification.locations[0].agencyId, {
                link: this.newNotification.link !== '' ? Url.from(this.newNotification.link!) : null,
                locations: this.newNotification.locations.map(x => x.locationId),
                message: this.newNotification.message!,
                subject: this.newNotification.subject!,
            })
            .then(agencyNotification => {
                this.agencyNotifications.unshift({
                    agencyNotification,
                    totalClicked: 0,
                    totalSent: 0,
                });
                this.resetNewNotification();
            }),
    );

    public readonly getAgencyNotifications = this.interactionUtils.createFuture('Get Agency Notifications', () =>
        this.agencyNotificationsService.getAgencyNotifications().then(x => {
            this.agencyNotifications = x.sort(this.sortAgencyNotifications.bind(this));
            return this.agencyNotifications;
        }),
    );

    public agencyNotifications: AgencyNotificationReportEntry[] = [];

    public visibleNotifications: AgencyNotificationReportEntry[] = [];

    public paging: Paging<AgencyNotificationReportEntry> = new Paging<AgencyNotificationReportEntry>({
        initialShowPerPage: 5,
        perPageOptions: [5, 10, 25, 50],
    });

    public newNotification: NewNotificationForm = {
        link: null,
        locations: [],
        message: null,
        subject: null,
    };

    // eslint-disable-next-line @typescript-eslint/member-ordering
    public static readonly $inject: string[] = [
        $rootScopeSID,
        AgencyNotificationsService.SID,
        InteractionUtils.SID,
        I18nService.SID,
        MvIdentity.SID,
        confirmModalSID,
        DataUtils.SID,
    ];

    // eslint-disable-next-line max-params
    public constructor(
        private readonly $rootScope: ng.IRootScopeService,
        private readonly agencyNotificationsService: AgencyNotificationsService,
        private readonly interactionUtils: InteractionUtils,
        private readonly i18n: I18nService,
        private readonly identity: MvIdentity,
        private readonly confirmModal: ConfirmModal,
        private readonly dataUtils: DataUtils,
    ) {
        void this.getAgencyNotifications.run({});
    }

    public getTimezone(): string {
        return this.identity.currentUser!.timezone;
    }

    public updateSelectedLocations(locations: LocationPickerDto[]): void {
        this.newNotification.locations = locations;
    }

    public getFullName(user: User): string {
        return `${user.firstName} ${user.lastName}`;
    }

    public getFormattedSentAt(sentAt: Date): string {
        return sentAt ? moment(sentAt).tz(this.getTimezone()).format('D MMM YYYY @ h:mma') : '-';
    }

    public getPreviewDate(): string {
        return this.getFormattedSentAt(new Date()); // It's inefficient to create a new date on each AngularJS digest loop, but never mind.
    }

    public isDisabled(): boolean {
        return this.createNewNotification.isRunning();
    }

    public hasSent(notification: AgencyNotificationReportEntry): boolean {
        return isNotNullOrUndefined(notification.agencyNotification.sentAt);
    }

    public hasLink(notification: AgencyNotificationReportEntry): boolean {
        return isNotNullOrUndefined(notification.agencyNotification.link);
    }

    public createNotification(form: ng.IFormController): void {
        if (this.newNotification.locations.length === 0) {
            return;
        }

        const onConfirm = () => this.createNewNotification.run({}).then(() => form.$setUntouched());

        this.confirmModal.open(
            this.i18n.text.agency.notifications.sendConfirm.title(),
            this.i18n.text.agency.notifications.sendConfirm.message({ count: this.newNotification.locations.length }),
            onConfirm,
            noop,
        );
    }

    public getFilteredNotifications(): AgencyNotificationReportEntry[] {
        const offset = this.paging.getOffset();
        return this.agencyNotifications.slice(offset, offset + this.paging.showPerPage);
    }

    public removeLocation(location: LocationPickerDto): void {
        (location as LocationEntry).selected = false;
        this.dataUtils.removeObject(location, this.newNotification.locations);
    }

    public toggleNotificationMessage(notification: AgencyNotificationReportEntry): void {
        notification.showFullMessage = !notification.showFullMessage;
    }

    private resetNewNotification() {
        this.newNotification.subject = null;
        this.newNotification.message = null;
        this.newNotification.link = null;
        this.newNotification.locations.forEach(x => {
            (x as LocationEntry).selected = false;
        });
        this.$rootScope.$broadcast(REFRESH_LOCATIONS_EVENT_NAME);
    }

    private sortAgencyNotifications(arg0: AgencyNotificationReportEntry, arg1: AgencyNotificationReportEntry) {
        return moment(arg1.agencyNotification.createdAt).diff(arg0.agencyNotification.createdAt);
    }
}

angular.module('app').controller(AgencyNotificationsCtrl.SID, AgencyNotificationsCtrl);
