/// <reference path="../../../../typings/browser.d.ts" />
import {
    AgencyId,
    AssignedLocation,
    ClientId,
    LocationCategoryGroupView,
    LocationId,
    LocationPickerDto,
} from '@deltasierra/shared';
import { MvAuth } from '../../account/mvAuth';
import { MvIdentity } from '../../account/mvIdentity';
import {
    $kookiesSID,
    $rootScopeSID,
    actualComponent,
    ExpressionBinding,
    IKookies,
    ILifecycleHooks,
    OptionalOneWayBinding,
    OptionalTwoWayBinding,
} from '../../common/angularData';
import { DataUtils } from '../../common/dataUtils';
import { InteractionUtils } from '../../common/interactionUtils';
import { MvNotifier } from '../../common/mvNotifier';
import { GraphqlService } from '../../graphql/GraphqlService';
import { MvLocation } from '../../locations/mvLocation';
import {
    MvLocationCategoryOptionsResource,
    mvLocationCategoryOptionsResourceSID,
} from '../../locations/mvLocationCategoryOptionsResource';
import { BaseLocationPicker, LocationPickerView } from './locationPickerCommon';
import IRootScopeService = angular.IRootScopeService;
import IAngularEvent = angular.IAngularEvent;

const COOKIE_NAME = 'plannerLocation';
export const SELECTED_LOCATION_CHANGED_EVENT_NAME = 'selectedLocationChanged';

interface ChangeLocals {
    location: AssignedLocation;
}

export class LocationPickerCtrl extends BaseLocationPicker implements ILifecycleHooks {
    public static readonly $inject = [
        DataUtils.SID,
        $rootScopeSID,
        $kookiesSID,
        MvNotifier.SID,
        InteractionUtils.SID,
        MvAuth.SID,
        MvIdentity.SID,
        MvLocation.SID,
        mvLocationCategoryOptionsResourceSID,
        GraphqlService.SID,
    ];

    // Props
    public readonly disabled?: boolean;

    public readonly selectedLocationId?: string;

    /**
     * @deprecated This is the old location id, use `selectedLocationId` for new graphql ids
     */
    public readonly preselectedLocationId?: LocationId;

    public readonly change!: (locals: ChangeLocals) => void;

    public readonly restrictToAgencyId?: AgencyId;

    public readonly restrictToSameClient?: boolean;

    // State
    public currentLocation?: LocationPickerDto;

    public categories?: LocationCategoryGroupView[];


    public readonly fetchCategories = this.interactionUtils.createFuture('fetch categories', () =>
        this.mvLocationCategoryOptionsResource
            .queryAssigned()
            .$promise.then(categories => {
                this.categories = categories;
            })
            .catch(() => {
                this.resetView();
            }),
    );



    public constructor(
        protected readonly dataUtils: DataUtils,
        protected readonly $rootScope: IRootScopeService,
        protected readonly $kookies: IKookies,
        protected readonly mvNotifier: MvNotifier,
        protected readonly interactionUtils: InteractionUtils,
        protected readonly mvAuth: MvAuth,
        protected readonly mvIdentity: MvIdentity,
        protected readonly mvLocation: MvLocation,
        protected readonly mvLocationCategoryOptionsResource: MvLocationCategoryOptionsResource,
        private readonly graphqlService: GraphqlService,
    ) {
        super(dataUtils);
    }

    public $onInit(): void {
        void this.mvLocation
            .getSimpleAssignedLocations()
            .then(async (data: LocationPickerDto[]) => {
                if (this.restrictToAgencyId) {

                    data = this.filterByAgencyId(data, this.restrictToAgencyId);
                }
                let location = null;

                // This should be how you assign the location, using the graphql id
                if (this.selectedLocationId) {
                    location = this.dataUtils.findBy('locationGraphqlId', data, this.selectedLocationId);
                }

                const locationId =
                    this.preselectedLocationId || this.mvIdentity.getDefaultLocationId() || this.getFromCookie();
                if (!location && locationId) {
                    location = this.dataUtils.findBy('locationId', data, locationId);
                }
                // If we don't have a location we select the first in the list
                if (!location) {
                    location = data[0];
                }
                if (this.restrictToSameClient && location) {

                    data = this.filterByClientId(data, location.clientId);
                }
                this.locations = data;
                this.initFiltering();

                if (location !== null && location !== undefined) {
                    return this.setLocation(location);
                } else {
                    // eslint-disable-next-line promise/no-return-wrap
                    return Promise.resolve();
                }
            })
            .catch(data => {
                this.mvNotifier.unexpectedErrorWithData('Failed to retrieve assigned locations', data);
            });
    }

    public async setLocation(simpleLocation: LocationPickerDto): Promise<void> {
        this.currentLocation = simpleLocation;
        this.setInCookie(simpleLocation.locationId);
        const fullLocation = await this.mvLocation.getAssignedLocation(simpleLocation.locationId);
        this.graphqlService.setCurrentLocationId(fullLocation.graphqlId);
        this.change({ location: fullLocation });
        this.$rootScope.$broadcast(SELECTED_LOCATION_CHANGED_EVENT_NAME, { location: fullLocation });
        this.filteredEntries = this.groupedLocations;
        await this.updateDefaultLocationIdForCurrentUser(fullLocation.id);
    }

    public browse(event: IAngularEvent): PromiseLike<void> {
        event.preventDefault();
        if (event.stopPropagation) {
            event.stopPropagation();
        }
        this.view = LocationPickerView.Categories;
        if (!this.categoryGroups) {
            return this.fetchCategories.run({});
        }
        return Promise.resolve();
    }

    private getFromCookie(): LocationId | undefined {
        const locationId = this.$kookies.get(COOKIE_NAME, Number);
        if (locationId) {
            return LocationId.from(locationId);
        } else {
            return undefined;
        }
    }

    private setInCookie(locationId: LocationId): void {
        /*
            Set location cookies across two domains to handle issue with two cookies existing
            with the same key but different value.
            Related ticket: https://digitalstack.atlassian.net/browse/DSL-3125
        */
        this.$kookies.set(COOKIE_NAME, locationId, { expires: 0, path: '/', secure: true });
        this.$kookies.set(COOKIE_NAME, locationId, { domain: `.${window.location.hostname}`, path: '/', secure: true });
    }

    private filterByClientId(locations: LocationPickerDto[], clientId: ClientId): LocationPickerDto[] {
        return this.dataUtils.filterBy('clientId', locations, clientId);
    }

    private async updateDefaultLocationIdForCurrentUser(locationId: LocationId): Promise<void> {
        const currentDefaultLocationId = this.mvIdentity.getDefaultLocationId();
        if (currentDefaultLocationId !== locationId) {
            await this.mvAuth.updateCurrentUser({ defaultLocationId: locationId });
        }
    }
}

export const locationPickerSID = 'locationPicker';
export const locationPickerConfig = actualComponent(
    LocationPickerCtrl,
    '/partials/directives/locationPicker/locationPicker',
    {
        change: ExpressionBinding,
        disabled: OptionalTwoWayBinding,
        preselectedLocationId: OptionalTwoWayBinding,
        restrictToAgencyId: OptionalOneWayBinding,
        restrictToSameClient: OptionalOneWayBinding,
        selectedLocationId: OptionalOneWayBinding,
    },
);

angular.module('app').component(locationPickerSID, locationPickerConfig);
