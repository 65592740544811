import { BlobWithData } from '@deltasierra/shared';
import { dataUrlToBlob } from '@deltasierra/utilities/web-image';
import { $scopeSID } from '../../common/angularData';
import { $modalInstanceSID } from '../../common/angularUIBootstrapData';
import { FileCache } from '../../common/fileCache';
import { ImageCropperService } from '../../common/imageCropper/service';
import { ImageLoaderService } from '../../contentBuilder/imageLoaderService';
import { LoadedVideo, VideoCache, VideoLoaderService } from '../../contentBuilder/videoLoaderService';
import { ModalInstance } from '../../typings/angularUIBootstrap/modalService';

import IScope = angular.IScope;

type Views = 'custom' | 'options' | 'video';

export class ThumbnailSelectorController {
    public static readonly $inject: string[] = [
        'videoFile',
        $scopeSID,
        $modalInstanceSID,
        VideoLoaderService.SID,
        ImageCropperService.SID,
        ImageLoaderService.SID,
    ];

    public totalFrames = 1;

    public selectedFrame = 0;

    public view: Views = 'options';

    public isVideoProvided = false;

    public fileCache = new FileCache();

    public videoCache = new VideoCache();

    private video?: LoadedVideo;

    public constructor(
        private readonly videoFile: File,
        private readonly $scope: IScope,
        private readonly $modalInstance: ModalInstance,
        private readonly videoLoaderService: VideoLoaderService,
        private readonly imageCropperService: ImageCropperService,
    ) {
        if (this.videoFile) {
            this.isVideoProvided = true;
            void this.videoLoaderService.loadVideoFromFile(this.videoCache, this.fileCache, videoFile).then(video => {
                this.video = video;
                this.video.element.setAttribute('width', '100%');
                this.totalFrames = Math.floor(this.video.element.duration);
            });
        }

        this.$scope.$on('$destroy', () => {
            this.videoCache.clear();
            this.fileCache.clear();
        });
    }

    public updateVideoTime(): void {
        if (this.video) {
            this.video.element.currentTime = this.selectedFrame;
        }
    }

    public setView(view: Views): void {
        this.view = view;
    }

    public chooseFrame(): void {
        if (this.video) {
            const frame = this.videoLoaderService.extractFrame(this.video.element);
            const thumbnail: BlobWithData = dataUrlToBlob(frame.toDataURL('image/png'));
            const now = new Date();
            thumbnail.lastModified = now.getTime();
            thumbnail.name = 'thumbnail.png';

            this.$modalInstance.close({
                thumbnail,
            });
        }
    }

    public async selectCustomImage(files: File[]): Promise<void> {
        // This width and height is only used to determine aspect ratio
        const thumbnail = await this.imageCropperService.showCropperModal(files[0], {
            height: 9,
            width: 16,
        });

        this.$modalInstance.close({
            thumbnail,
        });
    }

    public close(): void {
        this.$modalInstance.dismiss();
    }
}
