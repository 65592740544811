import { AssignedLocation } from '@deltasierra/shared';
import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { useAppFrontendRouter } from '../../../../common/hooks/useAppFrontendRouter';
import {
    GetDashboardV2FeatureFlagForClient,
    GetDashboardV2FeatureFlagForClientVariables,
} from './__graphqlTypes/GetDashboardV2FeatureFlagForClient';

export const GET_DASHBOARD_V2_FEATURE_FLAG_FOR_CLIENT = gql`
    query GetDashboardV2FeatureFlagForClient($locationId: ID!) {
        location(id: $locationId) {
            id
            features {
                dashboardV2
            }
        }
    }
`;

export function useRedirectToNewDashboardCheck(location?: AssignedLocation | null): { loading: boolean } {
    const frontendRouter = useAppFrontendRouter();

    const { data, loading } = useQuery<GetDashboardV2FeatureFlagForClient, GetDashboardV2FeatureFlagForClientVariables>(
        GET_DASHBOARD_V2_FEATURE_FLAG_FOR_CLIENT,
        !location
            ? { skip: true }
            : {
                  fetchPolicy: 'cache-and-network',
                  nextFetchPolicy: 'cache-first',
                  notifyOnNetworkStatusChange: true,
                  variables: { locationId: location.graphqlId },
              },
    );

    const redirectUrl = React.useMemo(() => {
        const flag = !!data?.location?.features.dashboardV2;
        if (flag && frontendRouter && location?.graphqlId) {
            return frontendRouter.get(`/dashboard/?l=${location.graphqlId}`);
        }
        return null;
    }, [frontendRouter, data, location]);

    React.useEffect(() => {
        if (redirectUrl) {
            window.location.replace(redirectUrl);
        }
    }, [redirectUrl]);

    return { loading };
}
