import * as React from 'react';
import { AppBar, Box, Divider, IconButton, Toolbar } from '@material-ui/core';
import { HelpCircle, Menu01 } from '@untitled-ui/icons-react';
import styled from 'styled-components';
import { t } from '@deltasierra/shared';
import { Image, ProfileNavigation, UserInfo, LEFT_NAV_BACKGROUND_COLOR } from '@deltasierra/components';
import {
    LeftNavigationTopBarNotifications,
    LeftNavigationTopBarNotificationsProps,
} from './LeftNavigationTopBarNotifications';
import { LeftNavigationTopBarProfile } from './LeftNavigationTopBarProfile';

const StyledIconButton = styled(IconButton)`
    padding: 8px;
`;

const StyledAnchor = styled.a`
    color: ${({ theme }) => theme.palette.primary.main};
    height: 24px;
    width: 24px;
`;

export type LeftNavigationTopBarProps = Pick<LeftNavigationTopBarNotificationsProps, 'notifications'> & {
    contextPicker?: React.ReactNode;
    helpUrl: string;
    imageLogoAlt?: string;
    isOpenDrawer: boolean;
    logoImageSrc: string;
    onClickDismissAllNotifications: () => void;
    onClickDismissNotification: (id: string) => void;
    onClickHamburger: () => void;
    profileNavigation: ProfileNavigation;
    userInfo: UserInfo;
};

// eslint-disable-next-line max-lines-per-function
export const LeftNavigationTopBar = ({
    contextPicker,
    helpUrl,
    imageLogoAlt = 'Digital Stack',
    isOpenDrawer,
    logoImageSrc,
    notifications,
    onClickDismissAllNotifications,
    onClickDismissNotification,
    onClickHamburger,
    profileNavigation,
    userInfo,
}: LeftNavigationTopBarProps): JSX.Element => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const open = !!anchorEl;

    const onClickOpen = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const onClickClose = React.useCallback(() => {
        setAnchorEl(null);
    }, []);

    return (
        <AppBar
            aria-label={t('MENU.MAIN_NAVIGATION')}
            component="nav"
            position="fixed"
            style={{
                backgroundColor: LEFT_NAV_BACKGROUND_COLOR,
                boxShadow: 'none',
                color: 'black',
                display: 'flex',
                justifyContent: 'center',
                left: 0,
                paddingTop: 'env(safe-area-inset-top)',
                position: 'fixed',
                right: 0,
                top: 0,
                whiteSpace: 'nowrap',
                zIndex: 1001,
            }}
        >
            <Toolbar style={{ minHeight: '60px' }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        width: '100%',
                    }}
                >
                    <Box
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            flex: 1,
                            flexDirection: 'row',
                            gridColumnGap: '16px',
                        }}
                    >
                        <StyledIconButton
                            aria-label={isOpenDrawer ? t('MENU.CLOSE_NAVIGATION') : t('MENU.OPEN_NAVIGATION')}
                            edge="start"
                            style={{
                                color: 'common.black',
                            }}
                            onClick={onClickHamburger}
                        >
                            <Menu01 style={{ height: '24px', width: '24px' }} />
                        </StyledIconButton>
                        <a href="/">
                            <Image alt={imageLogoAlt} height="24px" src={logoImageSrc} width="144px" />
                        </a>
                    </Box>
                    {contextPicker && (
                        <>
                            <Divider flexItem orientation="vertical" />
                            <Box
                                sx={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    justifyContent: 'stretch',
                                    minWidth: '224px',
                                }}
                            >
                                {contextPicker}
                            </Box>
                            <Divider flexItem orientation="vertical" />
                        </>
                    )}
                    <Box
                        style={{
                            alignItems: 'center',
                            columnGap: '4px',
                            display: 'flex',
                            flexDirection: 'row',
                            paddingLeft: '10px',
                        }}
                    >
                        <LeftNavigationTopBarNotifications
                            anchorEl={anchorEl}
                            notificationUrl="/notifications"
                            notifications={notifications}
                            open={open}
                            onClickClose={onClickClose}
                            onClickDismissAllNotifications={onClickDismissAllNotifications}
                            onClickDismissNotification={onClickDismissNotification}
                            onClickOpen={onClickOpen}
                        />
                        <StyledIconButton aria-label={t('MENU.HELP')}>
                            <StyledAnchor href={helpUrl}>
                                <HelpCircle />
                            </StyledAnchor>
                        </StyledIconButton>
                        <LeftNavigationTopBarProfile profileNavigation={profileNavigation} userInfo={userInfo} />
                    </Box>
                </Box>
            </Toolbar>
        </AppBar>
    );
};

LeftNavigationTopBar.displayName = 'LeftNavigationTopBar';
