import { chunkArray } from '@deltasierra/utilities/array';
import {
    assertDefined,
    findBy,
    BuilderFontConfig,
    BuilderFontCustomDto,
    ClientId,
    generateFontCssSrc,
    justQuery,
    protocolRelativeUrlToHttpsUrl,
    SignedUrl,
    Url,
    UrlSigningApi,
} from '@deltasierra/shared';
import { isRecordType } from '@deltasierra/type-utilities';
import { IHttpService, ITimeoutService } from 'angular';
import { $httpSID, $timeoutSID } from '../common/angularData';
import { invokeApiRoute } from '../common/httpUtils';
import { MvNotifier, mvNotifierSID } from '../common/mvNotifier';
import { SentryService } from '../common/sentryService';
import { BuilderFontApiClient } from './builderFontClientApi';

const FONTFACE_TIMEOUT = 80_000;

/**
 * A basic type declaration of the FontFace API (because TS doesn't have typing for it). This only implements the
 * functionality that we use. Refer to the MDN docs for full details on the API and what it can do.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/FontFace
 */
declare class FontFace {
    public constructor(
        family: string,
        source: string,
        descriptors?: {
            ascentOverride?: string;
            descentOverride?: string;
            featureSettings?: string;
            lineGapOverride?: string;
            stretch?: string;
            style?: string;
            unicodeRange?: string;
            variant?: string;
            variationSettings?: string;
            weight?: string;
        },
    );

    public load(): Promise<FontFace>;
}

/**
 * A basic type declaration of the CSS Font loading API (because TS doesn't have typing for it). This only implements
 * the subset of functionality that we use. Refer to the MDN docs for full API details.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/FontFaceSet
 */
declare interface FontFaceSet {
    add(font: FontFace): void;
    clear(): void;
}

/**
 * Check if the document/browser supports the FontFaceSet and Font loading API. Why is this defined as a type-guard you
 * ask? It's because it makes it a lot easier to use in code and allows you to specify a fallback method.
 *
 * @example
 * if (hasFontFaceSet(document)) {
 *     document.fonts.add(await new FontFace().load());
 * } else {
 *     // Fallback logic
 * }
 * @param document - The document to check
 * @returns Boolean - Whether or not the browser supports the font loading APIs
 * @see https://developer.mozilla.org/en-US/docs/Web/API/FontFaceSet
 * @see https://developer.mozilla.org/en-US/docs/Web/API/FontFace
 */
function hasFontFaceSet<T extends Document>(document: T): document is T & { fonts: FontFaceSet } {
    return isRecordType(document) && typeof document.fonts === 'object';
}

export class FontService {
    public static SID = 'fontService';

    public static readonly $inject: string[] = [
        BuilderFontApiClient.SID,
        mvNotifierSID,
        $httpSID,
        $timeoutSID,
        SentryService.SID,
    ];

    public constructor(
        private builderFontApiClient: BuilderFontApiClient,
        private mvNotifier: MvNotifier,
        private readonly httpService: IHttpService,
        private readonly $timeout: ITimeoutService,
        private readonly sentryService: SentryService,
    ) {}

    public async getFontsForClient(
        clientId: ClientId,
        callbacks: ResourceCountCallbacks<BuilderFontCustomDto>,
    ): Promise<void> {
        return this.builderFontApiClient
            .getFontsForClient(clientId)
            .then((builderFontConfig: BuilderFontConfig) => this.loadFonts(builderFontConfig, callbacks))
            .catch((err: Error) => {
                this.mvNotifier.unexpectedErrorWithData('Failed to retrieve fonts for client', err);
            });
    }

    /**
     * Clear all manually loaded fonts. This needs to be called when the builder document is closed so that we can
     * cleanup the loaded fonts.
     */
    public clearAllLoadedFonts(): void {
        if (hasFontFaceSet(document)) {
            document.fonts.clear();
        } else {
            throw new Error('Font loading not supported');
        }
    }

    private loadFonts(builderFontConfig: BuilderFontConfig, callbacks: ResourceCountCallbacks<BuilderFontCustomDto>) {
        const fontsToLoad: BuilderFontCustomDto[] = [];
        const fontOptions: BuilderFontCustomDto[] = [];
        const failedFonts: BuilderFontCustomDto[] = [];

        const promises = builderFontConfig.custom.map(async customFont => {
            try {
                callbacks.increment();
                if (!hasFontFaceSet(document)) {
                    throw new Error('Font loading not supported');
                }
                this.addFontToList(customFont.family, fontsToLoad, builderFontConfig);
                const [signedUrl] = await this.signUrls([protocolRelativeUrlToHttpsUrl(customFont.upload.url!)]);
                assertDefined(signedUrl);

                const cssFontImport = generateFontCssSrc(
                    { ...customFont, extension: customFont.upload.ext },
                    signedUrl,
                );
                const font = new FontFace(customFont.family, cssFontImport, {
                    stretch: customFont.stretch,
                    style: customFont.style,
                    weight: customFont.weight,
                });

                const timeout = this.$timeout(() => 'TIMEOUT' as const, FONTFACE_TIMEOUT);
                const result = await Promise.race([font.load(), timeout]);
                if (result === 'TIMEOUT') {
                    throw new Error('TimeoutError');
                }

                document.fonts.add(result);
                this.addFontToList(customFont.family, fontOptions, builderFontConfig);
                callbacks.decrement();
            } catch (error: unknown) {
                this.sentryService.captureException('Failed to load font', {
                    customFont,
                    error,
                });
                this.failedToLoadFont(customFont.family, failedFonts, builderFontConfig);
                throw error;
            }
        });

        Promise.all(promises)
            .then(() => {
                void this.$timeout(() => {
                    callbacks.resetCount();
                    callbacks.finished(fontOptions);
                });
            })
            .catch((error: unknown) => {
                void this.$timeout(() => callbacks.failure(failedFonts));
            });
    }

    private failedToLoadFont(
        familyName: string,
        failedFonts: BuilderFontCustomDto[],
        builderFontConfig: BuilderFontConfig,
    ) {
        const fontObject = findBy('family', builderFontConfig.custom, familyName);
        if (!fontObject) {
            throw new Error(`Missing expected font ${familyName}`);
        }

        // Temp fix for potential duplicate font names
        if (!failedFonts.find(font => font.family === fontObject.family)) {
            failedFonts.push(fontObject);
        }
    }

    private addFontToList(
        familyName: string,
        fontOptions: BuilderFontCustomDto[],
        builderFontConfig: BuilderFontConfig,
    ): void {
        if (!fontOptions.some(font => font.family === familyName)) {
            const fontObject = findBy('family', builderFontConfig.custom, familyName);

            if (!fontObject) {
                throw new Error(`Missing expected font ${familyName}`);
            }

            fontOptions.push(fontObject); // TODO: support variations somehow
        }
    }

    private async signUrls(urls: Url[]): Promise<SignedUrl[]> {
        const chunks: Url[][] = chunkArray<Url>(urls, 10);
        const promises = chunks.map(async urlChunk =>
            invokeApiRoute(
                this.httpService,
                UrlSigningApi.signUrls,
                justQuery({
                    urls: urlChunk,
                }),
            ),
        );

        const responses = await Promise.all(promises);
        return responses.reduce<SignedUrl[]>((carry, current) => [...carry, ...current.urls ?? []], []);
    }
}

export interface ResourceCountCallbacks<T> {
    increment: () => unknown;
    decrement: () => unknown;
    resetCount: () => unknown;
    finished: (values: T[]) => unknown;
    failure: (failedFonts: T[]) => unknown;
}

angular.module('app').service(FontService.SID, FontService);
