/// <reference path="../../../../typings/browser.d.ts" />
import { CampaignMonitorReportId } from '@deltasierra/ids';
import { ReportableService } from '@deltasierra/integrations/integration-types';
import { $httpSID } from '../../common/angularData';
import { DataUtils } from '../../common/dataUtils';
import { BaseLocationStatsService } from './common';
import IHttpService = angular.IHttpService;

export class CampaignMonitorStatsService extends BaseLocationStatsService<CampaignMonitorStats, CampaignMonitorStatsEntry> {
    public static SID = 'campaignMonitorStatsService';

    public static readonly $inject: string[] = [$httpSID, DataUtils.SID];

    public serviceName: ReportableService = 'campaignMonitor';

    public displayName = 'Campaign Monitor';

    public constructor($http: IHttpService) {
        super($http);
    }

    public combineStats(stats: CampaignMonitorStatsEntry[]): CampaignMonitorStatsEntry {
        const dummy: CampaignMonitorStatsEntry = {
            htmlContentUrl: '',
            id: CampaignMonitorReportId.from(1),
            name: 'dummy',
            scheduledTime: new Date(),
            thumbnailUrl: '',
            totalClicks: 0,
            totalHardBounces: 0,
            totalOpens: 0,
            totalRecipients: 0,
            totalSoftBounces: 0,
            totalSpamReports: 0,
            totalUniqueClicks: 0,
            totalUniqueOpens: 0,
        };
        return stats.reduce(
            (prev: CampaignMonitorStatsEntry, cur: CampaignMonitorStatsEntry) => ({
                ...prev,
                totalClicks: prev.totalClicks + cur.totalClicks,
                totalHardBounces: prev.totalHardBounces + cur.totalHardBounces,
                totalOpens: prev.totalOpens + cur.totalOpens,
                totalRecipients: prev.totalRecipients + cur.totalRecipients,
                totalSoftBounces: prev.totalSoftBounces + cur.totalSoftBounces,
                totalSpamReports: prev.totalSpamReports + cur.totalSpamReports,
                totalUniqueClicks: prev.totalUniqueClicks + cur.totalUniqueClicks,
                totalUniqueOpens: prev.totalUniqueOpens + cur.totalUniqueOpens,
            }),
            dummy,
        );
    }
}

export interface CampaignMonitorStatsEntry {
    id: CampaignMonitorReportId;
    name: string;
    thumbnailUrl: string;
    scheduledTime: Date;
    totalRecipients: number;
    totalOpens: number;
    totalUniqueOpens: number;
    totalClicks: number;
    totalUniqueClicks: number;
    totalSpamReports: number;
    totalSoftBounces: number;
    totalHardBounces: number;
    htmlContentUrl?: string;
}

export type CampaignMonitorStats = CampaignMonitorStatsEntry[];

angular.module('app').service('campaignMonitorStatsService', CampaignMonitorStatsService);
