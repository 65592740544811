/* eslint-disable max-lines-per-function */
import { gql, useMutation, useQuery } from '@apollo/client';
import {
    DSButton,
    DSFormControl,
    DSFormGroup,
    DSGrid,
    DSLoaderButton,
    DSRadio,
    DSRadioGroup,
    DSTextField,
    DSTypography,
    Loading,
    Tooltip,
    Translate,
    WizardStepProps,
} from '@deltasierra/components';
import { noop } from '@deltasierra/utilities/object';
import { BuilderTemplateId, isEmailStringValid, t } from '@deltasierra/shared';
import { isNotNullOrUndefined } from '@deltasierra/type-utilities';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Formik, FormikErrors, FormikProps } from 'formik';
import { DateTime } from 'luxon';
import * as React from 'react';
import styled from 'styled-components';
import { useAngularServiceContext } from '../../../../../common/componentUtils/angularServiceContexts';
import { ValidateableFormGroup } from '../../../../../common/form/ValidateableFormGroup';
import { ValidatableTextInput } from '../../../../../common/form/ValidateableTextInput';
import { EmailPublishData } from '../../../emailPublishData';
import {
    FormButtonGroup,
    StyledDSAlert,
    StyledDSFormControlLabel,
    StyledForm,
    StyledLabel,
    StyledMainTitle,
    StyledTextBox,
    StyledTextBoxContent,
    StyledTextBoxEnd,
} from './common';
import { GetClubReadySenderInfo, GetClubReadySenderInfoVariables } from './__graphqlTypes/GetClubReadySenderInfo';
import { PublishToClubReadyStep2Fragment } from './__graphqlTypes/PublishToClubReadyStep2Fragment';
import {
    SendClubReadyCampaignPreview,
    SendClubReadyCampaignPreviewVariables,
} from './__graphqlTypes/SendClubReadyCampaignPreview';

const SEND_CLUB_READY_CAMPAIGN_PREVIEW_MUTATION = gql`
    mutation SendClubReadyCampaignPreview($input: SendClubReadyCampaignPreviewInput!, $id: ID!) {
        sendClubReadyCampaignPreview(input: $input, id: $id) {
            ... on SendClubReadyCampaignPreviewResult {
                success
            }
            ... on ClubReadyApiError {
                message
            }
        }
    }
`;

const GET_SENDER_INFO = gql`
    query GetClubReadySenderInfo($id: ID!) {
        clubReady(id: $id) {
            ... on ClubReady {
                id
                sender {
                    email
                    isVerified
                    name
                }
            }
        }
    }
`;

const fragments = {
    clubReady: gql`
        fragment PublishToClubReadyStep2Fragment on ClubReady {
            id
            sender {
                email
                isVerified
                name
            }
        }
    `,
};

const StyledDSRadioGroup = styled(DSRadioGroup)`
    margin-bottom: 0px;
`;

const StyledDSDateTextField = styled(DSTextField)`
    width: 250px;
`;

const SendTestEmailButton = styled(DSLoaderButton)`
    height: 32px;
    margin-left: 15px;
    width: 95px;
`;

const StyledVerifyButtonWrapper = styled.div`
    text-align: right;
`;

const GreenCheck = styled(CheckCircleIcon)`
    color: ${({ theme }) => theme.palette.success.main};
`;

type Step2FormValues = {
    scheduled: string;
    scheduleOption: 'immediate' | 'schedule';
    subject: string;
    testEmail: string;
    sendPreviewClicked: boolean;
};

export type PublishToClubReadyStep2Data = {
    formValues: Step2FormValues;
};

export type PublishToClubReadyStep2Props = WizardStepProps<PublishToClubReadyStep2Data> & {
    clubReady: PublishToClubReadyStep2Fragment;
    emailTemplateId: BuilderTemplateId;
    publishData: EmailPublishData;
    step2Data?: PublishToClubReadyStep2Data;
    scheduledDate?: Date;
};

export const PublishToClubReadyStep2: React.FC<PublishToClubReadyStep2Props> & { fragments: typeof fragments } = ({
    clubReady,
    emailTemplateId,
    gotoNextStep,
    gotoPrevStep,
    publishData,
    scheduledDate,
    step2Data,
    StepCount,
}) => {
    const mvNotifier = useAngularServiceContext('mvNotifier');
    const emailPublishService = useAngularServiceContext('EmailPublishService');

    const {
        data: senderData,
        loading,
        refetch: verifySender,
    } = useQuery<GetClubReadySenderInfo, GetClubReadySenderInfoVariables>(GET_SENDER_INFO, {
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
        variables: {
            id: clubReady.id,
        },
    });

    const sender = senderData?.clubReady.__typename === 'ClubReady' ? senderData.clubReady.sender : clubReady.sender;

    const [sendClubReadyCampaignPreview, { loading: testSendInProgress }] = useMutation<
        SendClubReadyCampaignPreview,
        SendClubReadyCampaignPreviewVariables
    >(SEND_CLUB_READY_CAMPAIGN_PREVIEW_MUTATION);

    const initialValues: Step2FormValues = React.useMemo(
        () => ({
            scheduleOption: scheduledDate ? 'schedule' : 'immediate',
            scheduled: DateTime.fromJSDate(scheduledDate ?? new Date())
                .plus({ minutes: 35 })
                .startOf('second')
                .toISO({
                    includeOffset: false,
                    includePrefix: true,
                    suppressMilliseconds: true,
                }),
            sendPreviewClicked: false,
            subject: publishData.builderDocument.subject ?? '',
            testEmail: '',
            ...step2Data?.formValues,
        }),
        [publishData.builderDocument.subject, scheduledDate, step2Data?.formValues],
    );

    const handleSendTestEmail = async (formik: FormikProps<typeof initialValues>) => {
        formik.setFieldValue('sendPreviewClicked', true);
        const html = await emailPublishService.rectifyAllImagesAndConvertToString(
            publishData.builderDocument,
            publishData.htmlDocument,
            publishData.fileCache,
        );
        const response = await sendClubReadyCampaignPreview({
            variables: {
                id: clubReady.id,
                input: {
                    emailTemplateId,
                    html,
                    recipientEmail: formik.values.testEmail,
                    scheduleTime: formik.values.scheduleOption === 'schedule' ? formik.values.scheduled : null,
                    subject: formik.values.subject,
                },
            },
        });
        const responseData = response.data;
        if (!responseData) {
            mvNotifier.unexpectedErrorWithData(
                t('ERRORS.BAD_REQUEST_ERRORS.REQUEST_RETURNED_NO_RESPONSE_DATA'),
                response,
            );
            return;
        }
        if (responseData.sendClubReadyCampaignPreview.__typename === 'ClubReadyApiError') {
            mvNotifier.unexpectedError(t('BUILD.PUBLISH.CLUB_READY.PREVIEW_EMAIL_FAILED'));
            return;
        }
        mvNotifier.success(t('BUILD.PUBLISH.CLUB_READY.PREVIEW_EMAIL_SENT'));
    };


    const handleValidation = (values: typeof initialValues): FormikErrors<typeof initialValues> => {
        const errors: FormikErrors<Step2FormValues & { sender: string }> = {};
        if (values.subject === '') {
            errors.subject = t('COMMON.VALIDATION.REQUIRED');
        }
        if (values.testEmail !== '' && !isEmailStringValid(values.testEmail)) {
            errors.testEmail = t('COMMON.VALIDATION.INVALID_EMAIL');
        }

        const minutes = 30; // See DSL-1275 https://digitalstack.atlassian.net/browse/DSL-1275
        const minScheduledTime = DateTime.local().plus({ minutes });
        if (values.scheduleOption === 'schedule') {
            if (!DateTime.fromISO(values.scheduled).isValid) {
                errors.scheduled = t('COMMON.VALIDATION.INVALID_DATE');
            } else if (DateTime.fromISO(values.scheduled) < minScheduledTime) {
                errors.scheduled = t('BUILD.PUBLISH.INSTAGRAM.SCHEDULED_POST_MUST_BE_AT_LEAST_MINS_IN_FUTURE', {
                    minutes,
                });
            }
        }
        return errors;
    };

    if (!senderData && loading) {
        return <Loading />;
    }

    return (
        <Formik<typeof initialValues>
            initialValues={initialValues}
            validate={handleValidation}
            validateOnBlur
            validateOnMount
            onSubmit={noop}
        >
            {formik => (
                <StyledForm>
                    <StyledMainTitle component="h3" variant="h3">
                        <Translate keyId="BUILD.PUBLISH.CLUB_READY.EMAIL_SUBJECT_AND_SCHEDULE" />
                    </StyledMainTitle>
                    <DSFormGroup>
                        <StyledLabel component="label" htmlFor="sender-name-and-email" variant="inherit">
                            <Translate keyId="BUILD.PUBLISH.CLUB_READY.SENDER" />
                        </StyledLabel>
                        <StyledTextBox id="sender-name-and-email">
                            {sender && (
                                <>
                                    <StyledTextBoxContent>{`${sender.name} <${sender.email}>`}</StyledTextBoxContent>
                                    <StyledTextBoxEnd>
                                        {sender.isVerified ? (
                                            <Tooltip
                                                placement="top"
                                                tip={t('BUILD.PUBLISH.CLUB_READY.SENDER_VERIFIED_TOOLTIP')}
                                            >
                                                <GreenCheck />
                                            </Tooltip>
                                        ) : (
                                            <Tooltip
                                                placement="top"
                                                tip={t('BUILD.PUBLISH.CLUB_READY.SENDER_NOT_VERIFIED_TOOLTIP')}
                                            >
                                                <CancelIcon color="error" />
                                            </Tooltip>
                                        )}
                                    </StyledTextBoxEnd>
                                </>
                            )}
                            {!sender && (
                                <>
                                    <StyledTextBoxContent>
                                        {t('BUILD.PUBLISH.CLUB_READY.NO_SENDER_INFO_HAS_BEEN_SET_FOR_LOCATION')}
                                    </StyledTextBoxContent>
                                    <Tooltip
                                        placement="top"
                                        tip={t('BUILD.PUBLISH.CLUB_READY.SENDER_NOT_VERIFIED_TOOLTIP')}
                                    >
                                        <CancelIcon color="error" />
                                    </Tooltip>
                                </>
                            )}
                        </StyledTextBox>
                    </DSFormGroup>
                    {loading && <Loading size="small" />}
                    {sender && !sender.isVerified && (
                        <>
                            <div className="help-block">
                                <Translate
                                    keyId="BUILD.PUBLISH.CLUB_READY.SENDER_NOT_VERIFIED_MESSAGE"
                                    options={{ domain: sender.email.slice(sender.email.lastIndexOf('@') + 1) }}
                                />
                            </div>
                            <StyledVerifyButtonWrapper>
                                <DSButton color="warning" size="small" onClick={async () => verifySender()}>
                                    <Translate keyId="BUILD.PUBLISH.CLUB_READY.REFETCH_VERIFY" />
                                </DSButton>
                            </StyledVerifyButtonWrapper>
                        </>
                    )}
                    <ValidateableFormGroup
                        fullWidth
                        label={t('BUILD.PUBLISH.CLUB_READY.SUBJECT')}
                        name="subject"
                        required
                    >
                        <ValidatableTextInput disabled={!sender?.isVerified} />
                    </ValidateableFormGroup>
                    <ValidateableFormGroup
                        fullWidth
                        label={t('BUILD.PUBLISH.CLUB_READY.SEND_A_TEST_EMAIL')}
                        name="testEmail"
                    >
                        <div style={{ display: 'flex' }}>
                            <ValidatableTextInput disabled={!sender?.isVerified} />
                            <SendTestEmailButton
                                color="primary"
                                disabled={
                                    formik.values.testEmail === '' ||
                                    isNotNullOrUndefined(formik.errors.testEmail) ||
                                    formik.values.subject === '' ||
                                    !sender?.isVerified
                                }
                                loading={testSendInProgress}
                                onClick={async () => handleSendTestEmail(formik)}
                            >
                                <Translate keyId="COMMON.SEND" />
                            </SendTestEmailButton>
                        </div>
                    </ValidateableFormGroup>
                    <DSFormGroup>
                        <DSFormControl>
                            <StyledLabel component="label" variant="inherit">
                                <Translate keyId="BUILD.PUBLISH.CLUB_READY.WHEN_WOULD_YOU_LIKE_TO_SEND_THIS_EMAIL" />
                            </StyledLabel>
                            <StyledDSRadioGroup
                                value={formik.values.scheduleOption}
                                onChange={value => formik.setFieldValue('scheduleOption', value.target.value)}
                            >
                                <StyledDSFormControlLabel
                                    control={<DSRadio />}
                                    label={t('BUILD.PUBLISH.SEND_IMMEDIATELY')}
                                    value="immediate"
                                />
                                <StyledDSFormControlLabel
                                    control={<DSRadio />}
                                    label={t('BUILD.PUBLISH.SCHEDULE_FOR_LATER')}
                                    value="schedule"
                                />
                            </StyledDSRadioGroup>
                        </DSFormControl>
                        {formik.values.scheduleOption === 'schedule' && (
                            <DSFormControl>
                                <StyledDSDateTextField
                                    defaultValue={formik.values.scheduled}
                                    type="datetime-local"
                                    onChange={event => formik.setFieldValue('scheduled', event.target.value)}
                                />
                            </DSFormControl>
                        )}
                        {formik.errors.scheduled && (
                            <DSTypography color="error">{formik.errors.scheduled}</DSTypography>
                        )}
                        <StyledDSAlert severity="warning">
                            <Translate keyId="BUILD.PUBLISH.CLUB_READY.PUBLISH_DELAY_WARNING_MESSAGE" />
                        </StyledDSAlert>
                    </DSFormGroup>
                    <FormButtonGroup>
                        <DSGrid alignItems="center" container direction="row" justifyContent="space-between">
                            <DSGrid item>
                                <DSButton startIcon={<ArrowBackIosIcon />} variant="outlined" onClick={gotoPrevStep}>
                                    <Translate keyId="COMMON.BACK" />
                                </DSButton>
                            </DSGrid>
                            <DSGrid item>
                                <StepCount />
                            </DSGrid>
                            <DSGrid item>
                                <DSButton
                                    color="primary"
                                    disabled={!formik.isValid || !sender?.isVerified || loading}
                                    endIcon={<ArrowForwardIosIcon />}
                                    type="submit"
                                    variant="contained"
                                    onClick={() => gotoNextStep({ formValues: formik.values })}
                                >
                                    <Translate keyId="COMMON.NEXT" />
                                </DSButton>
                            </DSGrid>
                        </DSGrid>
                    </FormButtonGroup>
                </StyledForm>
            )}
        </Formik>
    );
};

PublishToClubReadyStep2.fragments = fragments;
PublishToClubReadyStep2.displayName = 'PublishToClubReadyStep2';
