import * as React from 'react';
import { noop } from '@deltasierra/utilities/object';
import { Translate, TranslateProps } from '../../directives/Translate';

type SelectOption = {
    label: string;
    value: string;
};

export type RadioButtonsProps = {
    label: TranslateProps;
    multipleColumns?: boolean;
    name: string;
    onChange?: (value: any) => void;
    options: Array<SelectOption | string>;
    value?: any | null;
};

export const RadioButtons: React.FunctionComponent<RadioButtonsProps> = ({
    label,
    multipleColumns = false,
    name,
    onChange = noop,
    options,
    value,
}) => {
    const cleanedOptions = options.map<SelectOption>(option => isSelectOption(option) ? option : { label: option, value: option });
    return (
        <div className="form-group">
            <label>
                <Translate {...label} />
            </label>
            <div className={multipleColumns ? 'row' : ''}>
                {cleanedOptions.map(opt => (
                    <div
                        className={`radio ${multipleColumns ? 'col-md-6' : ''}`}
                        key={opt.value}
                        style={multipleColumns ? { marginBottom: 5, marginTop: 0 } : {}}
                    >
                        <label>
                            <input
                                checked={opt.value === value}
                                name={name}
                                type="radio"
                                value={opt.value}
                                onChange={event => onChange(event.target.value)}
                            />
                            <span>{opt.label}</span>
                        </label>
                    </div>
                ))}
            </div>
        </div>
    );
};
RadioButtons.displayName = 'RadioButtons';

function isSelectOption(option: SelectOption | string): option is SelectOption {
    return typeof option !== 'string';
}
