/// <reference path="../../../../typings/browser.d.ts" />
import {
    assertNever,
    AssetIdAndLayerId,
    BaseVideoPublishService,
    BuilderDocument,
    BuilderTemplateId,
    cataResult,
    CheckVideoRequirementsError,
    GalleryPlannerDetails,
    IPublishVideoRequest,
    ISize,
    IVideoRequestData,
    LocationType,
    PublishedArtifactGroupId,
    PublishVideoCallback,
    Upload,
    UploadId,
    VideoLayer,
    VideoUploadResult,
    WorkflowStartResult,
} from '@deltasierra/shared';
import { dataUrlToBlob } from '@deltasierra/utilities/web-image';
import { $qSID } from '../../common/angularData';
import { UploadContext, UploadService } from '../../common/uploadService';
import { I18nService } from '../../i18n';
import { SocketService } from '../../sockets/socketService';
import { ContentBuilder } from '../contentBuilder';
import { FileCache } from '../imageLoaderService';
import IPromise = ng.IPromise;
import IQService = ng.IQService;

interface VideoPublishContext {
    contentBuilder: ContentBuilder;
    fileCache: FileCache;
    ctrl: { uploadContext: UploadContext };
}

type VideoPublishArgs<TChannelData> = {
    videoData: IVideoRequestData;
    templateId: BuilderTemplateId;
    plannerDetails: GalleryPlannerDetails | null;
    channelData: TChannelData;
    publishCallback: PublishVideoCallback<TChannelData>;
    linkedAssetLibraryAssetIds: AssetIdAndLayerId[];
    publishedArtifactGroupId: PublishedArtifactGroupId | null;
    scheduledPublishGroupId: string | null;
};

export class VideoPublishService extends BaseVideoPublishService<VideoPublishContext> {
    public static readonly SID = 'VideoPublishService';

    public static readonly $inject = [$qSID, I18nService.SID, UploadService.SID, SocketService.SID];

    public constructor(
        private readonly $q: IQService,
        private readonly i18nService: I18nService,
        private readonly uploadService: UploadService,
        socketService: SocketService,
    ) {
        super(socketService);
    }

    public checkVideoRequirementsAsPromise(doc: BuilderDocument): IPromise<VideoLayer> {
        return cataResult(
            super.checkVideoRequirements(doc),
            videoLayer => this.$q.resolve(videoLayer),
            (failure: CheckVideoRequirementsError) => {
                let msg;
                switch (failure) {
                    case CheckVideoRequirementsError.NoVideoLayers:
                        msg = this.i18nService.text.build.video.noVideoLayers();
                        break;
                    case CheckVideoRequirementsError.NoVideoUploaded:
                        msg = this.i18nService.text.build.video.noVideoUploaded();
                        break;
                    default:
                        throw assertNever(failure);
                }
                return this.$q.reject(new Error(msg)) as IPromise<VideoLayer>;
            },
        );
    }

    public async generateVideoOverlayImage(contentBuilder: ContentBuilder, dimensions: ISize): Promise<Blob> {
        return this.getCompositeImageAsBlob(contentBuilder, dimensions);
    }

    public uploadOverlayImage(image: Blob, ctrl: { uploadContext?: UploadContext }): IPromise<Upload> {
        const output: Upload[] = [];
        return this.$q.all(this.uploadService.upload([image], 'videoOverlay', output, ctrl)).then(() => output[0]);
    }

    public createVideoOverlayAndUpload(
        doc: BuilderDocument,
        dimensions: ISize,
        context: VideoPublishContext,
    ): IPromise<UploadId> {
        return this.getCompositeImageAsBlob(context.contentBuilder, dimensions)
            .then(blob => this.uploadOverlayImage(blob, context.ctrl))
            .then(upload => upload.id);
    }

    public async publishVideo<TChannelData>({
        channelData,
        linkedAssetLibraryAssetIds,
        plannerDetails,
        publishCallback,
        publishedArtifactGroupId,
        scheduledPublishGroupId,
        templateId,
        videoData,
    }: VideoPublishArgs<TChannelData>): Promise<WorkflowStartResult> {
        const requestBody: IPublishVideoRequest<any> = {
            auditData: {
                builderTemplateId: templateId,
                linkedAssetLibraryAssetIds,
                plannerId: plannerDetails ? plannerDetails.id : undefined,
                publishedArtifactGroupId,
                scheduledPublishGroupId,
            },
            channelData,
            videoData,
        };

        return publishCallback(requestBody);
    }

    protected uploadVideo(layer: VideoLayer, context: VideoPublishContext): IPromise<VideoUploadResult> | never {
        if (layer.locationType === LocationType.external) {
            return this.$q.resolve({
                existingUrl: layer.location,
                uploadId: null,
            }); // Already uploaded
        } else if (layer.location) {
            const cachedFile = context.fileCache.getCache(layer.location);
            if (cachedFile.uploadId !== undefined && cachedFile.uploadId !== null) {
                return this.$q.resolve({
                    existingUrl: null,
                    uploadId: cachedFile.uploadId,
                });
            }
            const output: Upload[] = [];
            return this.$q
                .all(this.uploadService.upload([cachedFile.file], 'videoClip', output, context.ctrl))
                .then(() => {
                    const upload = output[0];
                    cachedFile.uploadId = upload.id;
                    return {
                        existingUrl: null,
                        uploadId: upload.id,
                    };
                });
        } else {
            return this.$q.reject();
        }
    }

    private async getCompositeImageAsBlob(contentBuilder: ContentBuilder, dimensions: ISize): Promise<Blob> {
        // NOTE: can export directly to blob on some browsers, maybe should try doing that first?
        return dataUrlToBlob(
            (
                await contentBuilder.exportAsImage({
                    height: dimensions.height,
                    transparentBackground: true,
                    width: dimensions.width,
                })
            ).dataUrl!,
        );
    }
}

angular.module('app').service(VideoPublishService.SID, VideoPublishService);
