import { contains } from '@deltasierra/utilities/array';
import {
    ExpressionBinding,
    ExpressionCallback,
    OneWayBinding,
    OptionalOneWayBinding,
    simpleComponent,
    StringBinding,
} from '../angularData';
import { DataUtils } from '../dataUtils';


type Entry = any; // Allow anything, since the AngularJS component can't accept a type parameter.

export class CheckboxObjectArrayCtrl {
    public static readonly SID = 'CheckboxObjectArrayCtrl';

    name!: string;

    options!: Entry[];

    change!: ExpressionCallback<{ values: Entry[] }>;

    preselectedValues?: Entry[];

    valueLabel!: ExpressionCallback<{ value: Entry }, string>;

    required!: boolean;

    public selectedIndexes: { [key: number]: boolean } = {}; // Not really used, just here so that we can use ng-model, so that the form controller's "valid/invalid" state works.

    public selected!: Entry[];

    public static readonly $inject: string[] = [
        DataUtils.SID,
    ];

    constructor(
        protected readonly dataUtils : DataUtils,
    ) {}

   public $onInit(): void {
        if (this.preselectedValues) {
            this.selected = this.preselectedValues.filter(value => this.options.indexOf(value) > -1);
        } else {
            this.selected = [];
        }
    }

    toggleSelected(entry: Entry): void {
        this.dataUtils.toggle(entry, this.selected);
        this.onChange();
    }

    isSelected(entry: Entry): boolean {
        return contains(this.selected, entry);
    }

    protected onChange(): void {
        this.change({ values: this.selected });
    }

    getLabel(value: Entry): string {
        return this.valueLabel({ value });
    }
}

export const checkboxObjectArraySID = 'checkboxObjectArray';

export const checkboxObjectArrayConfig = simpleComponent(
    CheckboxObjectArrayCtrl,
    '/partials/common/checkboxObjectArray/checkboxObjectArray',
    {
        change: ExpressionBinding,
        name: StringBinding,
        options: OneWayBinding,
        preselectedValues: OptionalOneWayBinding,
        required: OneWayBinding,
        valueLabel: ExpressionBinding,
    },
);

angular.module('app').directive(checkboxObjectArraySID, [() => checkboxObjectArrayConfig]);
