/* eslint-disable max-lines-per-function */
/* eslint-disable no-nested-ternary */
import {
    AdWordsAdEntityFields,
    BrandAwarenessStats,
    calculatePotentialReturnOnInvestment,
    calculateRecommendedSpend,
    ConversionsConversionTypeStats,
    ConversionsStats,
    CurrencyCode,
    CurrencyFloat,
    getClickThroughRate,
    getCostPerConversion,
    getReturnOnInvestment,
    GoogleAdWordsReportByType,
    isBrandAwarenessAdEntityStat,
    isBrandAwarenessLocationStats,
    isConversionsAdEntityStats,
    isConversionsConversionTypeStats,
    isConversionsLocationStats,
    RecommendationsLocationStats,
    Untyped,
} from '@deltasierra/shared';
import { titleCaseFirst } from '@deltasierra/utilities/string';
import { compareNumbers, compareStrings } from '../compare';
import { ReportTable, SortOrder, StatsEntry, TextAlignClass } from '../reportTable/reportTable';
import { CreateExpandableReportTableOptions, CreateReportTableOptions } from './common';

function compareAdWordsRowLabel(strA: string, strB: string): number {
    // Ensure is a string and ignore awkward characters at start
    const sanitise = (value: string) => String(value || '').replace(/^['"+]/, '');
    const sanitisedStrA = sanitise(strA);
    const sanitisedStrB = sanitise(strB);
    return sanitisedStrA.localeCompare(sanitisedStrB);
}

export interface CreateGoogleAdWordsReportTableOptions
    extends CreateReportTableOptions,
        CreateExpandableReportTableOptions {
    reportByType: GoogleAdWordsReportByType;
}

function getAllCurrencyCodes(entries: Array<{ currencyCode: CurrencyCode | null }>): CurrencyCode[] {
    const currencyCodes = [];
    for (const entry of entries) {
        if (!!entry.currencyCode && currencyCodes.indexOf(entry.currencyCode) === -1) {
            currencyCodes.push(entry.currencyCode);
        }
    }
    return currencyCodes;
}

export function containsMultipleCurrencies(entries: Array<{ currencyCode: CurrencyCode | null }>): boolean {
    const currencyCodes = getAllCurrencyCodes(entries);
    return currencyCodes.length > 1;
}

export function isMobileDevicePreference(value: string): boolean {
    // Magic string values!
    // The new google ads client may define mobile device preferences in two ways:
    // The enum value 2, OR
    // The string 'MOBILE'
    return value === '2' || value === 'MOBILE';
}

function formatDevicePreference(value: string, options: CreateReportTableOptions) {
    if (isMobileDevicePreference(value)) {

        return `<i class="fa fa-mobile device" aria-hidden="true" title="${options.i18n.text.report.devices.mobile()}"></i>`;
    } else {
        return '-';
    }
}

function getAdFormatted(label: string, entry: AdWordsAdEntityFields) {
    return `
        <div class="entry-label"> ${label} </div>
        <div class="description-line"> ${entry.description} </div>
        <div class="description-line"> ${entry.description1} ${entry.description2} </div>
    `;
}

export function createGoogleAdWordsBrandAwarenessReportTable(
    options: CreateGoogleAdWordsReportTableOptions,
): ReportTable<StatsEntry<BrandAwarenessStats>, BrandAwarenessStats> {
    const formatCurrency = (value: number, currencyCode: string | null) =>
        value === null
            ? '-'
            : options.formatters.formatCurrency(value, { code: currencyCode || undefined, format: 'symbol' });

    const formatEntityLabel = (value: string, entry: BrandAwarenessStats | StatsEntry<BrandAwarenessStats>) => {
        if (isBrandAwarenessAdEntityStat(entry)) {
            return getAdFormatted(value, entry);
        }

        return value;
    };

    const getEntityRowLabel = (entry: StatsEntry<BrandAwarenessStats>) => {
        if (isBrandAwarenessLocationStats(entry)) {
            return entry.location.locationTitle;
        }

        return entry.label;
    };

    const impressionIndex = 4;

    const result: ReportTable<StatsEntry<BrandAwarenessStats>, BrandAwarenessStats> = new ReportTable<
        StatsEntry<BrandAwarenessStats>,
        BrandAwarenessStats
    >({
        columns: [
            {
                compare: compareAdWordsRowLabel,
                cssClass: TextAlignClass.Left,
                format: formatEntityLabel,
                getTotal: () => options.i18n.text.report.total(),
                getValue: getEntityRowLabel,
                label: () =>
                    options.reportByType === 'ad'
                        ? options.i18n.text.report.locationAd()
                        : options.i18n.text.report.locationKeyword(),
            },
            {
                compare: compareAdWordsRowLabel,
                cssClass: TextAlignClass.Center,
                format: (value, entry) => formatDevicePreference(value, options),
                getHeaderHint: () => options.i18n.text.report.deviceSelectionHint(),
                getIsVisible: reportTable => options.reportByType === 'ad',
                getTotal: () => '-',
                getValue: entry => (isBrandAwarenessAdEntityStat(entry) ? entry.devicePreference : undefined),
                label: () => options.i18n.text.report.deviceSelection(),
            },
            {
                compare: compareStrings,
                cssClass: TextAlignClass.Center,
                format: options.formatters.formatString,
                getHeaderHint: () => options.i18n.text.report.googleAds.columns.currencyTooltip(),
                getIsVisible: reportTable =>
                    reportTable ? containsMultipleCurrencies(reportTable.data.getEntries()) : false,
                getTotal: totals => (containsMultipleCurrencies(result.data.getEntries()) ? '-' : totals.currencyCode),
                getValue: entry => entry.currencyCode,
                label: () => options.i18n.text.report.currency(),
            },
            {
                compare: compareNumbers,
                cssClass: TextAlignClass.Right,
                format: (value, entry) => (entry ? formatCurrency(value, entry.currencyCode) : ''),
                getHeaderHint: () => options.i18n.text.report.googleAds.columns.costTooltip(),
                getTotal: totals => (containsMultipleCurrencies(result.data.getEntries()) ? null : totals.cost),
                getValue: entry => entry.cost,
                label: () => options.i18n.text.report.totalSpend(),
            },
            {
                compare: compareNumbers,
                cssClass: TextAlignClass.Right,
                format: options.formatters.formatNumber,
                getHeaderHint: () => options.i18n.text.report.googleAds.columns.impressionsTooltip(),
                getTotal: totals => totals.impressions,
                getValue: entry => entry.impressions,
                label: () => options.i18n.text.report.impressions(),
            },
            {
                compare: compareNumbers,
                cssClass: TextAlignClass.Right,
                format: options.formatters.formatNumber,
                getHeaderHint: () => options.i18n.text.report.googleAds.columns.interactionsTooltip(),
                getTotal: totals => totals.clicks,
                getValue: entry => entry.clicks,
                label: () => options.i18n.text.report.clicks(),
            },
            {
                compare: compareNumbers,
                cssClass: TextAlignClass.Right,
                format: options.formatters.formatPercentageFloat,
                getHeaderHint: () => options.i18n.text.report.clickThroughRateHint(),
                getTotal: totals => getClickThroughRate(totals.impressions, totals.clicks),
                getValue: entry => getClickThroughRate(entry.impressions, entry.clicks),
                label: () => options.i18n.text.report.clickThroughRate(),
            },
        ],
        sortedByColumn: {
            columnIndex: impressionIndex,
            order: SortOrder.Desc,
        },
        tree: {
            canExpandOrCollapseEntry: entry => isBrandAwarenessLocationStats(entry),
            getChildEntries: entry =>
                isBrandAwarenessLocationStats(entry) ? entry.entityTotals : ReportTable.EMPTY_ENTRY_ARRAY,
            getIsEntryExpanded: entry =>
                isBrandAwarenessLocationStats(entry) ? options.getIsLocationExpanded(entry.location.locationId) : true,
            setIsEntryExpanded: (entry, value) => {
                if (isBrandAwarenessLocationStats(entry)) {
                    options.setIsLocationExpanded(entry.location.locationId, value);
                }
            },
        },
    });

    return result;
}

export function createGoogleAdWordsConversionsReportTable(
    options: CreateGoogleAdWordsReportTableOptions,
): ReportTable<StatsEntry<ConversionsStats>, ConversionsStats> {
    const formatCurrency = (value: number, currencyCode: Untyped) =>
        value === null || value === Number.POSITIVE_INFINITY
            ? '-'
            : options.formatters.formatCurrency(value, { code: currencyCode, format: 'symbol' });

    const formatLocationCurrency = (value: number, entry: ConversionsStats) => {
        if (isConversionsLocationStats(entry)) {
            return formatCurrency(value, entry.currencyCode);
        } else {
            return '-';
        }
    };

    const getConversionTypeTitle = (entry: ConversionsConversionTypeStats) =>
        `${titleCaseFirst(entry.conversionTypeName)} ` +
        `(${formatCurrency(entry.totalConversionValue / entry.conversions, entry.currencyCode)})`;

    const formatEntityLabel = (value: string, entry: ConversionsStats | StatsEntry<ConversionsStats>) => {
        if (isConversionsAdEntityStats(entry)) {
            return getAdFormatted(value, entry);
        }

        return value;
    };

    const result: ReportTable<StatsEntry<ConversionsStats>, ConversionsStats> = new ReportTable<
        StatsEntry<ConversionsStats>,
        ConversionsStats
    >({
        columns: [
            {
                compare: compareAdWordsRowLabel,
                cssClass: TextAlignClass.Left,
                format: formatEntityLabel,
                getTotal: () => options.i18n.text.report.total(),
                getValue: entry =>
                    isConversionsLocationStats(entry)
                        ? entry.location.locationTitle
                        : isConversionsConversionTypeStats(entry)
                        ? getConversionTypeTitle(entry)
                        : entry.label,
                label: () =>
                    options.reportByType === 'ad'
                        ? options.i18n.text.report.locationConversionTypeAd()
                        : options.i18n.text.report.locationConversionTypeKeyword(),
            },
            {
                compare: compareAdWordsRowLabel,
                cssClass: TextAlignClass.Center,
                format: (value, entry) => formatDevicePreference(value, options),
                getHeaderHint: () => options.i18n.text.report.deviceSelectionHint(),
                getIsVisible: reportTable => options.reportByType === 'ad',
                getTotal: () => '-',
                getValue: entry => (isConversionsAdEntityStats(entry) ? entry.devicePreference : undefined),
                label: () => options.i18n.text.report.deviceSelection(),
            },
            {
                compare: compareStrings,
                cssClass: TextAlignClass.Center,
                format: options.formatters.formatString,
                getHeaderHint: () => options.i18n.text.report.googleAds.columns.currencyTooltip(),
                getIsVisible: reportTable =>
                    reportTable ? containsMultipleCurrencies(reportTable.data.getEntries()) : false,
                getTotal: totals =>
                    result.data.getEntries().length > 0
                        ? containsMultipleCurrencies(result.data.getEntries())
                            ? '-'
                            : totals.currencyCode
                        : '-',
                getValue: entry => entry.currencyCode,
                label: () => options.i18n.text.report.currency(),
            },
            {
                compare: compareNumbers,
                cssClass: TextAlignClass.Right,
                format: options.formatters.formatNumber,
                getHeaderHint: () => options.i18n.text.report.googleAds.columns.conversionsTooltip(),
                getTotal: totals => totals.conversions,
                getValue: entry => entry.conversions,
                label: () => options.i18n.text.report.conversions(),
            },
            {
                compare: compareNumbers,
                cssClass: TextAlignClass.Right,
                format: formatLocationCurrency,
                getHeaderHint: () => options.i18n.text.report.googleAds.columns.costTooltip(),
                getTotal: totals => (containsMultipleCurrencies(result.data.getEntries()) ? null : totals.cost),
                getValue: entry => entry.cost,
                label: () => options.i18n.text.report.totalSpend(),
            },
            {
                compare: compareNumbers,
                cssClass: TextAlignClass.Right,
                format: formatLocationCurrency,
                getHeaderHint: () => options.i18n.text.report.googleAds.columns.costPerConversionTooltip(),
                getTotal: totals =>
                    containsMultipleCurrencies(result.data.getEntries())
                        ? null
                        : getCostPerConversion(totals.cost, totals.conversions),
                getValue: entry => getCostPerConversion(entry.cost, entry.conversions),
                label: () => options.i18n.text.report.costPerConversion(),
            },
            {
                compare: compareNumbers,
                cssClass: TextAlignClass.Right,
                format: (value, entry) => (entry ? formatCurrency(value, entry.currencyCode) : ''),
                getHeaderHint: () => options.i18n.text.report.conversionValueHint(),
                getTotal: totals =>
                    containsMultipleCurrencies(result.data.getEntries()) ? null : totals.totalConversionValue,
                getValue: entry => entry.totalConversionValue,
                label: () => options.i18n.text.report.conversionValue(),
            },
            {
                compare: compareNumbers,
                cssClass: TextAlignClass.Right,
                format: formatLocationCurrency,
                getHeaderHint: () => options.i18n.text.report.roiHint(),
                getTotal: totals =>
                    containsMultipleCurrencies(result.data.getEntries())
                        ? null
                        : getReturnOnInvestment(totals.totalConversionValue, totals.cost),
                getValue: entry => getReturnOnInvestment(entry.totalConversionValue, entry.cost),
                label: () => options.i18n.text.report.roi(),
            },
        ],
        sortedByColumn: {
            columnIndex: 3,
            order: SortOrder.Desc,
        },
        tree: {
            canExpandOrCollapseEntry: entry => isConversionsLocationStats(entry),
            getChildEntries: entry =>
                isConversionsLocationStats(entry)
                    ? entry.conversionTypeTotals
                    : isConversionsConversionTypeStats(entry)
                    ? entry.entityTotals
                    : ReportTable.EMPTY_ENTRY_ARRAY,
            getIsEntryExpanded: entry =>
                isConversionsLocationStats(entry) ? options.getIsLocationExpanded(entry.location.locationId) : true,
            setIsEntryExpanded: (entry, value) => {
                if (isConversionsLocationStats(entry)) {
                    options.setIsLocationExpanded(entry.location.locationId, value);
                }
            },
        },
    });

    return result;
}

export interface AdjustableRecommendationsLocationStats extends RecommendationsLocationStats {
    potentialSpend?: CurrencyFloat;
}

export interface CreateGoogleAdWordsRecommendationsReportTableOptions
    extends CreateExpandableReportTableOptions,
        CreateReportTableOptions {
    agencyHasGoogleAdWordsMarketingContact: () => boolean;
}

export function createGoogleAdWordsRecommendationsReportTable(
    options: CreateGoogleAdWordsRecommendationsReportTableOptions & CreateReportTableOptions,
): ReportTable<StatsEntry<AdjustableRecommendationsLocationStats>, AdjustableRecommendationsLocationStats> {
    const formatCurrency = (value: number, currencyCode: CurrencyCode | null | undefined) =>
        value === null
            ? '-'
            : options.formatters.formatCurrency(value, { code: currencyCode || undefined, format: 'symbol' });

    const hasMarketShareLoss = (
        entry: AdjustableRecommendationsLocationStats | StatsEntry<AdjustableRecommendationsLocationStats>,
    ) => entry.impressionShareLostByBudget.value > 0;

    const formatUnreachedMarket = (
        value: number,
        entry: AdjustableRecommendationsLocationStats | StatsEntry<AdjustableRecommendationsLocationStats>,
    ) => {
        if (hasMarketShareLoss(entry)) {
            return (
                (entry.impressionShareLostByBudget.type === 'greaterThan' ? 'At least ' : '') +
                options.formatters.formatPercentageFloat(value)
            );
        }

        return options.i18n.text.report.noUnreachedMarketForPeriod();
    };

    return new ReportTable<StatsEntry<AdjustableRecommendationsLocationStats>, AdjustableRecommendationsLocationStats>({
        columns: [
            {
                compare: compareAdWordsRowLabel,
                cssClass: TextAlignClass.Left,
                format: options.formatters.formatString,
                getTotal: () => null,
                getValue: entry => entry.location.locationTitle,
                label: () => options.i18n.text.report.location(),
            },
            {
                compare: compareStrings,
                cssClass: TextAlignClass.Center,
                format: options.formatters.formatString,
                getIsVisible: reportTable =>
                    reportTable ? containsMultipleCurrencies(reportTable.data.getEntries()) : false,
                getTotal: () => null,
                getValue: entry => entry.currencyCode,
                label: () => options.i18n.text.report.currency(),
            },
            {
                compare: compareNumbers,
                cssClass: TextAlignClass.Right,
                format: (value, entry) => (entry ? formatCurrency(value, entry.currencyCode) : ''),
                getHeaderHint: () => options.i18n.text.report.googleAds.columns.costTooltip(),
                getTotal: () => null,
                getValue: entry => entry.cost,
                label: () => options.i18n.text.report.totalSpend(),
            },
            {
                compare: compareNumbers,
                cssClass: TextAlignClass.Right,
                format: (value, entry) => (entry ? formatCurrency(value, entry.currencyCode) : ''),
                getHeaderHint: () => options.i18n.text.report.conversionValueHint(),
                getTotal: () => null,
                getValue: entry => entry.totalConversionValue,
                label: () => options.i18n.text.report.conversionValue(),
            },
            {
                compare: compareNumbers,
                cssClass: TextAlignClass.Right,
                format: (value, entry) => (entry ? formatCurrency(value, entry.currencyCode) : ''),
                getHeaderHint: () => options.i18n.text.report.roiHint(),
                getTotal: () => null,
                getValue: entry => getReturnOnInvestment(entry.totalConversionValue, entry.cost),
                label: () => options.i18n.text.report.roi(),
            },
            {
                compare: compareNumbers,
                cssClass: TextAlignClass.Right,
                format: formatUnreachedMarket,
                getColSpan: entry => (!hasMarketShareLoss(entry) ? 3 : 1),
                getHeaderHint: () => options.i18n.text.report.unreachedMarketHint(),
                getTotal: () => null,
                getValue: entry => entry.impressionShareLostByBudget.value,
                label: () => options.i18n.text.report.unreachedMarket(),
            },
            {
                compare: compareNumbers,
                cssClass: TextAlignClass.Right,
                format: (value, entry) => (entry ? formatCurrency(value, entry.currencyCode) : ''),
                getColSpan: entry => (!hasMarketShareLoss(entry) ? 0 : 1),
                getHeaderHint: () => options.i18n.text.report.recommendedSpendHint(),
                getTotal: () => null,
                getValue: entry => calculateRecommendedSpend(entry.cost, entry.impressionShareLostByBudget),
                label: () => options.i18n.text.report.recommendedSpend(),
            },
            {
                compare: compareNumbers,
                cssClass: TextAlignClass.Right,
                format: (value, entry) => (entry ? formatCurrency(value < 0 ? null : value, entry.currencyCode) : ''),
                getColSpan: entry => (!hasMarketShareLoss(entry) ? 0 : 1),
                getHeaderHint: () => options.i18n.text.report.potentialRoiHint(),
                getTotal: () => null,
                getValue: entry =>
                    calculatePotentialReturnOnInvestment(
                        entry.cost,
                        entry.totalConversionValue,
                        entry.impressionShareLostByBudget,
                        entry.potentialSpend || 0,
                    ),
                label: () => options.i18n.text.report.potentialRoi(),
            },
        ],
        rowFooter: {
            getIsHiddenWhenCollapsed: () => true,
            getTemplateUrl: entry =>
                entry.impressionShareLostByBudget.value &&
                getReturnOnInvestment(entry.totalConversionValue, entry.cost) > 0 &&
                options.agencyHasGoogleAdWordsMarketingContact()
                    ? '/partials/reports/reportTables/googleAdWordsRecommendationsRowFooter'
                    : null,
        },
        sortedByColumn: {
            columnIndex: 4,
            order: SortOrder.Desc,
        },
        totals: {
            isEnabled: false,
        },
        tree: {
            canExpandOrCollapseEntry: () => true,
            getChildEntries: () => ReportTable.EMPTY_ENTRY_ARRAY,
            getIsEntryExpanded: entry => options.getIsLocationExpanded(entry.location.locationId),
            setIsEntryExpanded: (entry, value) => options.setIsLocationExpanded(entry.location.locationId, value),
        },
    });
}
