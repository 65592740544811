import { convertUnits, PrintUnits } from '@deltasierra/shared';
import { getEnumKeyValuePairs } from '@deltasierra/utilities/object';
import { titleCase } from '@deltasierra/utilities/string';
import { $scopeSID } from '../common/angularData';

class MeasurementInputController {
    amount! : number;

    units? : string;

    selectedUnits? : string;

    availableUnits! : string;

    availableUnitsArray? : Array<{ title : string; value : string }>;

    placeholder? : string;

    static readonly $inject : string[] = [
        $scopeSID,
    ];

    constructor(
        private readonly $scope : ng.IScope,
    ) {}

   public $onInit(): void {
        this.$scope.$watch(() => this.availableUnits, () => {
            this.availableUnitsArray = this.getAvailableUnitsArray();
        });

        this.$scope.$watch(
            () => this.units,
            () => {
                this.selectedUnits = this.units;
            },
        );

        this.$scope.$watch(
            () => this.selectedUnits,
            (newUnits, oldUnits) => {
                if (this.amount && oldUnits && newUnits) {
                    this.amount = convertUnits(this.amount, oldUnits as any, newUnits as any);
                }
                this.units = newUnits;
            },
        );
    }

    private getAvailableUnitsArray() : Array<{ title : string; value : string }> {
        const units = (this.availableUnits || '')
            .replace(/[;|]/g, ',')
            .toLowerCase()
            .split(',');

        const result = units.map(value => ({
            title: titleCase(this.measurementKeyByValue(value)) || '',
            value,
        }));

        return result;
    }

    // A way to get around the PrintUnits key / value being the wrong way around for this directive
    private measurementKeyByValue(value: string): string | undefined {
        const measurements = getEnumKeyValuePairs(PrintUnits);
        let targetKey;
        measurements.forEach(measurement => {
            if (measurement.value === value) {
                targetKey = measurement.key;
            }
        });
        return targetKey;
    }
}

export const dsMeasurementInputSID = 'dsMeasurementInput';
export const dsMeasurementInputConfig = {
    restrict: 'E',
    scope: {},
    bindToController: {
        amount: '=',
        units: '=',
        availableUnits: '@',
        placeholder: '@?',
    },
    controller: MeasurementInputController,
    controllerAs: 'ctrl',
    templateUrl: '/partials/contentBuilder/measurementInput',
};

angular.module('app').directive(dsMeasurementInputSID, [() => dsMeasurementInputConfig]);
