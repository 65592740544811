/// <reference path="../../../../../../typings/browser.d.ts" />
import {
    AssignedLocation,
    BuilderTemplateType,
    CampaignMonitorClient,
    CampaignMonitorDraftData,
    CampaignMonitorSendCampaignData,
    CampaignMonitorSendPreviewData,
    ClientDetails,
    ExportPlatforms,
    GalleryPlannerDetails,
    getDefaultScheduledTime,
    Html,
    Nullable,
    protocolRelativeUrlToHttpsUrl,
    Segment,
    SubscriberList,
    TimezoneInfo,
} from '@deltasierra/shared';
import { noop } from '@deltasierra/utilities/object';
import { FeatureFlag } from '@deltasierra/features/feature-flags/core';
import { IAngularEvent, IPromise, IQService, IScope } from 'angular';
import { DateTime } from 'luxon';
import { PublishResult } from '../../../publish/publishResult';
import {
    IsSelectedForList,
    ListSelectionService,
    listSelectionServiceSID,
    ToggleSelectedForList,
} from '../../../../common/listSelectionService';
import { MvNotifier } from '../../../../common/mvNotifier';
import { UploadContext } from '../../../../common/uploadService';
import { InteractionUtils } from '../../../../common/interactionUtils';
import { CampaignMonitorPublishService } from '../../../../integration/publish/campaignMonitorPublishService';
import { BuilderConstants, builderConstantsSID } from '../../../builderConstants';
import { $qSID, $scopeSID, OneWayBinding, simpleComponent } from '../../../../common/angularData';
import { EmailPublishData } from '../../emailPublishData';
import { EmailPublishService } from '../emailPublishService';
import { I18nService } from '../../../../i18n';
import { GraphqlService } from '../../../../graphql/GraphqlService';
import { GET_CUSTOM_MERGE_FIELDS } from '../../../GetCustomMergeFields.query';
import { GetCustomMergeFields } from '../../../__graphqlTypes/GetCustomMergeFields';

const MINIMUM_SCHEDULED_TIME_AHEAD_MINUTES = 15;
const DEFAULT_HOUR_FROM_PLANNER_DATE = 19;

interface PublishToCampaignMonitorCtrlScope extends IScope {
    uploadContext?: UploadContext;
}

export class PublishToCampaignMonitorCtrl {
    public static readonly SID = 'publishToCampaignMonitorCtrl';

    public publishData!: EmailPublishData;

    public location!: AssignedLocation;

    public options: {
        clients: CampaignMonitorClient[] | null;
        lists: SubscriberList[] | null;
        segments: Segment[] | null;
    } = {
        clients: null,
        lists: null,
        segments: null,
    };

    public selected: {
        client: CampaignMonitorClient | null;
        lists: SubscriberList[] | null;
        segments: Segment[] | null;
    } = {
        client: null,
        lists: [],
        segments: [],
    };

    public clientDetails?: ClientDetails;

    public clientTimezone?: TimezoneInfo;

    public campaignData!: Nullable<CampaignMonitorDraftData>;

    public sendPreviewData: CampaignMonitorSendPreviewData = {
        personalize: 'Random',
        previewRecipients: [],
    };

    public sendCampaignData: CampaignMonitorSendCampaignData = {
        confirmationEmail: [],
        sendDate: null,
    };

    public datePickerIsOpen = {
        scheduledTime: false,
    };

    public loading = {
        clients: false,
        lists: false,
        saveDraftCampaign: false,
        segments: false,
        sendCampaign: false,
    };

    public step = 1;

    public campaignId: string | null = null;

    public isToBeScheduled = false;

    public scheduledTime?: Date | null;

    public minDate: Date = new Date();

    public toggleSelectedForList!: ToggleSelectedForList<any>;

    public isSelectedForList!: IsSelectedForList<any>;

    public toggleSelectedForSegment!: ToggleSelectedForList<any>;

    public isSelectedForSegment!: IsSelectedForList<any>;

    // eslint-disable-next-line @typescript-eslint/ban-types
    public fetchClients = this.interactionUtils.createFuture<CampaignMonitorClient[], {}>(
        'retrieve Campaign monitor clients',
        () => this.campaignMonitorPublishService.getClients(this.publishData.location.id),
    );

    // eslint-disable-next-line @typescript-eslint/ban-types
    public fetchClientTimezone = this.interactionUtils.createHttpFuture<TimezoneInfo, {}>(
        'retrieve Campaign Monitor client timezone',
        () =>
            this.campaignMonitorPublishService.getClientTimezone(
                this.publishData.location.id,
                this.selected.client!.clientId,
            ),
    );

    // eslint-disable-next-line @typescript-eslint/ban-types
    public fetchLists = this.interactionUtils.createHttpFuture<SubscriberList[], {}>(
        'retrieve Campaign monitor lists',
        () => this.campaignMonitorPublishService.getLists(this.publishData.location.id, this.selected.client!.clientId),
    );

    // eslint-disable-next-line @typescript-eslint/ban-types
    public fetchSegments = this.interactionUtils.createHttpFuture<Segment[], {}>(
        'retrieve Campaign Monitor segments',
        () =>
            this.campaignMonitorPublishService.getSegments(
                this.publishData.location.id,
                this.selected.client!.clientId,
            ),
    );

    public sendPreview = this.interactionUtils.createHttpFuture('send campaign preview', () =>
        this.campaignMonitorPublishService.sendPreview(
            this.publishData.location.id,
            this.selected.client!.clientId,
            this.campaignId!,
            this.sendPreviewData,
        ),
    );

    public saveDraftCampaign = this.interactionUtils.createFuture('save draft campaign', () =>
        this.doSaveDraftCampaign(),
    );

    public sendCampaign = this.interactionUtils.createFuture('publish campaign', () => this.doSendCampaign());

    public isLoading = this.interactionUtils.createIsLoading(
        this.fetchClients,
        this.fetchLists,
        this.fetchSegments,
        this.sendPreview,
        this.saveDraftCampaign,
        this.sendCampaign,
    );

    // eslint-disable-next-line @typescript-eslint/member-ordering
    public static readonly $inject: string[] = [
        $scopeSID,
        $qSID,
        InteractionUtils.SID,
        MvNotifier.SID,
        CampaignMonitorPublishService.SID,
        listSelectionServiceSID,
        builderConstantsSID,
        EmailPublishService.SID,
        I18nService.SID,
        GraphqlService.SID,
    ];

    // eslint-disable-next-line max-params
    public constructor(
        private readonly $scope: PublishToCampaignMonitorCtrlScope,
        private readonly $q: IQService,
        private readonly interactionUtils: InteractionUtils,
        private readonly mvNotifier: MvNotifier,
        private readonly campaignMonitorPublishService: CampaignMonitorPublishService,
        private readonly listSelectionService: ListSelectionService,
        private readonly builderConstants: BuilderConstants,
        protected readonly emailPublishService: EmailPublishService,
        private readonly i18n: I18nService,
        private readonly graphqlService: GraphqlService,
    ) {}

    public $onInit(): void {
        this.campaignData = {
            builderTemplateId: this.publishData.templateId,
            fromEmail: null,
            fromName: null,
            htmlUploadId: null,
            htmlUrl: null,
            linkedAssetLibraryAssetIds: [],
            listIds: [],
            name: null,
            plannerId: this.publishData.plannerId || null,
            /*
             * There isn't a multi-location publish option for Campaign Monitor. This should be fine to leave as null.
             */
            publishedArtifactGroupId: null,
            replyTo: null,
            segmentIds: [],
            subject: this.publishData.builderDocument.subject ?? null,
        };
        this.location = this.publishData.location;
        const luxonCurrentTime = DateTime.now();
        if (
            this.publishData.plannerDetails?.date &&
            DateTime.fromJSDate(this.publishData.plannerDetails.date) > luxonCurrentTime
        ) {
            this.isToBeScheduled = true;
            this.setScheduledTime(this.publishData.plannerDetails);
        } else {
            this.setScheduledTime();
        }

        this.listSelectionService.applyListSelectionMixins(this, 'List', 'ListID', () => this.selected.lists || []);
        this.listSelectionService.applyListSelectionMixins(
            this,
            'Segment',
            'SegmentID',
            () => this.selected.segments || [],
        );
        void this.loadClients();
    }

    public onClientChange(): IPromise<void> {
        if (this.selected.lists) {
            this.selected.lists.length = 0;
        } else {
            this.selected.lists = [];
        }
        if (this.selected.segments) {
            this.selected.segments.length = 0;
        } else {
            this.selected.segments = [];
        }
        return this.$q.all([this.loadClientTimezone(), this.loadLists(), this.loadSegments()]).then(noop);
    }

    public clickSaveDraftCampaign(): IPromise<void> {
        return this.saveDraftCampaign.run({});
    }

    public hasPreviewRecipient(): boolean {
        return this.sendPreviewData.previewRecipients.length > 0 && !!this.sendPreviewData.previewRecipients[0];
    }

    public clickSendPreview(): IPromise<void> {
        if (this.hasPreviewRecipient()) {
            return this.sendPreview.run({}).then(() => {
                this.step = 4;
            });
        } else {
            this.step = 4;
        }
        return Promise.resolve();
    }

    public hasConfirmationEmail(): boolean {
        return this.sendCampaignData.confirmationEmail.length > 0 && !!this.sendCampaignData.confirmationEmail[0];
    }

    public clickSendCampaign(): IPromise<void> {
        return this.sendCampaign.run({});
    }

    public cancel(): void {
        this.$scope.$emit(this.builderConstants.EVENTS.PUBLISH_CANCEL);
    }

    public goToStep(step: number): void {
        this.step = step;
    }

    public openDatePicker($event: IAngularEvent, datePickerName: 'scheduledTime'): void {
        $event.preventDefault();
        if ($event.stopPropagation) {
            $event.stopPropagation();
        }
        this.datePickerIsOpen[datePickerName] = true;
    }

    private doSendCampaign() {
        if (this.isToBeScheduled) {
            this.sendCampaignData.sendDate = this.scheduledTime;
        } else {
            this.scheduledTime = null;
        }
        return this.campaignMonitorPublishService
            .sendCampaign(
                this.publishData.location.id,
                this.selected.client!.clientId,
                this.campaignId!,
                this.sendCampaignData,
            )
            .then(() => {
                this.mvNotifier.notify(this.i18n.text.build.publish.campaignMonitor.notifyPublishSuccess());
                this.finish();
            });
    }

    private populateCampaignData() {
        const listIds = [];
        for (const list of this.selected.lists || []) {
            listIds.push(list.ListID);
        }
        this.campaignData.listIds = listIds;
        const segmentIds = [];
        for (const segment of this.selected.segments || []) {
            segmentIds.push(segment.SegmentID);
        }
        this.campaignData.segmentIds = segmentIds;
        this.campaignData.linkedAssetLibraryAssetIds = this.publishData.linkedAssetLibraryAssetIds;
    }

    private async doSaveDraftCampaign() {
        this.populateCampaignData();

        let customMergeFields: Array<{ key: string; value: string; }> = [];

        const clientFeatures = new Set(this.publishData.location.clientFeatures);

        if(clientFeatures.has(FeatureFlag.CUSTOM_MERGE_FIELDS)) {
            const gqlClient = this.graphqlService.getClient();

            const { data } = await gqlClient.query<GetCustomMergeFields>({
                fetchPolicy: 'cache-first',
                notifyOnNetworkStatusChange: true,
                query: GET_CUSTOM_MERGE_FIELDS,
                variables: { locationId: this.publishData.location.graphqlId, templateType: BuilderTemplateType.email },
            });

            customMergeFields = [...data?.location?.buildableTemplateCustomMergeFields ?? []];
        }

        const { upload } = await this.emailPublishService.rectifyAndUpload(
            this.publishData.builderDocument,
            this.publishData.htmlDocument,
            this.publishData.fileCache,
            this.$scope,
            (html: Html): Html => {
                let htmlStr = html.toString();
                customMergeFields.forEach(
                    customMergeField => {
                        htmlStr = htmlStr.replace(`[${customMergeField.key}]`, customMergeField.value);
                    },
                );
                return Html.from(htmlStr);
            },
        );

        this.campaignData.htmlUploadId = upload.id;
        // The below should probably be provided from the server using the above htmlUploadId
        this.campaignData.htmlUrl = protocolRelativeUrlToHttpsUrl(upload.url!);


        const res = await this.campaignMonitorPublishService.saveDraftCampaign(
            this.publishData.location.id,
            this.selected.client!.clientId,
            this.campaignData as unknown as CampaignMonitorDraftData,
        );

        this.campaignId = res.data!.campaignId;
        this.step = 3;
    }

    private finish() {
        this.$scope.$emit(
            this.builderConstants.EVENTS.PUBLISH_FINISH,
            new PublishResult(ExportPlatforms.CampaignMonitor, this.scheduledTime || null),
        );
    }

    private setScheduledTime(plannerDetails?: GalleryPlannerDetails): void {
        this.scheduledTime = getDefaultScheduledTime(
            MINIMUM_SCHEDULED_TIME_AHEAD_MINUTES,
            plannerDetails,
            DEFAULT_HOUR_FROM_PLANNER_DATE,
        );
    }

    private loadClients() {
        // eslint-disable-next-line consistent-return
        return this.fetchClients.run({}).then(data => {
            this.options.clients = data;
            if (this.options.clients && this.options.clients.length > 0) {
                this.selected.client = this.options.clients[0];
                return this.onClientChange();
            }
        });
    }

    private loadClientTimezone() {
        return this.fetchClientTimezone.run({}).then(clientTimezone => {
            this.clientTimezone = clientTimezone;
        });
    }

    private loadLists() {
        return this.fetchLists.run({}).then(lists => {
            this.options.lists = lists;
            if (this.options.lists && this.options.lists.length === 1) {
                this.selected.lists!.push(this.options.lists[0]);
            }
        });
    }

    private loadSegments() {
        return this.fetchSegments.run({}).then(segments => {
            this.options.segments = segments;
        });
    }
}

export const publishToCampaignMonitorSID = 'publishToCampaignMonitor';

export const publishToCampaignMonitorConfig = simpleComponent(
    PublishToCampaignMonitorCtrl,
    '/partials/contentBuilder/email/publish/campaignMonitor/publishToCampaignMonitor',
    {
        publishData: OneWayBinding,
    },
);

angular.module('app').directive(publishToCampaignMonitorSID, [() => publishToCampaignMonitorConfig]);
