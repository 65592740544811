/// <reference path="../../../typings/browser.d.ts" />
import { noop } from '@deltasierra/utilities/object';
import { AssignedLocation, Untyped } from '@deltasierra/shared';
import { MvIdentity } from '../account/mvIdentity';
import { DataUtils } from '../common/dataUtils';
import { Debouncer } from '../common/Debouncer';
import { InteractionUtils } from '../common/interactionUtils';
import { I18nService } from '../i18n/i18nService';
import { TryThisVoteable } from './models';
import { MvTryThis } from './mvTryThis';
import IScope = angular.IScope;
import ILocationService = angular.ILocationService;
import IPromise = angular.IPromise;
import ITimeoutService = angular.ITimeoutService;
import IQService = angular.IQService;

export abstract class BaseTryThisCtrl {
    public static SID = 'mvTryThisCtrl';

    public trythis: TryThisVoteable | null = null;

    public trythises: TryThisVoteable[] = [];

    public allLoaded = false;

    public searchText: string | null = null;

    public loading = {
        trythises: false,
        vote: false,
    };

    public chunkedTryThese: TryThisVoteable[][] | null = null;

    public location?: AssignedLocation;

    public searchDebouncer = new Debouncer<unknown, void>(
        this.$timeout,
        this.$q,
        'search for articles',
        () => this.search(),
        1000,
    );

    public constructor(
        protected $q: IQService,
        protected $scope: IScope,
        protected $timeout: ITimeoutService,
        protected $location: ILocationService,
        protected Slug: Untyped,
        public identity: MvIdentity,
        protected mvTryThis: MvTryThis,
        protected interactionUtils: InteractionUtils,
        protected dataUtils: DataUtils,
        public i18nService: I18nService,
    ) {
        this.chunkedTryThese = null;
    }

    public updateLocation(newLocation: AssignedLocation): ng.IPromise<void> | void {
        if (this.location !== newLocation) {
            const oldLocation = this.location;
            this.location = newLocation;
            if (!newLocation || !oldLocation || oldLocation.clientId !== newLocation.clientId) {
                this.reset();
                return this.loadTryThisData();
            }
        }
        return noop();
    }

    public voteHelpful(trythisObj: TryThisVoteable, helpful: boolean): IPromise<void> {
        return this.interactionUtils
            .handleRemoteSimple(
                this,
                'vote on learn article',
                'vote',
                this.mvTryThis.voteHelpful(trythisObj.id, helpful),
            )
            .then((success: boolean) => {
                if (success === true) {
                    if (helpful) {
                        if (trythisObj.helpful === undefined) {
                            trythisObj.helpful = [];
                        }
                        trythisObj.helpful.push(this.identity.currentUser!.id);
                    }
                    if (!helpful) {
                        if (trythisObj.unhelpful === undefined) {
                            trythisObj.unhelpful = [];
                        }
                        trythisObj.unhelpful.push(this.identity.currentUser!.id);
                    }
                    trythisObj.voted = true;
                }
            });
    }

    public slugify(trythisObj: TryThisVoteable): string {
        if (trythisObj) {
            return this.Slug.slugify(trythisObj.title);
        } else {
            return '';
        }
    }

    public search(): IPromise<void> {
        this.$location.path('/trythis').search({
            q: this.searchText,
        });
        return this.$q.resolve();
    }

    public searchByTag(tag: Untyped): void {
        this.$location.path('/trythis').search({
            q: tag,
        });
    }

    protected abstract loadTryThisData(): IPromise<void>;

    protected reset(): void {
        this.allLoaded = false;
        this.trythises.length = 0;
        this.chunkedTryThese = null;
    }
}
