/* eslint-disable max-lines-per-function */
import { gql, useMutation } from '@apollo/client';
import {
    DSButton,
    DSDialog,
    DSDialogActions,
    DSDialogContent,
    DSDialogContentText,
    DSDialogTitle,
    DSTextField,
} from '@deltasierra/components';
import { noop } from '@deltasierra/utilities/object';
import { useControlledInput } from '@deltasierra/react-hooks';
import { t, Upload } from '@deltasierra/shared';
import * as React from 'react';
import styled from 'styled-components';
import { CreateBrandInput, UpdateBrandInput } from '../../../../../../__graphqlTypes/globalTypes';
import { useAngularServiceContext } from '../../../../common/componentUtils/angularServiceContexts';
import { BrandForDialog } from './__graphqlTypes/BrandForDialog';
import { CreateBrand, CreateBrandVariables } from './__graphqlTypes/CreateBrand';
import { UpdateBrand, UpdateBrandVariables } from './__graphqlTypes/UpdateBrand';

export type NewBrandDialogProps = {
    brand?: BrandForDialog;
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
};

const CREATE_BRAND = gql`
    mutation CreateBrand($input: CreateBrandInput!) {
        createBrand(input: $input) {
            __typename
            ... on CreateBrandPayload {
                brand {
                    id
                    title
                }
            }
        }
    }
`;

const UPDATE_BRAND = gql`
    mutation UpdateBrand($input: UpdateBrandInput!) {
        updateBrand(input: $input) {
            __typename
            ... on UpdateBrandPayload {
                brand {
                    id
                    title
                }
            }
        }
    }
`;

const DialogForm = styled.form`
    & > * {
        margin-top: 12px;
        margin-bottom: 12px;
    }
`;

const fragments = {
    BrandDialogFragment: gql`
        fragment BrandForDialog on Brand {
            id
            color
            title
            url
        }
    `,
};

export const BrandDialog: React.FC<NewBrandDialogProps> & { fragments: typeof fragments } = ({
    brand,
    isOpen,
    onClose,
    onConfirm,
}) => {
    const shouldUpdate = !!brand;
    const uploadService = useAngularServiceContext('uploadService');

    const [createBrand, { loading: createLoading }] = useMutation<CreateBrand, CreateBrandVariables>(CREATE_BRAND, {
        refetchQueries: ['GetBrandsForTable'],
    });
    const [updateBrand, { loading: updateLoading }] = useMutation<UpdateBrand, UpdateBrandVariables>(UPDATE_BRAND, {
        refetchQueries: ['GetBrandsForTable'],
    });
    const [title, , titleProps] = useControlledInput(noop, brand?.title ?? '');
    const [color, , colorProps] = useControlledInput(noop, brand?.color ?? '');
    const [isUploading, setIsUploading] = React.useState(false);

    const [logo, setLogo] = React.useState<Upload | null>(null);

    const handleLogo: React.ChangeEventHandler<HTMLInputElement> = React.useCallback(
        async ({ target }) => {
            if (target.files === null) {
                return;
            }
            setIsUploading(true);
            await Promise.all(uploadService.upload([target.files[0]], 'logo', [], {}, { suppressNotifications: true }))
                .then(([upload]) => {
                    setLogo(upload);
                })
                .finally(() => {
                    setIsUploading(false);
                });
        },
        [uploadService],
    );

    const createBrandAndConfirm = React.useCallback(
        async (input: CreateBrandInput) => {
            const response = await createBrand({
                refetchQueries: ['GetBrandsForAdminWell'],
                variables: { input },
            });
            if (response.data?.createBrand.__typename === 'CreateBrandPayload') {
                onConfirm();
            }
        },
        [createBrand, onConfirm],
    );

    const updateBrandAndConfirm = React.useCallback(
        async (input: UpdateBrandInput) => {
            const response = await updateBrand({
                refetchQueries: ['GetBrandsForAdminWell'],
                variables: { input },
            });
            if (response.data?.updateBrand.__typename === 'UpdateBrandPayload') {
                onConfirm();
            }
        },
        [updateBrand, onConfirm],
    );

    const url: string | null = logo?.url ? logo.url : brand?.url ?? null;

    return (
        <DSDialog open={isOpen}>
            <DSDialogTitle>{shouldUpdate ? t('ADMIN.BRANDS.UPDATE_BRAND') : t('ADMIN.BRANDS.NEW_BRAND')}</DSDialogTitle>
            <DSDialogContent>
                <DSDialogContentText>{t('ADMIN.BRANDS.PLEASE_ENTER_NAME_COLOR_LOGO')}</DSDialogContentText>
                <DSDialogContentText>{t('ADMIN.BRANDS.YOU_CAN_ASSIGN_CLIENTS_FROM')}</DSDialogContentText>
                <DialogForm>
                    <DSTextField fullWidth label={t('ADMIN.BRANDS.BRAND_NAME')} {...titleProps} />
                    <DSTextField fullWidth label={t('ADMIN.BRANDS.BRAND_COLOR')} {...colorProps} />
                    <>
                        <input
                            accept="image/*"
                            id="icon-button-photo"
                            style={{ display: 'none' }}
                            type="file"
                            onChange={handleLogo}
                        />
                        <label htmlFor="icon-button-photo">
                            <DSButton color="primary" component="span" disabled={isUploading} size="small">
                                <i className="fa fa-photo fa-space" />
                                {t('ADMIN.BRANDS.UPLOAD_LOGO')}
                            </DSButton>
                        </label>
                    </>
                    {!!url && (
                        <img
                            height="64px"
                            src={url}
                            style={{ marginLeft: '12px', objectFit: 'contain' }}
                            width="64px"
                        />
                    )}
                </DialogForm>
            </DSDialogContent>
            <DSDialogActions>
                <DSButton color="default" onClick={onClose}>
                    {t('COMMON.CANCEL')}
                </DSButton>
                <DSButton
                    color={shouldUpdate ? 'primary' : 'success'}
                    disabled={createLoading || updateLoading || !title || isUploading}
                    onClick={async () => {
                        if (shouldUpdate && !!brand) {
                            await updateBrandAndConfirm({ color, id: brand.id, logoId: logo?.id, title });
                        } else {
                            await createBrandAndConfirm({ color, logoId: logo?.id, title });
                        }
                    }}
                >
                    {shouldUpdate ? t('COMMON.UPDATE') : t('COMMON.CREATE')}
                </DSButton>
            </DSDialogActions>
        </DSDialog>
    );
};
BrandDialog.displayName = 'BrandDialog';
BrandDialog.fragments = fragments;
