/// <reference path="../../../typings/browser.d.ts" />
import { noop } from '@deltasierra/utilities/object';
import {
    AssignedLocation,
    ClientId,
    emptyRequest,
    GetLinkedInPageNameAndOrganizationUrnResponse,
    justBody,
    justParams,
    justQuery,
    Location,
    LocationApi,
    LocationCategoryOption,
    LocationCategoryOptionId,
    LocationFacebookPageDto,
    LocationId,
    LocationIdHierarchy,
    LocationMap,
    LocationPickerDto,
    LocationShippingDetails,
    NewLocationDto,
    paramsAndBody,
    Planner,
    PlannerOverviewDto,
    RecurringPaymentDetailDto,
    RecurringPaymentListDto,
    UpdateFitwareClubBody,
    UpdateFour51CredentialsBody,
    UserId,
} from '@deltasierra/shared';
import { MvAuth } from '../account/mvAuth';
import { MvIdentity } from '../account/mvIdentity';
import { LocationSubscription, Subscription } from '../admin/subscriptions/models';
import { $httpSID, $kookiesSID, $locationSID, IKookies } from '../common/angularData';
import { getData, invokeApiRoute } from '../common/httpUtils';
import { MvNotifier } from '../common/mvNotifier';
import { FitwareClub } from '../integration/stats/fitwareStatsService';
import { MvLocationResource, mvLocationResourceSID } from './mvLocationResource';
import IPromise = angular.IPromise;

const LOCATION_ID_COOKIE_NAME = 'plannerLocation';

export class MvLocation {
    public static readonly SID = 'mvLocation';

    public static readonly $inject: string[] = [
        $httpSID,
        $kookiesSID,
        $locationSID,
        mvLocationResourceSID,
        MvIdentity.SID,
        MvNotifier.SID,
        MvAuth.SID,
    ];

    public constructor(
        private $http: ng.IHttpService,
        private $kookies: IKookies,
        private $location: ng.ILocationService,
        private mvLocationResource: MvLocationResource,
        private mvIdentity: MvIdentity,
        private mvNotifier: MvNotifier,
        private mvAuth: MvAuth,
    ) { }

    public createLocation(clientId: ClientId, newLocationData: NewLocationDto): ng.IPromise<Location> {
        return invokeApiRoute(this.$http, LocationApi.createLocation, paramsAndBody({ clientId }, newLocationData));
    }

    public getAssignedLocations(): IPromise<LocationIdHierarchy[]> {
        return invokeApiRoute(this.$http, LocationApi.getSimpleAssignedLocations, justQuery({})).then(locations => {
            this.checkForNoAssignedLocations(locations);
            return locations;
        });
    }

    public getAssignedLocationsForUser(userId: UserId): IPromise<LocationIdHierarchy[]> {
        return invokeApiRoute(this.$http, LocationApi.getSimpleAssignedLocations, justQuery({ userId }));
    }

    public getAssignedLocation(locationId: LocationId): IPromise<AssignedLocation> {
        return invokeApiRoute(this.$http, LocationApi.getAssignedLocation, justParams({ locationId }));
    }

    public async getLocationIdOrUpdateWithDefault(): Promise<LocationId> {
        try {
            let locationId: LocationId | null | undefined = this.getLocationIdFromCookie();
            if (locationId) {
                return locationId;
            }
            locationId = this.mvIdentity.getDefaultLocationId();
            if (!locationId) {
                const data: LocationPickerDto[] = await this.getSimpleAssignedLocations();
                if (data.length < 0 || data[0] === null) {
                    throw new Error('No assigned locations found');
                }
                locationId = data[0].locationId;
            }
            await this.updateLocationIdForCookie(locationId);
            return locationId;
        } catch (error) {
            this.mvNotifier.unexpectedErrorWithData('Failed to retrieve assigned locations', error);
            throw error;
        }
    }

    public async getLocationOrUpdateWithDefault(): Promise<AssignedLocation> {
        const locationId = await this.getLocationIdOrUpdateWithDefault();
        return this.getAssignedLocation(locationId);
    }

    public async updateLocationIdForCookie(locationId: LocationId): Promise<void> {
        /*
            Set location cookies across two domains to handle issue with two cookies existing
            with the same key but different value.
            Related ticket: https://digitalstack.atlassian.net/browse/DSL-3125
        */
        this.$kookies.set(LOCATION_ID_COOKIE_NAME, locationId, { expires: 0, path: '/', secure: true });
        this.$kookies.set(LOCATION_ID_COOKIE_NAME, locationId, {
            domain: `.${window.location.hostname}`,
            path: '/',
            secure: true,
        });
        await this.updateDefaultLocationIdForCurrentUser(locationId);
    }

    public getConfiguredPageNameAndOrganizationUrn(
        locationId: LocationId,
    ): IPromise<GetLinkedInPageNameAndOrganizationUrnResponse> {
        return invokeApiRoute(
            this.$http,
            LocationApi.getConfiguredPageNameAndOrganizationUrn,
            justParams({ locationId }),
        );
    }

    public getLocationCategories(locationId: LocationId): IPromise<LocationCategoryOption[]> {
        return invokeApiRoute(this.$http, LocationApi.getLocationCategories, justParams({ locationId }));
    }

    public getSimpleAssignedLocations(): IPromise<LocationPickerDto[]> {
        return invokeApiRoute(this.$http, LocationApi.getSimpleAssignedLocationsWithCategories, emptyRequest()).then(
            locations => {
                this.checkForNoAssignedLocations(locations);
                return locations;
            },
        );
    }

    public getFacebookPagesForLocations(locationIds: LocationId[]): IPromise<LocationFacebookPageDto[]> {
        return invokeApiRoute(this.$http, LocationApi.getFacebookPageForLocations, justBody({ locationIds }));
    }

    public hasAssignedLocations(): IPromise<boolean> {
        return this.getAssignedLocations().then(locations => locations.length > 0);
    }

    public getLocationIdFromCookie(): LocationId | undefined {
        const cookieValue = this.$kookies.get(LOCATION_ID_COOKIE_NAME, Number);
        if (cookieValue) {
            return LocationId.from(cookieValue);
        } else {
            return undefined;
        }
    }

    public getPlanners(
        clientId: ClientId,
        locationId: LocationId,
        dateFrom: Date,
        dateTo: Date,
    ): IPromise<Planner[] | undefined> {
        return this.$http
            .post<Planner[] | undefined>('/api/planners/assigned', {
                clientId,
                dateFrom,
                dateTo,
                locationId,
            })
            .then(res => res.data || undefined);
    }

    public getOverviewPlanners(locationId: number, dateFrom: Date, dateTo: Date): IPromise<PlannerOverviewDto[]> {
        return this.$http
            .post<PlannerOverviewDto[]>('/api/planners/assigned/overview', {
                dateFrom,
                dateTo,
                locationId,
            })
            .then(res => res.data || []);
    }

    public updateFacebookPage(
        locationId: LocationId,
        facebookPageId: string | null,
        facebookPageName: string | null,
    ): IPromise<void> {
        return invokeApiRoute(
            this.$http,
            LocationApi.updateFacebookPage,
            paramsAndBody(
                { locationId },
                {
                    facebookPageId,
                    facebookPageName,
                },
            ),
        );
    }

    public updateFitwareClub(locationId: LocationId, club: FitwareClub | null): IPromise<void> {
        const payload: UpdateFitwareClubBody = {
            fitwareAccountId: club ? club.AccountID : null,
            fitwareClubBrand: club ? club.Brand : null,
            fitwareClubEmail: club ? club.Email : null,
            fitwareClubName: club ? club.ClubName : null,
            fitwareClubNumber: club ? String(club.ClubNumber) : null,
        };
        return this.$http.post(`/api/location/${locationId}/fitwareClub`, payload).then(noop);
    }

    public updateFour51Credentials(locationId: LocationId, body: UpdateFour51CredentialsBody): IPromise<void> {
        return invokeApiRoute(this.$http, LocationApi.updateFour51Credentials, paramsAndBody({ locationId }, body));
    }

    public deleteFour51Credentials(locationId: LocationId): IPromise<void> {
        return invokeApiRoute(this.$http, LocationApi.deleteFour51Credentials, justParams({ locationId }));
    }

    public updateCategories(locationId: LocationId, categoryIds: LocationCategoryOptionId[]): ng.IPromise<void> {
        return invokeApiRoute(
            this.$http,
            LocationApi.updateLocationCategories,
            paramsAndBody({ locationId }, { categoryIds }),
        );
    }

    public getRecurringPayment(
        locationId: LocationId,
        recurringPaymentId: number,
    ): IPromise<RecurringPaymentDetailDto> {
        return this.$http
            .get<RecurringPaymentDetailDto>(`/api/location/${locationId}/recurringPayments/${recurringPaymentId}`)
            .then(getData);
    }

    public getRecurringPayments(locationId: LocationId): IPromise<RecurringPaymentListDto[]> {
        return this.$http
            .get<RecurringPaymentDetailDto[]>(`/api/location/${locationId}/recurringPayments`)
            .then(getData);
    }

    public getCurrentlyActiveRecurringPayment(locationId: LocationId): IPromise<RecurringPaymentDetailDto | ''> {
        return this.$http
            .get<RecurringPaymentDetailDto | ''>(`/api/location/${locationId}/recurringPayments/active`)
            .then(getData);
    }

    public saveRecurringPayment(
        locationId: LocationId,
        recurringPayment: RecurringPaymentDetailDto,
    ): IPromise<RecurringPaymentDetailDto> {
        let url = `/api/location/${locationId}/recurringPayments`;
        if (recurringPayment.id) {
            url += `/${recurringPayment.id}`;
        }
        return this.$http.post<RecurringPaymentDetailDto>(url, recurringPayment).then(getData);
    }

    public deleteLocation(clientId: ClientId, locationId: LocationId): IPromise<void> {
        return this.mvLocationResource
            .delete({
                clientId,
                locationId,
            })
            .$promise.then(noop);
    }

    public deleteRecurringPayment(locationId: LocationId, recurringPaymentId: number): IPromise<void> {
        const url = `/api/location/${locationId}/recurringPayments/${recurringPaymentId}`;
        return this.$http.delete(url).then(() => {
            // Do nothing
        });
    }

    public getSubscription(locationId: LocationId): IPromise<LocationSubscription> {
        return this.$http.get<LocationSubscription>(`/api/location/${locationId}/subscription`).then(getData);
    }

    public getSubscriptionCascading(locationId: LocationId): IPromise<Subscription> {
        return this.$http.get<Subscription>(`/api/location/${locationId}/subscriptionCascading`).then(getData);
    }

    public updateSubscription(
        locationId: LocationId,
        locationSubscription: LocationSubscription,
    ): ng.IHttpPromise<unknown> {
        return this.$http.post(`/api/location/${locationId}/subscription`, locationSubscription);
    }

    public updateDetails(
        locationId: LocationId,
        details: {
            title?: string;
            displayName?: string | null;
            timezone?: string;
            supportedPlannerLimit?: number | null;
        },
    ): ng.IPromise<void> {
        return invokeApiRoute(this.$http, LocationApi.updateLocation, paramsAndBody({ locationId }, details)).then(
            noop,
        );
    }

    public updateAccountLocationDetails(
        locationId: LocationId,
        details: {
            phoneNumber: string | null;
            locationEmail: string | null;
            addressLineOne: string | null;
            addressLineTwo: string | null;
            addressLineThree: string | null;
            shippingAttention: string | null;
            shippingCompanyName: string | null;
            shippingLineOne: string | null;
            shippingLineTwo: string | null;
            shippingLineThree: string | null;
            timezone: string | null;
            logoId: number | null;
            websiteUrl: string | null;
        },
    ): ng.IPromise<void> {
        return this.$http.post(`/api/location/${locationId}/client`, details).then(noop);
    }

    public updateLocationShippingDetails(
        locationId: LocationId,
        details: {
            shippingAttention: string | null;
            shippingCompanyName: string | null;
            shippingLineOne: string | null;
            shippingLineTwo: string | null;
            shippingLineThree: string | null;
        },
    ): ng.IPromise<LocationShippingDetails> {
        return (
            this.$http
                // TODO: Fix the data returned. This is actually returning more than what the type suggests
                .post<LocationShippingDetails>(`/api/location/${locationId}/client`, details)
                .then(getData)
        );
    }

    public createLocationMap(
        locationId: LocationId,
        details: {
            center: { lat: string; lon: string };
            zoom: number;
        },
    ): ng.IPromise<LocationMap> {
        return this.$http.post<LocationMap>(`/api/location/${locationId}/map`, details).then(getData);
    }

    public removeLocationMap(locationId: LocationId): ng.IPromise<LocationMap> {
        return this.$http.delete<LocationMap>(`/api/location/${locationId}/map`).then(getData);
    }

    public updateShippingDetails(
        locationId: LocationId,
        details: {
            shippingAttention: string | null;
            shippingCompanyName: string | null;
            shippingLineOne: string | null;
            shippingLineTwo: string | null;
            shippingLineThree: string | null;
        },
    ): ng.IPromise<void> {
        return this.$http.post(`/api/location/${locationId}/client`, details).then(noop);
    }

    private checkForNoAssignedLocations<T>(locations: T[]): boolean {
        if (locations && locations.length === 0) {
            this.$location.url('/help/no-assigned-locations');
            return false;
        }

        return true;
    }

    private async updateDefaultLocationIdForCurrentUser(locationId: LocationId): Promise<void> {
        const currentDefaultLocationId = this.mvIdentity.getDefaultLocationId();
        if (currentDefaultLocationId !== locationId) {
            await this.mvAuth.updateCurrentUser({ defaultLocationId: locationId });
        }
    }
}

angular.module('app').service(MvLocation.SID, MvLocation);
