import { AssignedLocation } from '@deltasierra/shared';
import { LEFT_NAV_COLLAPSED_ENABLED_BODY_CLASS, LEFT_NAV_EXTENDED_ENABLED_BODY_CLASS } from '@deltasierra/components';
import { GraphqlService } from '../graphql/GraphqlService';
import { SELECTED_LOCATION_CHANGED_EVENT_NAME } from '../directives/locationPicker/locationPicker';
import { MvLocation } from '../locations/mvLocation';
import { MvIdentity } from './mvIdentity';
import { GET_NAVBAR_FEATURE_FLAG_QUERY } from './graphql/get-navbar-feature-flag.query';
import { GetNavbarFeatureFlag, GetNavbarFeatureFlagVariables } from './graphql/__graphqlTypes/GetNavbarFeatureFlag';

export class MvNavbar {
    public static readonly SID = 'mvNavbar';

    public static readonly $inject: string[] = ['$rootScope', MvIdentity.SID, GraphqlService.SID, MvLocation.SID];

    public isLeftNavbarEnabled = false;

    private location: AssignedLocation | null = null;

    public constructor(
        private readonly $rootScope: ng.IRootScopeService,
        private readonly mvIdentity: MvIdentity,
        private readonly graphqlService: GraphqlService,
        private readonly mvLocation: MvLocation,
    ) { }

    public init(): void {
        this.$rootScope.$watch(() => this.mvIdentity.isAuthenticated(), async () => {
            if (!this.location && this.mvIdentity.isAuthenticated()) {
                this.location = await this.mvLocation.getLocationOrUpdateWithDefault();
            }
            await this.updateIsLeftNavbarEnabled();
        });
        this.$rootScope.$on(SELECTED_LOCATION_CHANGED_EVENT_NAME, async (_event, data) => {
            this.location = data.location;
            await this.updateIsLeftNavbarEnabled();
        });
    }

    private async updateIsLeftNavbarEnabled(): Promise<void> {
        if (this.mvIdentity.isAuthenticated() && this.location) {
            const { data } = await this.graphqlService
                .getClient()
                .query<GetNavbarFeatureFlag, GetNavbarFeatureFlagVariables>({
                    fetchPolicy: 'network-only',
                    query: GET_NAVBAR_FEATURE_FLAG_QUERY,
                    variables: { clientId: this.location.clientGraphqlId, locationId: this.location.graphqlId },
                });

            this.isLeftNavbarEnabled =
                data.me.features.leftHandNavigation ||
                data.client?.features.leftHandNavigation ||
                data.location?.features.leftHandNavigation ||
                false;

            if (!this.isLeftNavbarEnabled) {
                this.removeLeftNavigationBodyClasses();
            }
        }
    }

    private removeLeftNavigationBodyClasses(): void {
        if (document.body.classList.contains(LEFT_NAV_EXTENDED_ENABLED_BODY_CLASS)) {
            document.body.classList.remove(LEFT_NAV_EXTENDED_ENABLED_BODY_CLASS);
        }
        if (document.body.classList.contains(LEFT_NAV_COLLAPSED_ENABLED_BODY_CLASS)) {
            document.body.classList.remove(LEFT_NAV_COLLAPSED_ENABLED_BODY_CLASS);
        }
    }
}

angular.module('app').service(MvNavbar.SID, MvNavbar);
